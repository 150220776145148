import { useEffect, useRef, useState } from "react";
import moment from "moment";
import CommentIcon from "../assets/icons/CommentIcon";
import LikeIcon from "../assets/icons/LikeIcon";
import { useAppDispatch, useAppSelector } from "../config/hooks";
import profile from "../assets/images/Blank_Placeholders.png";
import Comments from "./Comments";
import EclipsVerticleDotsIcon from "../assets/icons/EclipsVerticleDotsIcon";
import { setCommentIdL1 } from "../slices/profileSlice";
import {
  deleteCommentApi,
  doActionOnCommentApi,
  updateCommentApi,
} from "../slices/commentSlice";
import { baseUrl, contentUrl } from "../utils/contant";
import { dropDownByTitle } from "../utils/nocoFunctions";
import ProfileInput from "../pages/profile/components/basic-components/ProfileInput";
import { Controller, useForm } from "react-hook-form";
import SendButtonIcon from "../assets/icons/SendButtonIcon";
import { unwrapResult } from "@reduxjs/toolkit";

type FormValue = {
  editComment: string;
};

const CommentCard = ({ comment, userContent }) => {
  const dispatch = useAppDispatch();
  const form = useForm<FormValue>();
  const menuRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleComment, setToggleComment] = useState(false);
  const [editComment, setEditComment] = useState(false);
  const session = useAppSelector((state) => state.login.session);
  const { designations } = useAppSelector((state) => state.appConfig);
  const { author, authorId, content, counts, createdAt, isLiked, id } = comment;
  const { firstName, lastName, designation, organization, picUrl } = author;
  const { like, reply } = counts;
  const { handleSubmit, control, setValue } = form;
  const postRelativeTime = moment(createdAt).fromNow();

  useEffect(() => {
    if (editComment) {
      setValue("editComment", content);
    }
  }, [content, editComment, setValue]);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (
        menuRef.current &&
        parentRef.current &&
        !menuRef.current.contains(e.target as Node) &&
        !parentRef.current.contains(e.target as Node)
      ) {
        // onClose();
        setToggleModal(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const togglePostModal = () => {
    setToggleModal(!toggleModal);
  };

  const toggleCommentHandler = () => {
    if (toggleComment) {
      if (comment.id === id) {
        setToggleComment(false);
      } else {
        dispatch(setCommentIdL1(id));
      }
    } else {
      if (comment.id === id) {
        setToggleComment(true);
      } else {
        setToggleComment(true);
        dispatch(setCommentIdL1(id));
      }
    }
  };

  const toggleCommentLiked = (commentId) => {
    const isLiked = comment.isLiked;
    const data = {
      action: isLiked ? "unlike" : "like",
      postId: userContent.id, //userContent.id === comment.postId
      commentId,
    };
    dispatch(doActionOnCommentApi(data));
  };

  const toggleEditComment = () => {
    setEditComment(!editComment);
    setToggleModal(false);
  };

  const handleDeleteComment = () => {
    dispatch(
      deleteCommentApi({
        id: id,
        postId: userContent.id,
        authorType: "USER",
        authorId: authorId,
      })
    );
  };

  const onSubmit = async (formData: FormValue) => {
    console.log("formData :>> ", formData);
    const data = {
      id: id,
      postId: userContent.id,
      content: formData.editComment,
      // parentId?: string;
      authorType: "USER",
      authorId: authorId,
    };
    try {
      await dispatch(updateCommentApi(data)).then(unwrapResult);
      setEditComment(false);
    } catch (error) {}
  };

  return (
    <>
      <div
        className={`grow w-full bg-lxgray-700 p-4 rounded-xl space-y-5 border-l-4 ${
          comment.depth === 0
            ? "border-l-golden"
            : comment.depth === 1
            ? "border-l-green-400"
            : "border-lxgray-200"
        }`}
      >
        <div className="flex gap-4 items-start relative">
          <div className="w-[50px]">
            {picUrl ? (
              <div
                className={`w-[50px] h-[50px] bg-no-repeat bg-center bg-cover rounded-full`}
                style={{
                  backgroundImage: `url(${baseUrl}/${contentUrl}/${picUrl}?t=${session.token})`,
                }}
              ></div>
            ) : (
              <div
                className={`w-[40px] h-[40px] bg-no-repeat bg-center bg-cover rounded-full`}
                style={{
                  backgroundImage: `url(${profile})`,
                }}
              ></div>
            )}
          </div>
          <div className="text-start grow min-w-[70%]">
            <h1 className="text-lg text-white font-semibold leading-5">{`${firstName} ${lastName}`}</h1>
            <p className="text-base text-lxgray-400 font-normal flex flex-wrap items-center justify-between">
              <span className="text-start truncate">{`${dropDownByTitle(
                designation,
                designations
              )} | ${organization}`}</span>
              <span className="text-end">{postRelativeTime}</span>
            </p>
          </div>
          {authorId === session.userId && (
            <div
              className="self-start ml-auto mr-0 cursor-pointer"
              ref={parentRef}
              onClick={() => togglePostModal()}
            >
              <EclipsVerticleDotsIcon />
            </div>
          )}
          {toggleModal && (
            <div
              className="w-[265px] absolute top-6 right-0 z-40 rounded-s-xl rounded-br-xl"
              ref={menuRef}
            >
              <div
                className={`w-full h-full bg-lxgray-600 shadow-2xl text-lg text-white tracking-tight text-start font-light  rounded-s-xl rounded-br-xl flex flex-col justify-between divide-y-2 divide-lxgray-400/30 `}
              >
                <h1
                  className="w-full  p-2 cursor-pointer hover:opacity-90"
                  onClick={toggleEditComment}
                >
                  Edit
                </h1>
                <h1
                  onClick={handleDeleteComment}
                  className="w-full  p-2 cursor-pointer hover:opacity-90"
                >
                  Delete
                </h1>
              </div>
            </div>
          )}
        </div>
        <div className="w-[92%] ml-auto mr-0">
          {editComment ? (
            <form className="" onSubmit={handleSubmit(onSubmit)}>
              <div className="relative">
                <Controller
                  name="editComment"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ProfileInput
                      isComment={true}
                      className={`h-[50px] border-2 border-lxgray-400`}
                      placeholder={"Add comments"}
                      title=""
                      name="editComment"
                      value={value || ""}
                      onChange={onChange}
                      error={""}
                      autoFocus={true}
                    />
                  )}
                />
                <button
                  type="submit"
                  className="absolute top-0 right-2 h-full flex items-center justify-center"
                >
                  <SendButtonIcon />
                </button>
              </div>
            </form>
          ) : (
            <p className="text-white text-base font-normal tracking-wide">
              {content}
            </p>
          )}
        </div>
        <div className="w-full border-t-2 border-lxgray-400/30">
          <div
            className={`flex items-center ${
              comment.depth !== 2 ? "justify-end" : "justify-end"
            } text-sm text-lxgray-400 font-semibold py-2 px-4 ml-auto mr-0 gap-3`}
          >
            <h1
              className={`flex gap-2 items-center justify-center cursor-pointer px-2 text-center ${
                isLiked && "text-golden"
              }`}
              onClick={() => toggleCommentLiked(id)}
            >
              <LikeIcon color={isLiked ? "#AA933E" : "#8C8C8C"} />
              {`${like} Applauses`}
            </h1>
            {comment.depth !== 2 && (
              <h1
                className="flex gap-2 items-center justify-center cursor-pointer px-2 text-center"
                onClick={() => toggleCommentHandler()}
              >
                <CommentIcon />
                {`${reply} Replies`}
              </h1>
            )}
          </div>
        </div>
      </div>
      {toggleComment && (
        <div className="w-full pl-14">
          <Comments
            userContent={userContent}
            comments={comment?.replies}
            parentId={comment.id}
            key={id}
          />
        </div>
      )}
    </>
  );
};

export default CommentCard;
