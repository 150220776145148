import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../config/hooks'
import CommonCard from '../BothCommon/CommonCard'
import { Link } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import { getNextEventApi } from '../../../slices/eventSlice'

const AllEvents = () => {
    const {events,metadata} = useAppSelector((state)=>state?.events.events)
    const dispatch=useAppDispatch()
    const getMoreDataHandler=()=>{
      dispatch(getNextEventApi({}))
    }
  return (
    <InfiniteScroll
    className="space-y-2 pb-4 no-scrollbar"
    dataLength={events?.length-2}
    next={() => getMoreDataHandler()}
    hasMore={metadata?.nextResultURL?true:false }
    // height={"calc(100vh - 155px)"}
    loader={""}
  >
    <div className='flex flex-wrap gap-2 mx-auto px-7 py-11'>
       {
          events?.map((event,index)=> 
          <Link  key={event.id} to={ `/tab/events/${event.id}`}>
           <CommonCard details={{title:event?.title,detail:event?.details,offline:event?.isOffline,venue:event?.venue,author:`${event.author.firstName} ${event.author.lastName}`,startDate:event?.parsedStartTime,bannerImageUrl:event?.bannerImageUrl,members:event?.members,cardType:"event"}}/>
          </Link>
        )
        }
    </div>
    </InfiniteScroll>
  )
}

export default AllEvents
