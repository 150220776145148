import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../config/hooks'
import { useNavigate } from 'react-router-dom'

import { RootState } from '../../../config/store'
import { useForm } from 'react-hook-form'
import Dropdown from './Dropdown'
import Switch from '../Switch'
import Attach from '../../../assets/icons/Attach'
import { updateJobApi } from '../../../slices/jobSlice'


interface Props{
    authorId?:string,
    authorType?:string,
    setIsOpen?:Function,
    isOpen?:boolean
  }

const UpdateJob = ({authorId,authorType,setIsOpen,isOpen}:Props) => {
    const dispatch=useAppDispatch()
    const navigate=useNavigate()
    const {userId}=useAppSelector((state:RootState)=>state.login.session)
    const {jobDes} =useAppSelector((state)=>state.job)
    const appConfigSelector = useAppSelector(
      (state) => state.appConfig
    );

    const { jobTypes, workplaceTypes,qualification,preferredLocation, industries ,currencies,experienceMinRange,experienceMaxRange,minSalaries,maxSalaries,functions,cities,skills}= appConfigSelector
  
    const {control,handleSubmit,register,watch,setValue,formState:{errors}}=useForm()
    const hideCompanyCheckBox=useRef<HTMLInputElement>()
    const hideSalaryCheckBox=useRef<HTMLInputElement>()
    const documentRef=useRef<HTMLInputElement>()
  
    const minimumExperience=watch("experience.min")
    const minimumSalary=watch("salary.min")
    const [curentIndex,setCurrentIndex]=useState(0)
    const [expIndex,setExpIndex]=useState(0)
    

   

    useEffect(()=>{
      const index = minSalaries.findIndex(item => parseInt(item.Title) === minimumSalary);
      setCurrentIndex(index)
      // console.log(index);
    },[minimumSalary])
    
    useEffect(()=>{
      const index = experienceMinRange.findIndex(item => parseInt(item.Title) === minimumExperience);
      setExpIndex(index)
      // console.log(index);
    },[minimumExperience])

    useEffect(() => {
      if (jobDes) {
          setValue('experience.min', jobDes?.experience?.min);
          setValue('experience.max', jobDes?.experience?.max);
          setValue('salary.min', jobDes?.salary?.min);
          setValue('salary.max', jobDes?.salary?.max);
      }
  }, [jobDes,setValue]);

    const onSubmit = async(data) => {
      console.log(data)
    
      dispatch(updateJobApi({authorId:authorId?authorId:userId, authorType:authorType?authorType:"USER", jobId:jobDes?.id,jobStatus:"OPEN",createdBy:authorId?authorId:userId,createdAt:jobDes?.createdAt,functions:[""],qualification:" ",...data}))
      setIsOpen(false)
      
      navigate(`/tab/jobs/hiring`)     
    
    //   if(authorType=="COMPANY"){
    //     navigate(`/tab/company/${authorId}`)
    //   }
    //   else{
    //     navigate(`/tab/jobs/hiring`)   
    //   }  

    };
    return (
      <div className="mx-12 p-8 dark:bg-lxgray-700 mt-1 rounded-lg">
        <div className="flex justify-between  items-center px-1">
          <h1 className="text-white text-lg font-semibold">
            Update Job Post
          </h1>
        </div>
  
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col mt-1'>
        <div className="   flex flex-col mb-4">
          <label htmlFor="" className="text-sm p-1 text-lxgray-300">
            Enter Job Title *
          </label>
          <input
            type="text"
            placeholder="Enter Title"
            defaultValue={jobDes?.title}
            className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white"
            {...register('title',{
              required:true
            })}
            
         />
         {errors.title && (
              <p className="text-red-500">Job title is required.</p>
            )}
        </div>
  
        <div className="   flex flex-col mb-4">
          <label htmlFor="" className="text-sm p-1 text-lxgray-300">
            Company Name *
          </label>
          <input
            type="text"
            placeholder="Your Company Name"
            defaultValue={jobDes?.company}
            className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white"
            {...register('company',{
              required:true
            })}
          />
          {errors.company && (
              <p className="text-red-500">Company name is required.</p>
            )}
          <div className="flex p-1 text-sm text-lxgray-300 items-center justify-between">
            <span className="">*You can Hide the company name from candidates</span>
            <div className="flex items-center">
           
            <input ref={hideCompanyCheckBox} type="checkbox"  defaultChecked={jobDes?.hideCompany} onChange={(e)=>setValue("hideCompany",e.target.checked)} className="hidden" />
            </div>
            <div onClick={()=>hideCompanyCheckBox.current?.click()}  {...register('hideCompany',{value:jobDes?.hideCompany})}><Switch isopen={jobDes?.hideCompany} /></div>
          </div>
        </div>
  
        <div className="flex flex-col mb-4">
          <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
            Industry *
          </label>
          <div className="text-white">
            <Dropdown name="industries" control={control} defaultValue={jobDes?.industries} placeholder={"Choose The Relevant Industry"} options={industries?.map((ele)=>{
            return { value:ele.Name,label:ele.Name}
            })}  />
          </div> 
        </div>
  
        {/* <div className="flex flex-col mb-4">
          <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
            Functions *
          </label>
          <div className="text-white">
            <Dropdown name="functions" control={control} defaultValue={jobDes?.functions} placeholder={"Choose The Relevant Industry"} options={industries.map((ele)=>{
            return { value:ele.Name,label:ele.Name}
            })}  />
          </div> 
        </div> */}
  
        <div className="  flex flex-col mb-4">
          <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
            Skills *
          </label>
          <div className="text-white">
            <Dropdown name="skills" control={control} defaultValue={jobDes?.skills} placeholder={"Choose The Relevant skills"} options={skills.map((ele)=>{
            return { value:ele.Name,label:ele.Name}
            })}  />
          </div> 
        </div>
  
        <div className="  flex flex-col mb-4">
        <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
         Qualification *
        </label>
        <div className="text-white">
          <Dropdown name="qualifications" control={control} defaultValue={jobDes?.qualifications} placeholder={"Choose The Relevant qualification"} options={qualification?.map((ele)=>{
          return { value:ele.Title,label:ele.Title}
          })}  />
        </div> 
      </div>
  
  
  
        <div className="flex flex-col mb-4">
          <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
            Job Type *
          </label>
          <div className="text-white">
          <select className='dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2  text-white w-full' defaultValue={jobDes?.jobType} {...register('jobType')}>
            <option value="" disabled>Select</option>
            {jobTypes.map((ele,i)=><option key={`${i}jobType`} value={ele.Title}>{ele.Title}</option>)}
          </select>
          </div>
        </div>
       
        <div className=" flex flex-col mb-4">
          <label htmlFor="" className="text-sm p-1 text-lxgray-300">
            Workplace Type *
          </label>
          <div className="text-white">
          <select className='dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2  text-white w-full' defaultValue={jobDes?.workplaceType} {...register('workplaceType')}>
            <option value="" disabled>Select</option>
            {workplaceTypes.map((ele,i)=><option key={`${i}jobType`} value={ele.Title}>{ele.Title}</option>)}
          </select>
          </div>
        </div>
  
        {/* <div className=" flex flex-col mb-4">
          <label htmlFor="" className="text-sm p-1 text-lxgray-300">
            Location *
          </label>
          <div className="text-white">
          <select className='dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2  text-white w-full' defaultValue={jobDes?.location} {...register('location')}>
            <option value="" disabled>Select</option>
            {cities.map((ele,i)=><option key={`${i}jobType`} value={ele.Name}>{ele.Name}</option>)}
          </select>
          </div>
          <div className="  flex flex-col mb-4"> */}
        
        <label htmlFor="" className="text-sm p-1 text-lxgray-300 ">
        Location *
        </label>
        <div className="text-white">
          <Dropdown name="location" control={control} defaultValue={jobDes?.location} placeholder={"Choose The Relevant location"} options={preferredLocation.map((ele)=>{
          return { value:ele.Title,label:ele.Title}
          })}  />
        </div> 
      
  
        <div className=" flex flex-col mb-4">
          <label htmlFor="" className="text-sm p-1 text-lxgray-300">
            Experience *
          </label>
          <div className="flex justify-between">
            <div className="text-white w-1/2">
              <select className='dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white w-full' defaultValue={jobDes?.experience?.min} {...register('experience.min', { setValueAs: (value) => parseInt(value) ,required:true})}>
              <option value="" disabled>Minimum</option>
              {experienceMinRange.map((ele,i)=><option key={`${i}expMIN`} value={(ele.Title.slice(0,2))}>{ele.Title}</option>)}
              </select>
            </div>
            <div className="text-white relative w-1/2 pl-2">
              <select className='dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white w-full' defaultValue={jobDes?.experience?.max} {...register('experience.max', { setValueAs: (value) => parseInt(value) ,required:true})} >
              <option value="" disabled>Maximum</option>
               { experienceMaxRange.filter((ele)=>ele.Id>expIndex+1).map((ele,i)=><option key={`${i}expMAX`} value={(ele.Title.slice(0,2))}>{ele.Title}</option>)}
              </select>
            </div>
          </div>
        </div>
        {errors['experience.min'] && (
                <p className="text-red-500">Minimum Experience is required</p>
              )}
         {errors['experience.max'] && (
                <p className="text-red-500">Maximum  Experience is required</p>
              )}
      
        <div className="flex text-lxgray-300 flex-wrap">
          <div className="flex min-w-1/5 flex-col pr-1">
            <label htmlFor="" className="text-sm p-1 ">
              Currency *
            </label>
            <select className='dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white' defaultValue={jobDes?.currency} {...register('currency')} required>
            {currencies.map((ele,i)=><option key={`${i}curr`} value={ele.Title}>{ele.Title}</option>)}
            </select>
          </div>
          <div className="flex flex-1 gap-1  justify-between ">
            <div className="flex flex-col w-1/2 pl-1 ">
              <label htmlFor="" className="text-sm p-1">
                Select your min salary  *
              </label>
              <select className='dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white' defaultValue={jobDes?.salary.min}  {...register('salary.min',{ setValueAs: (value) => parseFloat(value) ,required:true})} >
              <option value="" disabled>Minimum</option>
              {minSalaries.map((ele,i)=><option key={`${i}minSAL`} value={(ele.Title).slice(0,2)}>{ele.Title}</option>)}
              </select>
            </div>
  
            <div className="flex flex-col w-1/2 pl-1">
              <label htmlFor="" className="text-sm p-1 ">
                Select your max salary  *
              </label>
              <select className='dark:bg-lxgray-700 border border-lxgray-300 rounded-lg p-2 text-white' defaultValue={`${jobDes?.salary.max} ` } {...register('salary.max',{ setValueAs: (value) => parseFloat(value) ,required:true})} >
              <option value="" disabled>Maximum</option>
              { maxSalaries.filter((ele)=>ele.Id>curentIndex+2).map((ele,i)=><option key={`${i}maxSAL`} value={(ele.Title).slice(0,2)}>{ele.Title}</option>)}
              </select>
            </div>
          </div>
  
          {errors['salary.min'] && (
                <p className="text-red-500">{`Minimum salary is required`}</p>
              )}
         {errors['salary.max'] && (
                <p className="text-red-500">{`Maximum salary is required`}</p>
              )}
  
        </div>
          <div className="flex p-1 text-sm text-lxgray-300 items-center justify-between mb-3">
            <span className="">*You can Hide the salary from candidates</span>
            <div className="flex items-center">
            <input type="checkbox" ref={hideSalaryCheckBox} defaultChecked={jobDes?.hideSalary} onChange={(e)=>setValue("hideSalary",e.target.checked)} className="hidden" />
            </div>
            <div onClick={()=>hideSalaryCheckBox.current?.click()} {...register('hideSalary',{value:jobDes?.hideSalary})}><Switch isopen={jobDes?.hideSalary}/></div>
          </div>
  
        <div className="text-lxgray-300 mb-4 flex flex-col">
          <label htmlFor="" className="text-sm p-1 ">
            Hiring For *
          </label>
          <div className="flex items-center">
            <input
              type="radio"
              value="CLIENT"
              defaultChecked={jobDes.hiringFor==="CLIENT"?true:false}
              className=" w-4 h-4 mr-2 "
              {...register('hiringFor')}
            />
            <label htmlFor="client" className="text-white p-1 mr-6 ">
              Client
            </label>
  
            <input
              type="radio"
              value="COMPANY"
              defaultChecked={jobDes.hiringFor==="COMPANY"?true:false}
              className=" h-4 w-4 mr-2"
              {...register('hiringFor')}
            />
            <label htmlFor="company" className="text-white p-1 ">
              Company
            </label>
          </div>
        </div>
  
        <div className="text-lxgray-300 flex flex-col mb-4 relative">
          <label htmlFor="" className="text-sm p-1">
            Enter Job Description *
          </label>
          <textarea
            className="dark:bg-lxgray-700 border border-lxgray-300 rounded-lg text-white py-2 pr-8 pl-2 no-scrollbar"
            name=""
            id=""
            rows={15}
            placeholder="Type or Select document"
            maxLength={26000}
            defaultValue={jobDes?.description}
            {...register('description',{
              required: 'Description is required.',
              maxLength: {
                value: 26000, // Adjusted for approximate word limit
                message: 'Description cannot exceed 26000 words.',
              },
            })}
          
          >
          </textarea>
          <input type="file" ref={documentRef} className="hidden"/>
          <div onClick={()=>documentRef.current.click()} className="absolute bottom-2 right-2 cursor-pointer"><Attach/></div>
        </div>
  
       
  
        <div className="text-end">
          <button className="text-white text-sm bg-golden px-10 py-2 rounded-lg">
           Update
          </button>
        </div>
        </form>
      </div>
    );
}

export default UpdateJob
