import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { getMyClubsapi, getNextPendingClubApi } from "../../../slices/clubSlice";
import CommonCard from "../BothCommon/CommonCard";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

const PendingClubs = () => {
  const dispatch = useAppDispatch();
  const { clubs ,metadata} = useAppSelector((state) => state.clubs.pendingClubs);
  const [requestedClubs, setRequestedClubs] = useState([]);
  const [invitedClubs, setInvitedClubs] = useState([]);
  useEffect(() => {
    dispatch(getMyClubsapi({ clubType: "pending" }));
  }, []);

  useEffect(() => {
    // Update the state whenever pendingEvents changes
    if(clubs){
      const filterRequestedClubs = clubs.filter(
        (ele) =>
          ele?.requestMember?.status === "PENDING" &&
          ele?.requestMember?.type === "REQUEST"
      );
      const filterInvitedClubs = clubs.filter(
        (ele) =>
          ele?.requestMember?.status === "PENDING" &&
          ele?.requestMember?.type === "INVITE"
      );
  
      setRequestedClubs(filterRequestedClubs);
      setInvitedClubs(filterInvitedClubs);
    }
    
  }, [clubs]);

  const getMoreDataHandler=()=>{ 
    // console.log("HOSTED")
    dispatch(getNextPendingClubApi({}))   
  }

  // console.log("clubs", clubs);
  return (
    <>
    <InfiniteScroll
                  className="space-y-2 pb-4 no-scrollbar"
                  dataLength={clubs?.length-2}
                  next={() => getMoreDataHandler()}
                  hasMore={metadata?.nextResultURL?true:false }
                  // height={"calc(100vh - 155px)"}
                  loader={""}
                >
      {invitedClubs?.length > 0 && (
        <div>
          <h1 className="text-white text-lg py-2">Invited</h1>

          <div className="flex flex-wrap gap-2 justify-start">
            {invitedClubs.map((club) => (
              <Link key={club.id} to={`/tab/clubs/${club.id}`}>
                <CommonCard
                  details={{
                    title: club?.title,
                    detail: club?.details,
                    bannerImage: club?.bannerImage,
                    members: club.members,
                    cardType: "club",
                  }}
                />
              </Link>
            ))}
          </div>
        </div>
      )}

      {requestedClubs?.length > 0 && (
        <div className="mt-2">
          <h1 className="text-white text-lg py-2 ">Requested</h1>

          <div className="flex flex-wrap gap-2 justify-start">
            {requestedClubs.map((club) => (
              <Link key={club.id} to={`/tab/clubs/${club.id}`}>
                <CommonCard
                  details={{
                    title: club?.title,
                    detail: club?.details,
                    bannerImage: club?.bannerImage,
                    members: club.members,
                    cardType: "club",
                  }}
                />
              </Link>
            ))}
          </div>
        </div>
      )}
    </InfiniteScroll>
      <div>
        {clubs && clubs.length == 0 && (
          <h1 className="text-lxgray-200 text-center">No Clubs Available</h1>
        )}
      </div>
    </>
  );
};

export default PendingClubs;
