import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../config/hooks';
import AlternateProfile from './AlternateProfile';
import { getMyNetworksApi } from '../slices/networkSlice';
import {v4 as uuidv4} from "uuid"
import { sendBulkMessageApi } from '../slices/chatSlice';
import { displayName } from 'react-quill';
import { toast } from 'react-toastify';

const ShareWithUsers = ({setPopupShareVisible,contentType}) => {
  const dispatch=useAppDispatch()

    const {myNetworks}=useAppSelector((state)=>state.networks)
    const {jobDes}=useAppSelector((state)=>state.job)
    const {clubDetail}=useAppSelector((state)=>state.clubs)
    const {eventDetail}=useAppSelector((state)=>state.events)
    const {userId}=useAppSelector((state)=>state.login.session)
    const {network}=myNetworks
    

    const newUuid=uuidv4()
    
    useEffect(()=>{
        dispatch(getMyNetworksApi())
    },[])


    const users = [
        { id: 1, name: 'John Doe' },
        { id: 2, name: 'Jane Smith' },
        { id: 3, name: 'Sara Lee' },
        { id: 4, name: 'Michael Brown' },
        { id: 5, name: 'Chris Johnson' },
        { id: 6, name: 'Alex Harris' },
        { id: 6, name: 'Alex Harris' },
        { id: 6, name: 'Alex Harris' },
        { id: 6, name: 'Alex Harris' },
        { id: 6, name: 'Alex Harris' },
        { id: 6, name: 'Alex Harris' },
        { id: 6, name: 'Alex Harris' },
        { id: 6, name: 'Alex Harris' },
        // Add more users for testing the search functionality
      ];
    
      
      const [selectedUsers, setSelectedUsers] = useState([]);
      const [selectAll, setSelectAll] = useState(false);
      const [searchQuery, setSearchQuery] = useState('');
    
      // Handle individual user selection
      const handleUserChange = (userId) => {
        setSelectedUsers((prevSelected) => {
          if (prevSelected.includes(userId)) {
            return prevSelected.filter(id => id !== userId);
          } else {
            return [...prevSelected, userId];
          }
        });
      };
    
      // Handle 'select all' checkbox
      const handleSelectAllChange = () => {
        if (selectAll) {
          setSelectedUsers([]);
        } else {
          setSelectedUsers(network?.filter(user => user.otherUserDetails.firstName.toLowerCase().includes(searchQuery.toLowerCase())).map(user => user.otherUserDetails.userId));
        }
        setSelectAll(!selectAll);
      };
    
      // Handle search query change
      const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
    
        // If search query is cleared, we might need to reset the select-all logic
        if (query === '') {
          setSelectAll(false);
          setSelectedUsers([]);
        }
      };
    
      // Filter users based on search query
      const filteredUsers = network?network?.filter(user => user.otherUserDetails.firstName.toLowerCase().includes(searchQuery.toLowerCase())):[];
    //  console.log("FILTERESE ",filteredUsers,network)
      // Handle sharing (just a placeholder for this example)
      const handleShare = () => {
        if (selectedUsers.length === 0) {
          alert('Please select at least one user to share with.');
        } else {
          setPopupShareVisible(false)
          
          // alert(`Sharing with user(s): ${selectedUsers.join(', ')}`);
          // console.log(selectedUsers)
          if(contentType==="job" && jobDes){
            console.log("FROM JOB")
            dispatch(sendBulkMessageApi({
              tos:selectedUsers,
              sender:userId,
              content:{
                message:{
                  id:newUuid,
                  text:"Job Share",
                  type:4,
                  metadata:{
                    id:jobDes.id,
                    body:jobDes.location.join(", "),
                    contents:[
                     { url:jobDes.author.picUrl,
                      displayName:jobDes.industries.join(", "),
                      type:jobDes.workplaceType}
                    ],
                    title:jobDes.title
                  }
                }
              }
             }))
           }

           if(contentType==="club" && clubDetail){
            console.log("FROM CLUB")
            dispatch(sendBulkMessageApi({
              tos:selectedUsers,
              sender:userId,
              content:{
                message:{
                  id:newUuid,
                  text:"Club Share",
                  type:3,
                  metadata:{
                    id:clubDetail.id,
                    body:clubDetail.details,
                    contents:[
                     { url:clubDetail.bannerImage,
                      displayName:clubDetail.author.firstName + ' ' + clubDetail.author.lastName,
                      type: clubDetail.isPrivate ? 'Private' : 'Public'}
                    ],
                    title:clubDetail.title
                  }
                }
              }
             }))

           }

           if(contentType==="event" && eventDetail){
            console.log("FROM EVENT")
            dispatch(sendBulkMessageApi({
              tos:selectedUsers,
              sender:userId,
              content:{
                message:{
                  id:newUuid,
                  text:"Event Share",
                  type:2,
                  metadata:{
                    id:eventDetail.id,
                    body:eventDetail.details,
                    contents:[
                     { url:eventDetail.bannerImageUrl,
                      displayName:eventDetail.author.firstName + ' ' + eventDetail.author.lastName,
                      type: eventDetail.isOffline ? 'Offline' : 'Online'}
                    ],
                    title:eventDetail.title
                  }
                }
              }
             }))
           }

           
         toast.success("Shared successfully")
          }
      };
    
      return (
        <div className='min-h-[60vh]'>

          {/* Search Input */}
          <div>
            <input
              className='text-lxgray-500 w-full p-1 rounded bg-white'
              type="text"
              placeholder="Search users..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
    
          {/* 'Select All' checkbox */}
          {network && <div className='flex justify-between items-center mt-4 '>
            <label>         
                Select All Users
            </label> 
            <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAllChange}
                />
          </div>}
           
             
          <div className='h-[50vh]  overflow-y-scroll no-scrollbar' >
            {filteredUsers && filteredUsers.length === 0 ? (
              <p>No users found.</p>
            ) : (
              filteredUsers.map((user) => (
                <div key={user.id} className='flex justify-between items-center my-4 hover:bg-lxgray-500/50 '>
                  {/* Individual user checkbox */}
                  <div className='flex items-center gap-4'>
                    {
                    user.otherUserDetails.picUrl?
                    <img src={user.otherUserDetails.picUrl} alt=""  className='rounded-full object-cover w-12 h-12'/>
                    :
                    <AlternateProfile firstName={user.otherUserDetails.firstName} lastName={user.otherUserDetails.lastName} width='12' height='12'/>
                    }
                    <div>
                    <label>
                        {user.otherUserDetails.firstName} {user.otherUserDetails.lastName}
                    </label>
                    <h3 className='text-xs text-lxgray-200'>{user.otherUserDetails.organization}</h3>
                    </div>
                  </div>
                  <input
                      className='p-2'
                      type="checkbox"
                      checked={selectedUsers.includes(user.otherUserDetails.userId)}
                      onChange={() => handleUserChange(user.otherUserDetails.userId)}
                      disabled={selectAll}  // Disable individual selection if 'select all' is checked
                    />
                </div>
              ))
            )}
          </div>
    
          <button onClick={handleShare} className='bg-golden text-white px-4 py-1 rounded mt-4'>Share</button>
        </div>
      );
}

export default ShareWithUsers
