import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { getPostByIdApi } from "../../../slices/postSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { baseUrl, contentUrl } from "../../../utils/contant";
import BlankPlaceHolder from "../../../assets/images/Blank_Placeholders.png";
import PlusIcon from "../../../assets/icons/PlusIcon";
// import LikeIcon from "../../../assets/icons/LikeIcon";
// import CommentIcon from "../../../assets/icons/CommentIcon";
// import ShareIcon from "../../../assets/icons/ShareIcon";
import Comments from "../../../components/Comments";

const ViewArticleById = () => {
  const { postId } = useParams();
  const dispatch = useAppDispatch();
  const session = useAppSelector((state) => state.login.session);
  const postComments = useAppSelector((state) => state.comment);
  const [postDetails, setPostDetails] = useState(null);
  const [showMore, setShowMore] = useState(false);
  //   const [toggleComment, setToggleComment] = useState(false);

  const { comments } = postComments;

  useEffect(() => {
    const getPostDetailsHandler = async () => {
      const postApiResponse = await dispatch(getPostByIdApi(postId)).then(
        unwrapResult
      );
      setPostDetails(postApiResponse.data.data);
    };
    getPostDetailsHandler();
  }, [dispatch, postId]);

  //   const toggleCommentHandler = () => {
  //     setToggleComment(!toggleComment);
  //   };

  const viewMoreHandler = () => {
    setShowMore(true);
  };

  const viewLessHandler = () => {
    setShowMore(false);
  };

  const truncatedContent = showMore
    ? postDetails?.metadata?.content // Show full content
    : postDetails?.metadata?.content.substring(0, 1700); // Show truncated content

  return (
    postDetails && (
      <div className="space-y-6 ">
        <div className="w-full">
          <img
            src={
              postDetails?.metadata?.attachments?.length > 0
                ? `${baseUrl}/${contentUrl}/${postDetails?.metadata?.attachments[0]?.url}?t=${session.token}`
                : BlankPlaceHolder
            }
            alt="Image"
            className="object-cover w-full h-80 rounded-xl"
          />
        </div>

        <div className="w-full space-y-6">
          <h1 className="text-white text-3xl font-semibold tracking-wide ">
            {postDetails?.metadata?.title}
          </h1>

          <div className="border-b border-b-lxgray-600 flex items-center justify-center gap-6 pb-6">
            <div
              className="w-16 h-16  bg-no-repeat bg-cover bg-center rounded-full"
              style={{
                backgroundImage: postDetails?.author?.picUrl
                  ? `url(${baseUrl}/${contentUrl}/${postDetails?.author?.picUrl}?t=${session.token})`
                  : `url(${BlankPlaceHolder})`,
              }}
            ></div>
            <div className="flex-1 self-stretch flex flex-col  ">
              <h1 className="text-white text-2xl font-semibold ">{`${postDetails?.author?.firstName} ${postDetails?.author?.lastName}`}</h1>
              <p className="text-xl text-lxgray-400">
                {postDetails?.author?.designation}
              </p>
            </div>
            <button className="text-xl text-golden border-2 border-golden py-1 px-8 flex items-center justify-center gap-2 rounded-lg">
              <PlusIcon color="#AA933E" />
              Follow
            </button>
          </div>

          <div
            dangerouslySetInnerHTML={{
              __html: truncatedContent,
            }}
            className="text-lxgray-100"
          />
          <div className="pb-6 border-b border-b-lxgray-600 ">
            <div className="w-fit ml-auto mr-0">
              {showMore ? (
                <button
                  className="text-golden text-lg"
                  onClick={viewLessHandler}
                >
                  View Less
                </button>
              ) : (
                <button
                  className="text-golden text-lg"
                  onClick={viewMoreHandler}
                >
                  View More
                </button>
              )}
            </div>
          </div>
          <div>
            {/* <div className="w-full flex items-center justify-between border-t-2 border-lxgray-700 text-sm text-lxgray-400 font-semibold py-2 px-4">
              <h1 className="flex gap-2 items-center justify-center cursor-pointer px-2 text-center">
                <LikeIcon />
                {`${postDetails.counts.like} Applauses`}
              </h1>
              <h1
                className="flex gap-2 items-center justify-center cursor-pointer px-2 text-center"
                onClick={() => toggleCommentHandler()}
              >
                <CommentIcon />
                {`${postDetails.counts.comment} Comments`}
              </h1>
              <h1 className="flex gap-2 items-center justify-center cursor-pointer px-2 text-center">
                <ShareIcon />
                {`${postDetails.counts.share} Shares`}
              </h1>
            </div> */}
            {
              <Comments
                userContent={postDetails}
                comments={comments}
                parentId=""
                parent="article"
                key={postId}
              />
            }
          </div>
        </div>
      </div>
    )
  );
};

export default ViewArticleById;
