
import { useAppSelector } from "../config/hooks";
import { Link } from "react-router-dom";
import BlankPlaceHolder from "../assets/images/Blank_Placeholders.png";
import { baseUrl, contentUrl } from "../utils/contant";

const ArticlePost = ({ userContent }) => {
  const session = useAppSelector((state) => state.login.session);
  const {
    id,
    metadata: { attachments, title },
  } = userContent;
  return (
    <Link
      className="w-full rounded-b-3xl cursor-pointer"
      to={`/tab/article/${id}`}
    >
      <img
        src={
          attachments?.length > 0
            ? `${baseUrl}/${contentUrl}/${attachments[0]?.url}?t=${session.token}`
            : BlankPlaceHolder
        }
        alt={attachments[0]?.name}
        className="w-full h-80 object-cover"
      />
      <div className="w-full bg-golden text-white text-2xl font-semibold rounded-b-3xl py-2 px-7 tracking-wide truncate">
        {title}
      </div>
    </Link>
  );
};

export default ArticlePost;
