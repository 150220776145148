import React from 'react'
import { multipleValueDropdownByCategories, multipleValueDropdownByName } from '../../../utils/nocoFunctions'
import { useAppSelector } from '../../../config/hooks'

const DetailCE = ({text,category,email,industry,host}:{text?:string,category?:string[],email?:string,industry?:string[],host?:string}) => {
 const {industries,categories}= useAppSelector((state)=>state.appConfig)
 
  return (
    <div className='px-8 py-4 flex flex-col '>
      <div className='mb-4'>
        {/* <h1 className='text-lxgray-200'>Details</h1> */}
        <p className='text-lxgray-100 whitespace-pre-line'>{text}</p>
      </div>
            <div className='grid grid-cols-2 mb-3'>
                <div>
                    <h1 className='text-lxgray-200'>Category</h1>
                    <p className='text-lxgray-100'>{multipleValueDropdownByCategories(category,categories)}</p>
                </div>
                <div>
                  <h1 className='text-lxgray-200'>Email</h1>
                  <p className='text-lxgray-100'>{email}</p>
                </div>
            </div>
            <div className='grid grid-cols-2 '>
              <div>
                <h1 className='text-lxgray-200 '>Industry</h1>
                <p className='text-lxgray-100'>{multipleValueDropdownByName(industry,industries)}</p>
              </div>
              <div>
                <h1 className='text-lxgray-200'>Host</h1>
                <p className='text-lxgray-100'>{host}</p>
              </div>
            </div>
    </div>
  )
}

export default DetailCE
