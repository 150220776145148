import { ActionOnClub, AddClubMemberResponse, AddMembersRequest, Club, ClubResponse, CreateClubRequest, DeleteClub, GetClubDetails, GetClubRequest, UpdateClubRequest,  } from "../models/club";
import  apiClient from "../config/axiosConfig"
import {AxiosPromise} from "axios"
import { ApiResponse } from "../models/base";
import { Post } from "../models/post";
import { MemberResponse } from "../models/event";

class ClubService {
  // getParticipatedClubs(): AxiosPromise<ApiResponse<ClubResponse>> {
  //   return apiClient.get(`/v1/clubs/my?tab=participating`);
  // }
  // getOwnedClubs(): AxiosPromise<ApiResponse<ClubResponse>> {
  //   return apiClient.get(`/v1/clubs/my?tab=hoisting`);
  // }
  // getPendingClubs(): AxiosPromise<ApiResponse<ClubResponse>> {
  //   return apiClient.get(`/v1/clubs/my?tab=pending`);
  // }

  getAllClubs(): AxiosPromise<ApiResponse<ClubResponse>> {
    return apiClient.get(`/v1/clubs?tab=trending`);
  }
  getMyClubs(
    getClubs: GetClubRequest
  ): AxiosPromise<ApiResponse<ClubResponse>> {
    return apiClient.get(`/v1/clubs/my?tab=${getClubs.clubType}`);
  }
  
  getAIRecommendedClubs(
    getClubDetail: GetClubDetails
  ): AxiosPromise<ApiResponse<ClubResponse>>{
    return apiClient.get(`/v1/get_club_recommendations?id=${getClubDetail.clubId}`);  //You can add filter also like "/v1/jobs?filter={"expMin":5}"
  }

  getClubDetails(
    getClubDetail: GetClubDetails
  ): AxiosPromise<ApiResponse<Club>> {
    return apiClient.get(`/v1/clubs/${getClubDetail.clubId}`);
  }

  getClubPosts(
    getClubDetail: GetClubDetails
  ):AxiosPromise<ApiResponse<Post>>{
    return apiClient.get(`/v1/posts?authorId=${getClubDetail.clubId}`)
  }

  requestToClub(
    request: AddMembersRequest
  ): AxiosPromise<ApiResponse<Club>> {
    return apiClient.post(`/v1/clubs/${request.clubId}/request`, request);
  }

  createClub(
    createClub: CreateClubRequest
  ): AxiosPromise<ApiResponse<Club>> {
    return apiClient.post(`/v1/clubs`, createClub);
  }

  updateClub(
    updateClubReq: UpdateClubRequest
  ): AxiosPromise<ApiResponse<Club>> {
    return apiClient.put(`v1/clubs/${updateClubReq.clubId}`, updateClubReq);
  }

  getClubMembers(
    club: GetClubDetails
  ): AxiosPromise<ApiResponse<Array<MemberResponse>>> {
    return apiClient.get(`/v1/clubs/${club.clubId}/members`);
  }

  actionsOnMembers(
    club: ActionOnClub
  ): AxiosPromise<ApiResponse<AddClubMemberResponse>> {
    return apiClient.patch(`/v1/clubs/${club.clubId}/members/${club.memberId}/${club.action}`);
  }

  removeMember(
    club: ActionOnClub
  ): AxiosPromise<ApiResponse<AddClubMemberResponse>> {
    return apiClient.delete(`/v1/clubs/${club.clubId}/members/${club.memberId}`);
  }

  inviteToMember(
    invite: AddMembersRequest
  ): AxiosPromise<ApiResponse<Club>> {
    return apiClient.post(`/v1/clubs/${invite.clubId}/invite`, invite.data);
  }

  actionOnClubInvite(
    club: ActionOnClub
  ): AxiosPromise<ApiResponse<AddClubMemberResponse>> {
    return apiClient.patch(`/v1/clubs/${club.clubId}/members/${club.action}`);
  }

  deleteClub(
    club: DeleteClub
  ): AxiosPromise<ApiResponse<AddClubMemberResponse>> {
    return apiClient.delete(`/v1/clubs/${club.clubId}`);
  }

  getNextMembers(
    nextUrl: string
  ): AxiosPromise<ApiResponse<MemberResponse>> {
    return apiClient.get(nextUrl);
  }


  getClubByUrl(
    nextUrl: string
  ):
    AxiosPromise<ApiResponse<ClubResponse>> {
    return apiClient.get(nextUrl);  //You can add filter also like "/v1/jobs?filter={"expMin":5}"
  }
  
}

const clubService=new ClubService()
export default clubService;
