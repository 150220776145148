import { useEffect, useRef } from "react";

import {
  openProfileModal,
  toggleEditProfilPopupeHandler,
} from "../../../slices/profileSlice";
import { useAppDispatch } from "../../../config/hooks";
import { useParams } from "react-router-dom";

const EditProfile = ({ parentRef }) => {
  const { profileId } = useParams();
  const menuRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const popupHandler = (e: MouseEvent) => {
      if (
        menuRef.current &&
        parentRef.current &&
        !menuRef.current.contains(e.target as Node) &&
        !parentRef.current.contains(e.target as Node)
      ) {
        // close popup
        dispatch(toggleEditProfilPopupeHandler(false));
      }
    };
    document.addEventListener("mousedown", popupHandler);

    return () => {
      document.removeEventListener("mousedown", popupHandler);
    };
  });

  const toggleHandler = () => {
    const modalTypes = {
      profileModalType: "contactInfoPopup",
      editForm: false,
      editFormId: "",
    };
    dispatch(openProfileModal(modalTypes));
    dispatch(toggleEditProfilPopupeHandler(false));
  };

  const toggleEditProfile = () => {
    const modalTypes = {
      profileModalType: "editProfile",
      editForm: false,
      editFormId: "",
    };
    dispatch(openProfileModal(modalTypes));
    dispatch(toggleEditProfilPopupeHandler(false));
  };

  return (
    <div className="w-[30%] absolute right-3 flex flex-row-reverse gap-1">
      <div
        className={`w-full bg-lxgray-600 shadow-2xl text-lg text-white tracking-tight text-center font-light divide-y-2 divide-lxgray-400/30 rounded-s-xl rounded-br-xl`}
        ref={menuRef}
      >
        {!profileId && (
          <h1
            className="w-full py-2 cursor-pointer hover:opacity-90"
            onClick={toggleEditProfile}
          >
            Edit Profile
          </h1>
        )}
        {/* {!profileId && (
          <h1 className="w-full py-2 cursor-pointer hover:opacity-90">
            Create Company
          </h1>
        )}
        <h1 className="w-full py-2 cursor-pointer hover:opacity-90">
          Share Profile via Message
        </h1>
        <h1 className="w-full py-2 cursor-pointer hover:opacity-90">
          Copy Link
        </h1> */}
        <h1
          className="w-full text-golden py-2  font-normal cursor-pointer hover:opacity-90"
          onClick={toggleHandler}
        >
          Contact Info
        </h1>
      </div>
    </div>
  );
};

export default EditProfile;
