import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useAppSelector } from '../../../config/hooks';
import { Link } from 'react-router-dom';

const RewardsBanner = () => {
    const {banners}=useAppSelector((state)=>state.rewards)
  return (
    
    <div className=' pb-4'>

   
         <Carousel autoPlay={true} infiniteLoop={true} interval={2000} showStatus={false} showThumbs={false}>

            {banners.map((ele)=>{
                return (
                        <Link to={`/tab/rewards/products/productdetail/${ele.discover.productId}`}>
                    <div>
                            <img src={ele.discover.bannerUrl} alt="img"  className='h-72 rounded object-contain bg-lxgray-700'/>
                    </div>
                        </Link>
                )
            })}

            
                        
         </Carousel> 
   </div>
  )
}

export default RewardsBanner
