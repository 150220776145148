import React, { useState } from 'react';
import CrossIcon from '../../assets/icons/CrossIcon';

const CommonPopup = ({ children ,setIsOpen,isOpen}) => {
   
  return (
    <div className="fixed top-0 left-0  w-full h-full flex items-center justify-center bg-black bg-opacity-80 z-[54]">
      <div className="bg-lxgray-500  rounded-lg shadow-lg w-4/6 relative">
        <button
          className="absolute top-0 right-0 p-1 m-2 text-black hover:text-gray-800"
          onClick={()=>
            setIsOpen(!isOpen)
          }
        >
          <CrossIcon/>
        </button>
        <div className="max-h-svh overflow-y-auto no-scrollbar">
          {children}
        </div>
      </div>
    </div>
  );
}

export default CommonPopup;
