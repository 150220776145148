import { EntityState, PayloadAction, createAsyncThunk, createEntityAdapter, createSlice, current } from "@reduxjs/toolkit";
import {
  AllMessage,
  ChatMessage,
  Conversation,
  CreateChatRoom,
  CreateConversation,
  CreateConversationAndMessage,
  GetAllMessages,
  PaginatedMetadata,
  SendBulkMessage,
  SendMessage
} from "../models/chat";


import chatService from "../services/chat.service";
import { logout } from "./loginSlice";

const chatAdaptor = createEntityAdapter<ChatMessage>({});


export interface ChatReduxState {
  conversations: Array<Conversation>;
  messages: EntityState<ChatMessage,string>;
  metadata: PaginatedMetadata;
  sideChat:{
    active:boolean,
    jobId:string,
    applicationId:string
    title:string
  };
  openChatRoom:boolean,
  currentChat:{
    member:{firstName:string,lastName:string,picUrl:string,userId:string,designation:string,organization:string,organizationId:string|null}[],
    conversationId:string
  }[]
}
const initialState: ChatReduxState = {
  conversations: [],
  messages: chatAdaptor.getInitialState(),
  metadata: {
    maxId: null,
    minId: null,
    size: 10,
    nextURL: null,
    previousURL: null
  },
  sideChat:{
    active:false,
    jobId:null,
    applicationId:null,
    title:null
  },
  openChatRoom:false,
  currentChat:[]
};



export const createChatRoomApi = createAsyncThunk(
  "chat/createChatRoomApi",
  async (data: CreateChatRoom, { getState, rejectWithValue }) => {
    try {
      const response = await chatService.createChatRoom(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAllConversationsApi = createAsyncThunk(
  "chat/getAllConversationsApi",
  async (data: null, { getState, rejectWithValue }) => {
    try {
      const response = await chatService.getAllConversation();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const createConversationApi = createAsyncThunk(
  "chat/createChatRoomApi",
  async (data: CreateConversation, { getState, rejectWithValue }) => {
    try {
      const response = await chatService.createConversation(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const checkConversationApi = createAsyncThunk(
  "chat/checkConversationApi",
  async (data: CreateConversation, { getState, rejectWithValue }) => {
    try {
      const response = await chatService.checkConversation(data);
      //console.log(response, "this is check connversation response");
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const sendMessageApi = createAsyncThunk(
  "chat/sendMessageApi",
  async (data: SendMessage, { getState, rejectWithValue }) => {
    try {
      const response = await chatService.sendMessage(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const sendBulkMessageApi = createAsyncThunk(
  "chat/sendBulkMessageApi",
  async (data: SendBulkMessage, { getState, rejectWithValue }) => {
    try {
      const response = await chatService.sendBulkMessage(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const createConAndMessageApi = createAsyncThunk(
  "chat/createConAndMessageApi",
  async (data: CreateConversationAndMessage, { getState, rejectWithValue }) => {
    try {
      const response = await chatService.createConversationAndSendMessage(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAllMessageApi = createAsyncThunk(
  "chat/getAllMessageApi",
  async (data: GetAllMessages, { getState, rejectWithValue }) => {
    try {
      const response = await chatService.getAllMessage(data);
      console.log(response, "88888888888response")
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getMessageByUrlApi = createAsyncThunk(
  "chat/getMessageByUrlApi",
  async (nextUrl: string, { getState, rejectWithValue }) => {
    try {
      const state: any = getState();
      const response = await chatService.getMessageByUrl(state.chat.metadata.nextURL);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getReceivedMessage = createAsyncThunk(
  "chat/getReceivedMessage",
  async (data: ChatMessage, { getState, rejectWithValue }) => {
    try {
      const state: any = getState();
      //return response;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const clearMessages = createAsyncThunk(
  "chat/clearMessages",
  async (data: null, { getState, rejectWithValue }) => {
    try {
      const state: any = getState();
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const addChatMessage = createAsyncThunk(
  "chat/getMessageByUrlApi",
  async (data: any, { getState, rejectWithValue }) => {
    try {
      //console.log("hello ypu are inn chat slice are you ok no ok")
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);





export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    addMessage: (
      state: ChatReduxState,
      message
    ) => {
      console.log(state.messages, "state.messages");
      console.log(message, "this is new message come from some one");
    },
    sideChatActive:(state)=>{
        state.sideChat.active=!state.sideChat.active
    },
    chatRoomActive:((state)=>{
      state.openChatRoom=!state.openChatRoom
    }),
    addDetails:(state,action)=>{
        state.sideChat.jobId=action.payload.jobId
        state.sideChat.applicationId=action.payload.applicationId
        state.sideChat.title=action.payload.title
    },
    addChat:(state,action)=>{
      const check=state.currentChat.some((ele)=>ele.conversationId===action.payload.conversationId)
      if(check){
        const updatedChat=state.currentChat.filter((ele)=>ele.conversationId!==action.payload.conversationId)
        state.currentChat=updatedChat
        state.currentChat.push(action.payload)
      }
      else if(state.currentChat.length>=3){
        state.currentChat.shift()
        state.currentChat.push(action.payload)
      }else{
        state.currentChat.push(action.payload)
      }
    },
    removeChat:(state,action)=>{
      console.log("ACTION PAYLOAD",action.payload)
      const updatedChat=state.currentChat.filter((ele)=>ele.conversationId!==action.payload)
      state.currentChat=updatedChat
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllConversationsApi.fulfilled, (state: ChatReduxState, action) => {
      state.conversations = action.payload.data;
    });
    builder.addCase(getAllMessageApi.fulfilled, (state: ChatReduxState, action) => {
      //state.messages = action.payload.data.messages
      if (action.payload.data.messages === null) {
        state.messages = chatAdaptor.getInitialState();
      }
      else {
        chatAdaptor.setAll(state.messages, action.payload.data.messages);
        state.metadata = action.payload.data.metadata;
      }

    });
    builder.addCase(getMessageByUrlApi.fulfilled, (state: ChatReduxState, action) => {
      //console.log(action.payload.data.messages, "##################");
      //let messages = action.payload.data.messages;
      chatAdaptor.addMany(state.messages, action.payload.data.messages);
      //state.messages = [...messages, ...state.messages];
      state.metadata = action.payload.data.metadata;
    });
    builder.addCase(sendMessageApi.fulfilled, (state: ChatReduxState, action) => {
      chatAdaptor.addOne(state.messages, action.payload.data);

      console.log(action.payload.data,"MEsasgeAPi")
      const updatedConversations = state.conversations.map((conversation) => {
        if (conversation.id === action.payload.data.conversationId) {
          return {
            ...conversation,
            createdAt: action.payload.data.createdAt,
            lastMessage: action.payload.data.content.message.text
          };
        }
        return conversation;
      });
      state.conversations = updatedConversations;

    });
    builder.addCase(sendBulkMessageApi.fulfilled, (state: ChatReduxState, action) => {
      // chatAdaptor.addOne(state.messages, action.payload.data);

      console.log(action.payload,"BULK MESSAGE")
      // const updatedConversations = state.conversations.map((conversation) => {
      //   if (conversation.id === action.payload.data.conversationId) {
      //     return {
      //       ...conversation,
      //       createdAt: action.payload.data.createdAt,
      //       lastMessage: action.payload.data.content.message.text
      //     };
      //   }
      //   return conversation;
      // });
      // state.conversations = updatedConversations;

    });
    builder.addCase(getReceivedMessage.fulfilled, (state: ChatReduxState, action) => {
      console.log(action.meta.arg)
      chatAdaptor.addOne(state.messages, action.meta.arg);
     
      const updatedConversations = state.conversations.map((conversation) => {
        if (conversation.id === action.meta.arg.conversationId) {
          return {
            ...conversation,
            createdAt: action.meta.arg.createdAt,
            lastMessage: action.meta.arg.content.message.text
          };
        }
        return conversation;
      });
      state.conversations = updatedConversations;
     
      // conversations.map((conversation) => {
      //   if (con.id == action.meta.arg.id) {
      //     con.createdAt = action.meta.arg.createdAt;
      //     con.lastMessage = action.meta.arg.content.message.text;
      //   }
      //   return con;
      // })
      // console.log(conversations, "action.meta.argaction.meta.argaction.meta.arg")
      // state.conversations = conversations;
    });
    builder.addCase(createConAndMessageApi.fulfilled, (state: ChatReduxState, action) => {
      
      chatAdaptor.addOne(state.messages, action.payload.data);

    });
    builder.addCase(createConversationApi.fulfilled,(state,action)=>{
      console.log(action.payload.data)
      const newupdated=action?.payload?.data
      state.conversations.push(newupdated)
    })
    builder.addCase(clearMessages.fulfilled, (state: ChatReduxState, action) => {
      state.messages = chatAdaptor.getInitialState();
    });
    builder.addCase(logout, (state) => {
      // Reset this slice's state to initial state
      return initialState;
    });

  },
});

export const { addMessage ,sideChatActive,addDetails,chatRoomActive,addChat,removeChat} = chatSlice.actions;


export default chatSlice.reducer;