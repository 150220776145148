import { Link, useNavigate } from "react-router-dom";
import { useLinkedIn } from 'react-linkedin-login-oauth2';
// import EmailMobileNumber from "@linkcxo/core/src/modules/login/components/EmailMobileNumber";
// import LogoLight from "../../assets/icons/LinkcxoDarkIcon";
// import SignUp_Screen from "../../assets/images/sign_up.png";
import BackgroundImage from "../../assets/images/login_background_web.jpg";

import { RootState } from "../../config/store";
import LxInput from "./basic-components/LxInput";
// import LxButton from "@linkcxo/core/src/components/basic/LxButton";
import { ChangeEvent, useEffect, useState } from "react";
// import ValidationUtils from "@linkcxo/core/src/utils/validation";
import { GetOTPRequest } from "../../models/auth";
import { getOtpApi } from "../../slices/loginSlice";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { Controller, useForm } from "react-hook-form";
import PhoneIcon from "../../assets/icons/PhoneIcon";
import StandardPhoneIcon from "../../assets/icons/StandardPhoneIcon";
import StandardEmailIcon from "../../assets/icons/StandardEmailIcon";
import LxCountryDropdown from "./basic-components/LxCountryDropdown";
// import { LinkIcon } from "@heroicons/react/24/outline";
// import LinkedinIcon from "../../assets/icons/LinkedinIcon";
import LinkedInIcon from "../../assets/icons/LinkInIcon";
import OtpVerificationPopup from "./OtpVerificationPopup";
import { CLIENT_ID, REDIRECT_URI, baseUrl } from "../../utils/contant";
import { Statictoken } from "../../assets/images/url/url";
import LinkcxoDarkIcon from "../../assets/icons/LinkcxoDarkIcon";

type FormValues = {
  emailId: string;
  phoneNo: string;
  countrycode: {
    Id: number;
    CountryName: string;
    Iso3: string;
    PhoneCode: string;
  };
};

const LoginWithEmailPage = () => {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [error, setError] = useState("");
  // const currentLocation = useAppSelector((state) => state.appConfig.location);
  const currentLocation = "India"
  const [countryCodes, setCountryCodes] = useState([]);
  
  // const countryCodes = useAppSelector((state) => state.appConfig.countries);
  const dispatch = useAppDispatch();
  const ui = useAppSelector((state: RootState) => state.login.ui);
  const [isModalOpen, setModalOpen]=useState(false)
  const { requestId } = ui;


  
  useEffect(()=>{
    fetchCountries();
   
},[])

const fetchCountries = async () => {

  try {
    const response = await fetch(`${baseUrl}/v1/configuration/api/v1/db/data/v1/linkcxo/Countries?limit=500&fields=Id,CountryName,PhoneCode,Iso3`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Statictoken}`,
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    console.log("Response.json",data)
    setCountryCodes(data.list);
  } catch (error) {
    console.error("Error fetching jobs:", error);
  }
};
 


const handleLinkedInLogin = () => {
  console.log("HELLO LINKEDIN")
  const LINKEDIN_AUTH_URL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&scope=email%20profile%20openid&state=${Date.now().toString()}`;
  window.location.href = LINKEDIN_AUTH_URL;
};


  const defualtCouontryCode =
    currentLocation &&
    countryCodes.find(
      (countryDetail) =>
        // currentLocation?.address?.country.toLowerCase() ===
        currentLocation.toLowerCase() ===
        countryDetail.CountryName.toLowerCase()
    );

  const modifiedCountryCodes = countryCodes?.map((country) => ({
    ...country,
    PhoneCode: `${country.Iso3} +${country.PhoneCode}`,
  }));

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    defaultValues: {
     
      countrycode: {
        CountryName: "India",
        Iso3: "IND",
        PhoneCode: "IND +91",
        Id: 96,
        // PhoneCode: `${"IND"} +${"91"}`,
      }
        //   defualtCouontryCode && {
        // ...defualtCouontryCode,
        // // PhoneCode: `${defualtCouontryCode.Iso3} +${defualtCouontryCode.PhoneCode}`,
        // PhoneCode: `${"IND"} +${"91"}`,
      
    },
  });

  // useEffect(() => {
  //   if (requestId) {
  //     // navigate("/otp");
  //     // setModalOpen(!openModal);
  //   }
  // }, [requestId]);

  const onChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailOrPhone(e.target.value);
    clearError();
  };

  const clearError = () => {
    setError("");
  };

  const getOTP = async (req: GetOTPRequest) => {
    dispatch(getOtpApi(req));
  };

  const toggleButtonHandler = () => {
    setToggle(!toggle);
    reset();
  };

  const onSubmitFormHandler = async (formData: FormValues) => {
    console.log("formData :>> ", formData);
    const req: GetOTPRequest = {};

    if (formData.countrycode && formData.phoneNo) {
      const numbers = formData.countrycode.PhoneCode.match(/\d+/g);
        setEmailOrPhone(`Mobile No- ${formData.phoneNo}`)
      if (numbers) {
        const countryCode = parseInt(numbers[0]);
        req.countryCode = countryCode;
        req.phoneNo = parseInt(formData.phoneNo);
      }
    } else {
      setEmailOrPhone(`Email Id- ${formData.emailId}`)
      req.emailId = formData.emailId;
    }
    getOTP(req);
    setModalOpen(true);
  };

  const openModal = () => {
    // setActiveEmailPhone(type);
    setModalOpen(true);
    
  };
  const handleCreateNewAccount=()=>{
    navigate("/signup")
  }

  return (
    <>
      {/* <section
        className="h-screen bg-cover md:hidden border-4"
        style={{
          backgroundImage: `url(${BackgroundImage})`,
        }}
      >
        <main className="w-[80%] mx-auto my-auto">
          <EmailMobileNumber onContinue={() => {}} onBack={() => {}} />
        </main>
      </section> */}

      <div style={{
          backgroundImage: `url(${BackgroundImage})`,
        }} className=" h-screen flex flex-col sm:flex-row sm:items-center sm:justify-center">
            
            <div className="h-screen   hidden sm:flex flex-col sm:w-1/2 ">
              
              <div className=" px-5 pt-8 h-1/3">
                <h1 className=" text-white  text-3xl 2xl:text-4xl  font-semibold  text-center mb-6 ">Exploring New Frontiers: Unlocking Insights on <span className="text-golden"> LinkCxO </span> Leadership </h1>
                {/* <span className="flex items-center text-white  text-3xl 2xl:text-4xl text-center gap-3   font-semibold mb-6">   <LinkcxoDarkIcon/>  </span> */}
                 <p className="text-sm 2xl:text-base text-lxgray-100 text-center">Discover the strategies and expertise driving the future of CxO leadership at Link. Elevate your skills and advance to the next level of CxO excellence.</p>
              </div>
              <div className="h-3/5 ">
                 <img  className="w-full h-full " src={"https://stagebucket.blob.core.windows.net/linkcxo/interest-icons-linkcxo/interest-icons-linkcxo/Group%201000006204%201.png"} alt="SIgnUP" />
              </div>
            </div>
            
            <div className={` sm:w-1/2 text-white bg-lxgray-700  flex flex-col gap-6 items-center justify-center h-screen ${toggle?"sm:px-32 px-4":"sm:px-24 px-4"}`}>
             
                  <div>
                    <h1 className="text-2xl text-center">Login / Signup</h1>
                    <p className="text-sm text-lxgray-300 mt-0.5 text-center">
                      Lets get started with your {!toggle?"mail":"phone"}
                    </p>
                  </div>
                
                <div className="">
                  {/* <label className="text-lxgray-300 text-sm pl-3">
                    Enter Your Email / Phone Number
                  </label>
                  <section className="mt-2">
                    <LxInput onChange={onChangeText} />
                  </section>
                  {error && <InlineError error={error} />}
                </section>
                <section className="w-[60%] border">
                  <div onClick={() => onSubmitFormHandler()}>
                    <LxButton>Continue</LxButton>
                  </div> */}
                  <form
                    onSubmit={handleSubmit(onSubmitFormHandler)}
                    className=""
                  >
                    {!toggle ? (
                      <div  className=" w-[370px]">
                        
                      
                      <Controller
                        name="emailId"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Please enter valid email ID",
                          },
                          pattern: {
                            value:
                              /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                            message: "Please enter a valid email id",
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <LxInput
                            className={`h-[50px]  min-w-1/2 max-w-1/2 border-2 ${
                              errors?.emailId?.message
                                ? "border-red-600"
                                : "border-lxgray-400"
                            }`}
                            placeholder={"abc@gmail.com"}
                            title="Email ID *"
                            name="emailId"
                            value={value || ""}
                            onChange={onChange}
                            error={errors?.emailId?.message}
                          />
                        )}
                      />
                      </div>
                    ) : (
                      <div className="flex items-center justify-center gap-2   max-w-[370px]">
                        <div className="self-baseline">
                          <Controller
                            name="countrycode"
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: "Required",
                              },
                            }}
                            render={({ field: { onChange, value } }) => (
                              <LxCountryDropdown
                                title="Country Code *"
                                onChange={onChange}
                                placeholder="Select"
                                value={value || null}
                                optionValue="Id"
                                optionLabel="PhoneCode"
                                options={modifiedCountryCodes}
                                dropDownHeight="50px"
                                dropDownBorderWidth="2px"
                                dropDownBorderColor="#696969"
                                error={errors?.countrycode?.message}
                              />
                            )}
                          />
                        </div>
                        <div className="flex-1 self-baseline">
                          <Controller
                            name="phoneNo"
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: "Mobile number is required",
                              },
                              pattern: {
                                value: /^[0-9]+$/,
                                message: "Please enter a valid mobile number",
                              },
                            }}
                            render={({ field: { onChange, value } }) => (
                              <LxInput
                                className={`h-[50px]   border-2 ${
                                  errors?.phoneNo?.message
                                    ? "border-red-600"
                                    : "border-lxgray-400"
                                }`}
                                placeholder={"Enter Your Mobile Number"}
                                title="Mobile Number *"
                                name="phoneNo"
                                value={value || ""}
                                onChange={onChange}
                                error={errors?.phoneNo?.message}
                              />
                            )}
                          />
                        </div>
                      </div>
                    )}
                    <div className="flex items-center justify-center mt-5 ">
                      <div className="border  border-lxgray-400 flex-1"></div>
                      <p className="text-lxgray-400 w-fit text-sm text-center leading-none mx-1 pb-1">
                        Or Login/ Signup with
                      </p>
                      <div className="border border-lxgray-400 flex-1"></div>
                    </div>

                    <div>
                      <div className="w-fit mx-auto my-4">
                        <button
                          type="button"
                          className="w-fit cursor-pointer rounded-full"
                          onClick={toggleButtonHandler}
                        >
                          {toggle ? (
                            <StandardEmailIcon height="45" width="45" />
                          ) : (
                            <StandardPhoneIcon height="45" width="45" />
                          )}
                        </button>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="py-2 text-white text-base tracking-wider bg-golden w-full rounded-lg"
                    >
                      Continue
                    </button>
                    </form>
                    <button
                      type="button"
                      className="py-2 text-white text-base tracking-wider border border-lxgray-200 w-full rounded-lg mt-4"
                    >
                      <div onClick={handleLinkedInLogin} className="flex justify-center gap-2 items-center">
                        <LinkedInIcon/>
                         <h1>Continue with Linkedln</h1> 
                      </div>
                    </button>

                    
                      {/* <button
                      onClick={handleCreateNewAccount}
                        type="button"
                        className="py-2 text-white text-base tracking-wider border border-lxgray-200 w-full rounded-lg mt-4 "
                        >
                          Create New Account
                      </button> */}
                  
                </div>
            </div>
   
      </div>
      {  <div className="">
                       { <OtpVerificationPopup isOpen={isModalOpen} onClose={() => setModalOpen(false)}  emailOrPhone={emailOrPhone}/>}
      </div>}
      
    </>
  );
};

export default LoginWithEmailPage;
