import React from "react";
// import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
// import TimeFormatter from "../../formatter/TimeFormatter";
// import { Session } from "../../../../../web/src/models/auth";
// import UserAvatar from "./UserAvatar";
import { useAppDispatch } from "./../../../config/hooks";
import { openCreatePostModalType } from "./../../../slices/homeFeedSlice";
import { Link } from "react-router-dom";
export interface PostMenuCardProps {
  title?: string;
  subTitle?: string;
  description?: string;
  postIcon?: any;
  closeMenuHandler?: () => void;
  
}

export default function PostMenuCard({ postType, closeMenuHandler}) {
  const dispatch = useAppDispatch();
  const { title, icon, description, type } = postType;
  
// console.log("Type",type)
// console.log(closeMenuHandler)
  const togglepopup = async (modalType: string) => {
    console.log(modalType,"ModalType")
    await closeMenuHandler();
    dispatch(openCreatePostModalType(modalType));
  };

  return type !== "article" ? (
    <div
      onClick={() => togglepopup(type)}
      className="flex flex-row bg-lxgray-600 rounded-xl items-center justify-start gap-4 h-full p-4 cursor-pointer hover:bg-lxgray-800/50 "
    >
      <div className="cursor-pointer shadow-xl shadow-black ">{icon}</div>
      <div className="w-5/6">
        <h1 className="text-xl  font-semibold text-white cursor-pointer">
          {title}
        </h1>
        <p className="text-sm text-lxgray-200 cursor-pointer">
          {description}
        </p>
      </div>
    </div>
  ) : (
    <Link to={"/tab/article"}>
      <div
        // onClick={() => togglepopup(type)}
        className="flex flex-row bg-lxgray-600 rounded-xl items-center justify-start gap-4 h-full p-4 cursor-pointer hover:bg-lxgray-800/50 "
      >
        <div className="cursor-pointer shadow-xl shadow-black">{icon}</div>
        <div className="w-5/6">
          <h1 className="text-xl font-semibold text-white cursor-pointer">
            {title}
          </h1>
          <p className="text-sm text-lxgray-200 cursor-pointer">
            {description}
          </p>
        </div>
      </div>
    </Link>
  );
}
