import Profile from "./../../../assets/images/Blank_Placeholders.png";
import { baseUrl, contentUrl } from "../../../utils/contant";
import { useAppSelector } from "../../../config/hooks";
import AlternateProfile from "../../../components/AlternateProfile";

const NetworksAndClubsList = ({ list, heading }) => {
  const { token } = useAppSelector((state) => state.login.session);
  // console.log(list, "LIUSTTS");
  const { firstName, lastName, organization, designation, picUrl } =
    list?.otherUserDetails || {};
  const { members, title, bannerImage } = list || {};
  return (
    <div>
      {heading === "Connections" && (
        <div className="flex gap-3">
          {picUrl ?
            <img
              src={`${baseUrl}/${contentUrl}/${picUrl}?t=${token}` || Profile}
              alt=""
              className="rounded-full w-16 h-16 object-cover"
            />
            :
            <AlternateProfile firstName={firstName} lastName={lastName} width="16" height="16"/>
          }
          <div>
            <div className="flex gap-2">
              <h1 className="text-lg font-semibold text-white">{firstName}</h1>
              <h1 className="text-lg font-semibold text-white">{lastName}</h1>
            </div>
            {/* <p className='text-xs text-white'>+140 members</p> */}
            <p className="text-xs text-lxgray-200">{designation}</p>
            <p className="text-xs text-golden">{organization}</p>
          </div>
        </div>
      )}

      {heading === "Clubs" && (
        <div className="flex gap-3">
          <img
            src={`${baseUrl}/${contentUrl}/${bannerImage}?t=${token}` || Profile}
            alt=""
            className="rounded-full w-16 h-16 object-cover"
          />
          <div>
            <h1 className="text-lg font-semibold text-white">{title}</h1>

            <p className="text-sm text-lxgray-200">
              {members.length} {`${members.length <= 1 ? "member" : "members"}`}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default NetworksAndClubsList;
