import React, { ChangeEvent, useRef, useState } from "react";
import ReactQuill from "react-quill";
import CustomToolbar, { modules, formats } from "./CustomToolBar";
import "./textEditor.css";
import "react-quill/dist/quill.snow.css";
import BlankPlaceHolder from "../../../assets/images/Blank_Placeholders.png";
import EditIcon from "../../../assets/icons/EditIcon";
import uploadContent from "../../../config/UploadContent";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { baseUrl, contentUrl } from "../../../utils/contant";
import { Link, useNavigate } from "react-router-dom";
import { ToastOptions, toast } from "react-toastify";
import { articleErrorPosition } from "../../../components/Notify/MessagePosition";
import { createPostApi } from "../../../slices/postSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { closeFeedsModal } from "../../../slices/homeFeedSlice";

const TextEditor: React.FC = () => {
  const imageInputRef = useRef(null);
  const inputRef = useRef(null);
  const textEditorRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const session = useAppSelector((state) => state.login.session);
  const articleData= useAppSelector((state) => state.createHomeFeedsData);
  const {articleAuthorId,articleAuthorType} = articleData
  const [imageUrl, setImageUrl] = useState([]);
  const [loadingUploadContent, setLoadingUploadContent] = useState(false);
  const [value, setValue] = useState<string>("");
  
  const handleChange = (value: string) => {
    setValue(value);
  };

  const inputImageHandler = () => {
    imageInputRef.current.click();
  };

  const textEditorFocusHandler = () => {
    textEditorRef.current.focus();
  };

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files;
    setLoadingUploadContent(true);
    uploadContent.uploadContent(session.userId, file, (response: any) => {
      setImageUrl(response);
      setLoadingUploadContent(false);
    });
  };

  const postArticleHandler = async () => {
    if (!inputRef.current.value) {
      toast.error("Title is required", articleErrorPosition as ToastOptions);
    } else if (!value) {
      toast.error(
        "Description is required",
        articleErrorPosition as ToastOptions
      );
    }

    if (inputRef.current.value && value) {
      setLoadingUploadContent(true);
      try {
        const postArticleResponse = await dispatch(
          createPostApi({
            authorType: articleAuthorType,
            authorId: articleAuthorId,
            postType: "ARTICLE",
            metadata: {
              title: inputRef.current.value,
              content: value,
              attachments: imageUrl,
            },
            id: "",
          })
        ).then(unwrapResult);
        if (postArticleResponse) {
          setLoadingUploadContent(false);  

          dispatch(closeFeedsModal());
          if(articleAuthorType=="USER"){
            navigate("/tab");
          }
          if(articleAuthorType=="COMPANY"){
            navigate(`/tab/company/${articleAuthorId}`) 
          }
          if(articleAuthorType=="CLUB"){
            navigate(`/tab/clubs/${articleAuthorId}`)  
          }

        }
      } catch (error) {
        toast.error(
          "Something went wrong",
          articleErrorPosition as ToastOptions
        );
        setLoadingUploadContent(false);
      }
    }
  };

  return (
    <div className="h-full w-full">
      <div className="flex gap-4 items-center justify-between">
        <div className="w-fit">
          <CustomToolbar />
        </div>
        <div className="flex gap-4">
          <Link to={"/tab"}>
            <button className="border-2 border-golden rounded-lg  h-10 px-8 text-center text-golden text-base font-semibold">
              Back
            </button>
          </Link>
          <button
            disabled={loadingUploadContent}
            className={`bg-golden rounded-lg h-10 px-11 text-white text-center text-base font-bold ${
              loadingUploadContent && "opacity-60"
            }`}
            onClick={postArticleHandler}
          >
            Post
          </button>
        </div>
      </div>
      <div
        className="w-full h-80 my-4 bg-no-repeat bg-cover bg-center p-3 rounded-xl"
        style={{
          backgroundImage:
            imageUrl.length > 0
              ? `url(${baseUrl}/${contentUrl}/${imageUrl[0]?.url}?t=${session.token})`
              : `url(${BlankPlaceHolder})`,
        }}
      >
        <div
          className="h-10 w-10 rounded-full bg-lxgray-400 flex items-center justify-center ml-auto mr-0 cursor-pointer"
          onClick={inputImageHandler}
        >
          <EditIcon height="18" width="18" color="#181919" />
          <input
            ref={imageInputRef}
            type="file"
            name="image"
            multiple
            accept="image/*"
            onChange={onChangeHandler}
            style={{ display: "none" }}
          />
        </div>
      </div>
      <div>
        <input
          ref={inputRef}
          type="text"
          className="w-full border-b-2 border-b-lxgray-400 text-4xl bg-transparent text-white placeholder:text-lxgray-400 placeholder:text-5xl outline-none pb-2 font-semibold"
          placeholder="Title"
          maxLength={140}
        />
      </div>
      <div
        className="text-lxgray-400 w-full mt-2"
        onClick={textEditorFocusHandler}
      >
        <ReactQuill
          ref={textEditorRef}
          className="min-h-44 scrollbar-hidden text-white cursor-text"
          value={value}
          onChange={handleChange}
          modules={modules}
          formats={formats}
          placeholder="Share industry insights, case studies, and expert opinions on how AI is reshaping sectors like healthcare, finance, and marketing.Engage readers with thought-provoking discussions and encourage interaction on AI-related topics."
          theme="snow"
        />
      </div>
    </div>
  );
};

export default TextEditor;
