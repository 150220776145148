import React from "react";
import ProfileIcon from "../../../assets/icons/addProfileIcon.png";
import { Link } from "react-router-dom";

const ProfilePhoto = () => {
  return (
    <div className="dark:bg-lxgray-700 px-6 p-2 rounded">
      <div className="flex flex-col justify-center items-center p-4">
        <img src={ProfileIcon} alt="Profile" className="object-cover" />
        <h1 className="text-golden text-center font-bold">
          Add a profile photo to help others recognize you{" "}
        </h1>
        <p className="text-lxgray-200 text-sm py-4 px-6 text-center">
          Members with a profile photo receive up to 2.3 times as many profile
          views.
        </p>
        <Link to={"/tab/profile"}>
          <button className="border border-golden rounded-lg text-golden text-center px-4  py-1 hover:text-white hover:border-white ">
            <span className=" text-lg">+</span> Add Button
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ProfilePhoto;
