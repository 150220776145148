import React, { useState } from 'react'
import PlusIcon from '../../../assets/icons/PlusIcon'
import CommonPopup from '../../company/CommonPopup'
import Discover from '../../job/components/Discover'
import CreateEvent from '../Events/CreateEvent'
import { useAppSelector } from '../../../config/hooks'
import CreateJob from '../../job/components/CreateJob'

const AddMultiple = ({placeholder,type}:{placeholder:string,type:string}) => {
    const[isOpen,setIsOpen]=useState(false)
    const {clubDetail} =useAppSelector((state)=>state.clubs)
    const {company}=useAppSelector((state)=>state.company)
  return (
    <>
   {type==="createEvent" &&  <div  className='flex items-center justify-center  '>

        <p className='rounded-full p-2 w-full text-lxgray-400 bg-white cursor-pointer'>{placeholder} </p>
        <div onClick={()=>setIsOpen(true)} className='bg-golden rounded-full p-3 pointer cursor-pointer relative -left-9'><PlusIcon/></div>
        {isOpen && <CommonPopup setIsOpen={setIsOpen} isOpen={isOpen}><CreateEvent authorId={clubDetail?.id} authorType='CLUB' setIsOpen={setIsOpen}/></CommonPopup>}
    </div>}

    {type==="createCompanyEvent" &&  <div  className='flex items-center justify-center  '>

        <p className='rounded-full p-2 w-full text-lxgray-400 bg-white cursor-pointer'>{placeholder} </p>
        <div onClick={()=>setIsOpen(true)} className='bg-golden rounded-full p-3 pointer cursor-pointer relative -left-9'><PlusIcon/></div>
        {isOpen && <CommonPopup setIsOpen={setIsOpen} isOpen={isOpen}><CreateEvent authorId={company?.id} authorType='COMPANY' setIsOpen={setIsOpen}/></CommonPopup>}
    </div>}

    {type==="createPost" &&  <div className='flex items-center justify-center '>

        <p className='rounded-full p-2 w-full text-lxgray-400 bg-white cursor-pointer'>{placeholder} </p>
        <div  className='bg-golden rounded-full p-3 pointer cursor-pointer relative -left-9'><PlusIcon/></div> 
    </div>}
    {type==="createJob" &&  <div  className='flex items-center justify-center '>

        <p className='rounded-full p-2 w-full text-lxgray-400 bg-white cursor-pointer'>{placeholder} </p>
        <div onClick={()=>setIsOpen(true)} className='bg-golden rounded-full p-3 pointer cursor-pointer relative -left-9'><PlusIcon/></div>
        {isOpen && <CommonPopup setIsOpen={setIsOpen} isOpen={isOpen}><CreateJob authorId={company?.id} authorType='COMPANY' setIsOpen={setIsOpen} isOpen={isOpen}/></CommonPopup>}
    </div>}

    {type==="addMedia" &&  <div  className='flex items-center justify-center '>

        <p className='rounded-full p-2 w-full text-lxgray-400 bg-white cursor-pointer'>{placeholder} </p>
        <div className='bg-golden rounded-full p-3 pointer cursor-pointer relative -left-9'><PlusIcon/></div>
       
    </div>}
    </>
  )
}

export default AddMultiple
