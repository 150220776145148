import apiClient from "../config/axiosConfig";
import { AxiosPromise } from "axios";
import { ApiResponse } from "../models/base";
import {
  DeletePost,
  PaginatedPostRequest,
  PaginatedPostResponse,
  Post,
  PostCardAction,
  PostResponse,
} from "../models/post";
import { BookmarkData, BookmarkResponse } from "../models/bookmark";

class PostService {
  createNewPost(createPostReq: Post): AxiosPromise<ApiResponse<PostResponse>> {
    return apiClient.post(`/v1/posts`, createPostReq);
  }

  getPostById(id: string): AxiosPromise<ApiResponse<PostResponse>> {
    return apiClient.get(`/v1/posts/${id}`);
  }
  getAllPost(
    req: PaginatedPostRequest
  ): AxiosPromise<ApiResponse<PaginatedPostResponse>> {
    return apiClient.get(`/v1/posts?authorId=${req.authorId}`);
  }
  getPostByUrl(
    nextUrl: string
  ): AxiosPromise<ApiResponse<PaginatedPostResponse>> {
    return apiClient.get(nextUrl);
  }
  doAction(
    postId: string,
    action: string
  ): AxiosPromise<ApiResponse<PaginatedPostResponse>> {
    return apiClient.post(`/v1/posts/${postId}/actions`, {
      action,
    });
  }

  postCardAction(
    postData: PostCardAction,
  ): AxiosPromise<ApiResponse<PaginatedPostResponse>> {
    return apiClient.post(`/v1/posts/card/actions`, {
      action: postData.action,
      postId: postData.postId,
      summary: postData.summary
    });
  }
  submitPollAnswer(
    postId: string,
    optionId: string
  ): AxiosPromise<ApiResponse<PaginatedPostResponse>> {
    return apiClient.post(`/v1/posts/poll/${postId}/vote`, {
      optionId,
    });
  }

  bookmarkData(
    data: BookmarkData
  ): AxiosPromise<ApiResponse<PostResponse>> {
    return apiClient.post(`/v1/bookmarks`, data);
  }

  getAllBookmark(): AxiosPromise<ApiResponse<BookmarkResponse>> {
    return apiClient.get(`/v1/bookmarks`);
  }

  deletePost(
    data: DeletePost
  ): AxiosPromise<ApiResponse<PostResponse>> {
    return apiClient.delete(`/v1/posts`, { data: { postId: data.postId } });
  }
}

const postService = new PostService();
export default postService;
