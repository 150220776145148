// import React, { useEffect, useState } from "react";
// import NetworksAndClubsList from "./NetworksAndClubsList";

// interface CheckBoxItem {
//   id: string;
//   label: string;
// }

// const CheckBoxComponent = ({ control, register, heading, watch ,setValue}) => {
//   const [checkboxes, setCheckBoxes] = useState<CheckBoxItem[]>([]);
//   const [selectAll,setSelectAll]=useState(false)

//   useEffect(() => {
//     setCheckBoxes([
//       { id: "1", label: "Checkbox 1" },
//       { id: "2", label: "Checkbox 2" },
//       { id: "3", label: "Checkbox 3" },
//       { id: "4", label: "Checkbox 4" },
//       { id: "5", label: "Checkbox 5" },
//       { id: "6", label: "Checkbox 6" },
//     ]);
//   }, []);

//   // const selectALL = watch(`selectAll${heading}`);
//   // console.log("selectAll",selectALL)

//   const handleSelectAll=(e)=>{
//    setSelectAll(!selectAll)
//   }

//   return (
//     <div>
//       <div className="flex justify-end gap-2 p-4 ">
//         <label htmlFor="selectall" className="text-sm text-golden">
//           Select All
//         </label>
//         <input
//           name="selectall"
//           className=""
//           type="checkbox"
//           checked={selectAll}
//           onClick={handleSelectAll}
//           {...register(`selectAll${heading}`)}
//         />
//       </div>

//       <div className="max-h-64  overflow-y-scroll no-scrollbar ">
//         {checkboxes.map((ele) => {
//           return (
//             <div
//               key={ele.id}
//               className="flex justify-between p-4 bg-red border-b mx-4 border-lxgray-300"
//             >
//               <label htmlFor="singleCheckbox" className="">
//                 <NetworksAndClubsList />
//               </label>
//               <input
//                 type="checkbox"
//                 name="singleCheckbox"
//                 className="h-full"
//                 value={ele.id}
//                 checked={selectAll}
//                 onChange={(e)=>setValue(`${heading}`,e.target.checked)} 
//                 {...register(`${heading}`)}
//               />
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

// export default CheckBoxComponent;


// import React, { useEffect, useState } from "react";
// import { Controller } from "react-hook-form";
// import NetworksAndClubsList from "./NetworksAndClubsList";

// interface CheckBoxItem {
//   id: string;
//   label: string;
// }

// const CheckBoxComponent = ({ control, heading, setValue }) => {
//   const [checkboxes, setCheckBoxes] = useState<CheckBoxItem[]>([]);
//   const [selectAll, setSelectAll] = useState(false);

//   useEffect(() => {
//     setCheckBoxes([
//       { id: "1", label: "Checkbox 1" },
//       { id: "2", label: "Checkbox 2" },
//       { id: "3", label: "Checkbox 3" },
//       { id: "4", label: "Checkbox 4" },
//       { id: "5", label: "Checkbox 5" },
//       { id: "6", label: "Checkbox 6" },
//     ]);
//   }, []);

//   const handleSelectAll = (e) => {
//     const { checked } = e.target;
//     setSelectAll(checked);
//     checkboxes.forEach((option) => {
//       setValue(`${heading}-${option.id}`, checked); // Call setValue from props
//     });
//   };

//   return (
//     <div>
//       <div className="flex justify-end gap-2 p-4 ">
//         <label htmlFor="selectall" className="text-sm text-golden">
//           Select All
//         </label>
//         <input
//           name="selectall"
//           className=""
//           type="checkbox"
//           checked={selectAll}
//           onChange={handleSelectAll}
          
//         />
//       </div>

//       <div className="max-h-64 overflow-y-scroll no-scrollbar">
//         {checkboxes.map((ele) => (
//           <div
//             key={ele.id}
//             className="flex justify-between p-4 bg-red border-b mx-4 border-lxgray-300"
//           >
//             <label htmlFor={`checkbox-${ele.id}`} className="">
//               <NetworksAndClubsList />
//             </label>
//             {/* Render each checkbox within Controller */}
//             <Controller
//               name={`${heading}-${ele.id}`}
//               control={control}
//               render={({ field }) => (
//                 <input
//                   type="checkbox"
//                   className="h-full"
//                   {...field}
//                   checked={field.value || false} // Check if field value is truthy
//                   onChange={(e) => {
//                     field.onChange(e.target.checked);
//                     setValue(`${heading}-${ele.id}`, e.target.checked);
//                   }}
                  
//                 />
//               )}
//             />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default CheckBoxComponent;



import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import NetworksAndClubsList from "./NetworksAndClubsList";

// interface CheckBoxItem {
//   id: string;
//   label: string;
// }

const CheckBoxComponent = ({ control, heading, setValue ,list}) => {
  const [checkboxes, setCheckBoxes] = useState([]);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    
      setCheckBoxes(list); 
    
  }, [list]);

  useEffect(() => {
    setValue(heading, selectedValues); // Set the array in the form data
  }, [setValue, heading, selectedValues]);

  const handleSelectAll = (e) => {
    const { checked } = e.target;
    setSelectAll(checked);
    const selected = checked ? checkboxes.map((option) => `${heading==="Connections"?option.otherUserDetails.userId:option.id}`) : [];
    setSelectedValues(selected);
  };

  const handleCheckboxChange = (id: string, checked: boolean) => {
    const updatedValues = checked
      ? [...selectedValues, id]
      : selectedValues.filter((value) => value !== id);
    setSelectedValues(updatedValues);
  };

  return (
    <div>
      <div className="flex justify-end gap-2 p-4 ">
        <label htmlFor="selectall" className="text-sm text-golden">
          Select All
        </label>
        <input
          name="selectall"
          className=""
          type="checkbox"
          checked={selectAll}
          onChange={handleSelectAll}
        />
      </div>

      <div className="max-h-64 overflow-y-scroll no-scrollbar">
        {checkboxes && checkboxes.map((ele) => (
          <div
            key={`${heading==="Connections"?ele.otherUserDetails.userId:ele.id}`}
            className="flex justify-between p-4 bg-red border-b mx-4 border-lxgray-300"
          >
            <label htmlFor={`checkbox-${heading==="Connections"?ele.otherUserDetails.userId:ele.id}`} className="">
             <NetworksAndClubsList list={ele} heading={heading}/>
            </label>
            <Controller
              name={`${heading==="Connections"?ele.otherUserDetails.userId:ele.id}`}
              control={control}
              render={({ field }) => (
                <input
                  type="checkbox"
                  className="h-full"
                  {...field}
                  checked={selectedValues.includes(`${heading==="Connections"?ele.otherUserDetails.userId:ele.id}`)}
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                    handleCheckboxChange(`${heading==="Connections"?ele.otherUserDetails.userId:ele.id}`, e.target.checked);
                  }}
                />
              )}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CheckBoxComponent;


