import { ChangeEvent, useEffect, useRef, useState } from "react";
import { DivideIcon, EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { unwrapResult } from "@reduxjs/toolkit";

import LxButton from "../../../components/basic/LxButton";
import EditProfile from "./EditProfile";

import profile from "../../../assets/images/Blank_Placeholders.png";
import ProfileBanner from "../../../assets/images/default_profile_banner.png";
import EditIcon from "../../../assets/icons/editIcon.png";

import uploadContent from "../../../config/UploadContent";

import { useAppDispatch, useAppSelector } from "../../../config/hooks";

import {
  getOtherUserDetailApi,
  updateUserProfileApi,
} from "../../../slices/userSlice";
import { toggleEditProfilPopupeHandler } from "../../../slices/profileSlice";

import { baseUrl, contentUrl } from "../../../utils/contant";
import { useParams } from "react-router-dom";
import { sendNetworkRequestApi } from "../../../slices/networkSlice";
import { addChat, checkConversationApi, createConversationApi } from "../../../slices/chatSlice";
import { CheckConversationApiFulfilledAction, CreateConversationAction } from "../../../models/chat";
import VerificationPopup from "../../../components/VerificationPopup";
import AlternateProfile from "../../../components/AlternateProfile";

const ProfileCard = () => {
  const { profileId } = useParams();
  const menuParentRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const session = useAppSelector((state) => state.login.session);
  const userDetail = useAppSelector((state) => state.user.userDetails);
  const toggleState = useAppSelector((state) => state.profileData);
  const { userDetails } = useAppSelector((state) => state.user);
  const [otherUserDetails, setOtherUserDetails] = useState(null);
  const [isConnected,setIsConnected]=useState(false)
  const [requested,setRequested]=useState(false)
  const [isPopupVisible, setPopupVisible] = useState(false);

  const { userId } = session;
  const {
    firstName,
    lastName,
    designation,
    profilePicture,
    profileBackground,
    location,
    organization,
    pronoun,
    profileHeading,
    connectionCount
  } = userDetail;
  const { toggleEditProfilePopup } = toggleState;

  
  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(
        getOtherUserDetailApi({
          userId: profileId,
        })
      ).then(unwrapResult);
      setOtherUserDetails(response.data.data.userDetails);
      setIsConnected(response.data.data.isConnected)
    };
    if (profileId) fetchData();
    else setOtherUserDetails(null);
  }, [profileId]);

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    if (e.target.files && e.target.files.length > 0) {
      uploadContent.uploadProfileContent(userId, e.target.files, (response) => {
        const url = response[0].url;
        dispatch(
          updateUserProfileApi({
            userId: userId,
            [name]: url,
          })
        )
          .then(unwrapResult)
          .then((response) => {
            console.log("response", response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  };

  const toggleHandler = () => {
    dispatch(toggleEditProfilPopupeHandler(!toggleEditProfilePopup));
  };

  const handleAddConnection=()=>{
    if(userDetails?.status!=="Verified"){
      setPopupVisible(true)
      return
    }
    dispatch(sendNetworkRequestApi({fromUserId: userId,
      toUserId: otherUserDetails.id,
      message: ""}))
      setRequested(true)
  }

  const handleClick=async(otherUserId)=>{
    const response=await dispatch(checkConversationApi({members:[userId,otherUserId]})) as CheckConversationApiFulfilledAction
    console.log("RESPONSE CHECK",response)
    if(response.payload==="No Conversations Found"){
     const newResponse=await  dispatch(createConversationApi({members:[userId,otherUserId]})) as CreateConversationAction
     console.log(response,"CHECKNEWRESPONSE")
     console.log(newResponse.payload.data.id)
    //  setShowNetwork(false)
     dispatch(addChat({member:newResponse.payload.data.memberDetails.filter((ele)=>ele.userId!==userId),conversationId:newResponse.payload.data.id}))
    //  navigate(`/tab/messages/${newResponse.payload?.data.id}`)
    }
    else{
      // setShowNetwork(false)
      dispatch(addChat({member:response.payload.data.memberDetails.filter((ele)=>ele.userId!==userId),conversationId:response.payload.data.id}))
      // navigate(`/tab/messages/${response.payload?.data.id}`)
    }
  }

  if (profileId) {
    return (
      otherUserDetails && (
        <header className="w-full mt-1" id="header">
          <div
            className="h-[221px] w-full bg-cover bg-no-repeat rounded-t-xl pt-4 pr-7 "
            style={{
              backgroundImage: otherUserDetails.profileBackground
                ? `url(${baseUrl}/${contentUrl}/${otherUserDetails.profileBackground}?t=${session.token})`
                : `url(${ProfileBanner})`,
            }}
          >
            {/* <div className="flex items-center justify-center w-10 h-10 bg-white rounded-lg ml-auto mr-0 cursor-pointer overflow-hidden active:scale-95">
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }} // Hide the file input
            id="fileInput" // Associate the input with a label
            name="profileBackground"
            onChange={handleImageChange}
          />
          <label
            htmlFor="fileInput"
            className="flex items-center justify-center cursor-pointer w-full h-full"
          >
            <img src={EditIcon} alt="edit" />
          </label>
        </div> */}
          </div>
          <div className="flex min-h-[224px]  bg-lxgray-700 rounded-xl -mt-8">
            <div className="w-[96%] mx-auto flex gap-3">
              <div className="relative h-[140px] w-[140px] top-[-31px]">
                {/* <img
              className="h-full w-full rounded-full border-4 border-lxgray-700 object-cover"
              src={
                profilePicture
                  ? `${baseUrl}/${contentUrl}/${profilePicture}?t=${session.token}`
                  : profile
              }
              alt=""
            /> */}
                <div className="w-[140px] h-[140px] border-4 border-lxgray-700 rounded-full ">
                  {otherUserDetails.profilePicture ? (
                    <div
                      className={`w-full h-full bg-no-repeat bg-center bg-cover rounded-full `}
                      style={{
                        backgroundImage: `url(${baseUrl}/${contentUrl}/${otherUserDetails.profilePicture}?t=${session.token})`,
                      }}
                    ></div>
                  ) : (
                    <div
                      className={`flex items-center justify-center `}    
                    ><AlternateProfile firstName={otherUserDetails.firstName} lastName={otherUserDetails.lastName} width="32" height="32" textSize="4xl"/></div>
                  )}
                </div>
                {/* <div className="flex items-center justify-center w-10 h-10 bg-golden rounded-lg ml-auto mr-0 cursor-pointer overflow-hidden absolute bottom-0 right-0 active:scale-95">
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }} // Hide the file input
                id="fileInputProfile" // Associate the input with a label
                name="profilePicture"
                onChange={handleImageChange}
              />
              <label
                htmlFor="fileInputProfile"
                className="flex items-center justify-center cursor-pointer w-full h-full"
              >
                <img
                  src={EditIcon}
                  alt="edit"
                  style={{
                    filter:
                      "invert(100%) sepia(97%) saturate(100%) hue-rotate(336deg) brightness(115%) contrast(100%)",
                  }}
                />
              </label>
            </div> */}
              </div>
              <div className="flex flex-col justify-between flex-1 pl-2 py-3">
                <div className="relative">
                  <div className="flex gap-1 items-center">
                    <h1 className="text-white text-3xl">
                      {otherUserDetails.firstName +
                        " " +
                        otherUserDetails.lastName}
                    </h1>
                    {otherUserDetails.pronoun && (
                      <span className="text-lxgray-200 text-base self-end">
                        {`( ${otherUserDetails.pronoun} )`}
                      </span>
                    )}
                    {/* <div
                      className="cursor-pointer ml-auto mr-0"
                      ref={menuParentRef}
                      onClick={toggleHandler}
                    >
                      <EllipsisVerticalIcon className="h-6 w-6 text-lxgray-900 dark:text-lxwhite-200" />
                    </div> */}
                  </div>
                  {toggleEditProfilePopup && (
                    <EditProfile parentRef={menuParentRef} />
                  )}
                  <div className="">
                    <h4 className="text-white text-xl font-light">
                      {otherUserDetails.profileHeading
                        ? otherUserDetails.profileHeading
                        : `${otherUserDetails.designation} | ${otherUserDetails.organization}`}
                    </h4>
                    <p className="text-lxgray-200 text-sm font-normal mt-0.5">
                      {otherUserDetails.location}
                    </p>
                    {/* <p className="w-fit text-golden text-sm font-normal mt-0.5 border-b-[1px] border-golden">
                      www.myprofile.com
                    </p> */}
                  </div>
                </div>
                
                <div className="flex items-center">
                  <div className="flex flex-col items-center">
                   <h2 className="text-golden text-3xl font-semibold">{connectionCount}+</h2>
                   <h3 className="text-xs text-lxgray-300">Connections</h3>
                  </div>
                  {userId!==profileId && <div className="w-[183px] ml-auto mr-0 active:scale-95 ">
                  { isConnected?<div onClick={()=>handleClick(profileId)}><LxButton>Message</LxButton></div>:<div>{requested?<h1 className="text-white bg-lxgray-200/20 w-fit px-10 py-2 rounded cursor-not-allowed">Requested</h1>:<LxButton onClick={handleAddConnection}>Add Connection</LxButton>}</div>}
                  </div>}
                </div>
                

              </div>
            </div>
          </div>
          <VerificationPopup
        visible={isPopupVisible} 
        onClose={setPopupVisible} 
         />
        </header>
      )
    );
  }

  return (
    <header className="w-full mt-1" id="header">
      <div
        className="h-[221px] w-full bg-cover bg-no-repeat rounded-t-xl pt-4 pr-7 "
        style={{
          backgroundImage: profileBackground
            ? `url(${baseUrl}/${contentUrl}/${profileBackground}?t=${session.token})`
            : `url(${ProfileBanner})`,
        }}
      >
        <div className="flex items-center justify-center w-10 h-10 bg-white rounded-lg ml-auto mr-0 cursor-pointer overflow-hidden active:scale-95 ">
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }} // Hide the file input
            id="fileInput" // Associate the input with a label
            name="profileBackground"
            onChange={handleImageChange}
          />
          <label
            htmlFor="fileInput"
            className="flex items-center justify-center cursor-pointer w-full h-full"
          >
            <img src={EditIcon} alt="edit" />
          </label>
        </div>
      </div>
      <div className="flex h-fit  bg-lxgray-700 rounded-xl -mt-8 ">
        <div className="w-[96%] mx-auto flex gap-3  ">
          <div className="relative h-[140px] w-[140px] top-[-31px] ">
            <div className="w-[140px] h-[140px] border-4 border-lxgray-700 rounded-full ">
              {profilePicture ? (
                <div
                  className={`w-full h-full bg-no-repeat bg-center bg-cover rounded-full`}
                  style={{
                    backgroundImage: `url(${baseUrl}/${contentUrl}/${profilePicture}?t=${session.token})`,
                  }}
                ></div>
              ) : (
                <div
                      className={`flex items-center justify-center `}    
                    ><AlternateProfile firstName={firstName} lastName={lastName} width="32" height="32" textSize="4xl"/>
                </div>
              )}
            </div>
            <div className="flex items-center justify-center w-10 h-10 bg-golden rounded-lg ml-auto mr-0 cursor-pointer overflow-hidden absolute bottom-0 right-0 active:scale-95">
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }} // Hide the file input
                id="fileInputProfile" // Associate the input with a label
                name="profilePicture"
                onChange={handleImageChange}
              />
              <label
                htmlFor="fileInputProfile"
                className="flex items-center justify-center cursor-pointer w-full h-full"
              >
                <img
                  src={EditIcon}
                  alt="edit"
                  style={{
                    filter:
                      "invert(100%) sepia(97%) saturate(100%) hue-rotate(336deg) brightness(115%) contrast(100%)",
                  }}
                />
              </label>
            </div>
          </div>
          <div className="flex flex-col  flex-1 pl-2   ">
            <div className="relative ">
              <div className="flex justify-between gap-1 items-center ">
                <div className="flex">

                <h1 className="text-white text-3xl">
                  {firstName + " " + lastName}
                </h1>
                {pronoun && (
                  <span className="text-lxgray-200 text-base self-end">
                    {`( ${pronoun} )`}
                  </span>
                )}
                </div>
                
                <div className="flex gap-1">
                  <div className="w-fit text-center ">
                    <h3 className="text-base font-semibold text-golden ">{connectionCount} Connections</h3>
                 </div>

                  <div
                    className="cursor-pointer ml-auto mr-0"
                    ref={menuParentRef}
                    onClick={toggleHandler}
                    >
                  
                    <EllipsisVerticalIcon className="h-6 w-6 text-lxgray-900 dark:text-lxwhite-200" />
                  </div>
                </div>
              </div>
              {toggleEditProfilePopup && (
                <EditProfile parentRef={menuParentRef} />
              )}
              <div className="">
                <h4 className="text-white text-xl font-light">
                  {profileHeading
                    ? profileHeading
                    : `${designation} | ${organization}`}
                </h4>
                <p className="text-lxgray-200 text-sm font-normal mt-0.5">
                  {location}
                </p>
                {/* <p className="w-fit text-golden text-sm font-normal mt-0.5 border-b-[1px] border-golden">
                  www.myprofile.com
                </p> */}
              </div>
            </div>
              
              {/* 
            <div className="w-[183px] ml-auto mr-0 mt-2 active:scale-95">
              <LxButton>Message</LxButton>
            </div> */}
          </div>
        </div>
      </div>
      
    </header>
  );
};

export default ProfileCard;
