import React from "react";
import { useAppSelector } from "../../../../config/hooks";
import UserJobProfile from "./UserJobProfile";
import NoJobs from "./NoJobs";

const Shortlisted = () => {
  const { shortlistedJobApplications } = useAppSelector((state) => state.job);
  return (
    <div>
      {shortlistedJobApplications.length > 0 ?
        shortlistedJobApplications.map((ele) => {
          return (
            <div key={ele.id}>
              {ele.userId !== undefined && <UserJobProfile id={ele.userId}  jobId={ele.jobId} applicationId={ele.id} appliedJobTime={ele.createdAt} attachment={ele.attachment} percentageMatch={ele.percentageMatch}/>}
            </div>
          );
        })
        :
          <NoJobs text={"There are currently no applications on record."}/>}
    </div>
  );
};

export default Shortlisted;
