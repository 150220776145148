import React from "react";
import { Link } from "react-router-dom";
import {baseUrl,contentUrl}from  '../../../utils/contant'
import vector from "../../../assets/icons/Vector.png";
import jobLogo from "../../../assets/images/Blank_Placeholders.png";
import { useAppSelector } from "../../../config/hooks";
import { LastAppliedDay } from "../../../utils/dateUtils";
import { multipleValueDropdown } from "../../../utils/nocoFunctions";
import JobCardBox from "./JobCardBox";

const arr = [1, 2, 3];

const RecommendedJobs = () => {
  // const { path } = useRouteMatch();
  const {userId,token}=useAppSelector((state)=>state.login.session)
  const {preferredLocation}=useAppSelector((state)=>state.appConfig)
  const data = useAppSelector((state) => state.job?.recommendedJobList);

  const jobs = Object.values(data?.entities);

  return (
    <div className="">
      <div className="flex justify-between items-center y-2">
        <h2 className="text-white font-bold text-lg ">
          Recommended Jobs <span className="tracking-wider">({jobs.length})</span>
        </h2>
        <div className="text-end text-lg font-semibold text-lxgray-400 py-2  ">
        <Link to={`/tab/jobs/recommended`}>
          <button className="text-golden hover:text-golden/50">Show all</button>
        </Link>
        </div>
      </div>

      
      <div className="grid grid-cols-2 gap-1.5 ">   
      {jobs.slice(0, 8).map((ele, i) => {
        const { title, company,  jobType ,authorType,author, workplaceType} = ele;
        return (
          <Link key={ele.id} to={`/tab/jobs/description/Recommended/${ele.id}`} className="h-full">
            {/* <div key={i} className="flex  bg-lxgray-700 p-1  items-center hover:bg-lxgray-800 rounded pl-4 border border-lxgray-100">
              <div>
                <img src={`${authorType==="COMPANY"?`${baseUrl}/${contentUrl}/${author.picUrl}?t=${token}`:jobLogo}`} alt="image" className="object-cover border border-lxgray-200 rounded-lg w-24 h-24" />
              </div>
              <div className="px-6  py-2">
                <p className="text-lg text-white line-clamp-1">{title.length<28?title:`${title.slice(0,28)}...`}</p>
                <p className="text-sm text-lxgray-400">{(!company||ele.authorId===userId)?company:"Confidential"}</p>
                <p className="text-xs text-lxgray-400 mb-2 line-clamp-1">
                  {multipleValueDropdown(ele?.location,preferredLocation)} <span className="text-golden"> ({workplaceType})</span>
                </p>
                <div className="flex items-center">
                  <img src={vector} alt="vector" className="pr-1" />
                  <p className="text-xs text-lxgray-400">
                    {`Posted ${LastAppliedDay(ele.createdAt)}`}
                  </p>
                </div>
              </div>
            </div> */}
            <JobCardBox data={ele} title={title}/>

          </Link>
        );
      })}

    </div>
      
    </div>
  );
};

export default RecommendedJobs;
