import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { getAIRecommendedJobApi } from "../../../slices/jobSlice";
import { Link } from "react-router-dom";
import JobCardBox from "./JobCardBox";
import Loading from "../../../components/basic/Loading";
import { H1Icon } from "@heroicons/react/24/outline";

const RecentJobSearch = () => {
  const dispatch=useAppDispatch()
  const {userId}=useAppSelector((state)=>state.login.session)
  const {aiRecommendedJobs}=useAppSelector((state)=>state.job)
  useEffect(()=>{
    dispatch(getAIRecommendedJobApi({id:userId}))
  },[])
  return (
    <div className="">

    <div className="mt-1.5">
        {/* <h2 className="text-white font-bold text-lg">
           AI Recommended Jobs <span className="tracking-wider"></span>
        </h2> */}
       {aiRecommendedJobs? <div className="grid grid-cols-2 gap-1.5 ">
            { aiRecommendedJobs.jobs.map((ele)=>{
              return (
                <Link key={ele.id} to={`/tab/jobs/description/Recommended/${ele.id}`}>
                  <JobCardBox data={ele} title={"title"}/>
                </Link>
              )
            })     
            }
        </div>
        :
        <h1 className="animate-pulse text-lxgray-200">Loading...</h1>
        }
      </div>
    </div>
  );
};

export default RecentJobSearch;
