import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { getClubPostsApi } from "../../../slices/clubSlice";
import { getMyAllPost } from "../../../slices/userSlice";
import UserPosts from "../../profile/components/UserPosts";
import AddMultiple from "../BothCommon/AddMultiple";
import HomePopup from "../../home/components/popup/HomePopup";
import Popup from "../../home/components/popup/Popup";
import Post from "../../home/components/post/Post";
import PollForm from "../../home/components/poll/PollForm";
import SurveyForm from "../../home/components/survey/SurveyForm";
import QueryForm from "../../home/components/query/QueryForm";
import OpenImage from "../../home/components/OpenImage";
import RepostForm from "../../home/components/repost/RepostForm";
import { openFeedsModal } from "../../../slices/homeFeedSlice";

const ClubPosts = ({ clubId ,authorId}: { clubId: string ,authorId:string}) => {
  const dispatch = useAppDispatch();
  const { userPosts } = useAppSelector((state) => state.user);
  const modalState = useAppSelector((state) => state.createHomeFeedsData);
  const { userId } = useAppSelector((state) => state.login.session);
  
  const {
    isOpenModal,
    openedModalType,
    createPostModalType,
    iscreatePostModalTypeOpen,
  } = modalState;

  useEffect(() => {
    dispatch(getMyAllPost({ authorId: clubId }));
  }, [clubId]);

  const toggleFeedsModal = (type: string) => {
    dispatch(openFeedsModal(type));
  };
  return (
    <div className="min-h-28 ">
      <div className="px-5 py-4">
       { authorId==userId && <div onClick={() => toggleFeedsModal("create")}>
          <AddMultiple
            placeholder={"Share Your Experience . . ."}
            type={"createPost"}
          />
        </div>}

        {openedModalType === "create" ? (
          <HomePopup isOpen={isOpenModal} popupHeight="h-auto" authorType={"CLUB"} authorId={clubId}/>
        ) : createPostModalType === "post" ? (
          <Popup
            title="Create post"
            isOpen={iscreatePostModalTypeOpen}
            popupHeight="h-auto"
          >
            <Post authorType={"CLUB"} authorId={clubId} />
          </Popup>
        ) : createPostModalType === "poll" ? (
          <Popup
            title="Create a poll"
            isOpen={iscreatePostModalTypeOpen}
            popupHeight="h-auto"
          >
            <PollForm authorType={"CLUB"} authorId={clubId} />
          </Popup>
        ) : createPostModalType === "SurveyScreen" ? (
          <Popup
            title="Create a Survey"
            isOpen={iscreatePostModalTypeOpen}
            popupHeight="h-auto"
          >
            <SurveyForm authorType={"CLUB"} authorId={clubId} />
          </Popup>
        ) : createPostModalType === "query" ? (
          <Popup
            title="Create a Query"
            isOpen={iscreatePostModalTypeOpen}
            popupHeight="h-auto"
          >
            <QueryForm authorType={"CLUB"} authorId={clubId} />
          </Popup>
        ) : createPostModalType.includes("openImage?=") ? (
          <OpenImage
            postId={createPostModalType}
            isOpen={iscreatePostModalTypeOpen}
            title=""
          />
        ) : createPostModalType.includes("repost?=") ? (
          <Popup
            title="Repost"
            isOpen={iscreatePostModalTypeOpen}
            popupHeight="h-auto"
          >
            <RepostForm />
          </Popup>
        ) : null}
      </div>
      {userPosts.posts.length == 0 && (
        <h1 className="text-white text-center py-2 ">
          {" "}
          No Posts yet...
        </h1>
      )}
      {userPosts.posts.length > 0 && (
        <div className="px-4">
          <UserPosts feeds={userPosts} />
        </div>
      )}
    </div>
  );
};

export default ClubPosts;
