import React, { useState } from "react";
import Participants from "./Participants";
import Search from "../../../assets/icons/Search";
import { useAppSelector } from "../../../config/hooks";
import CommonPopup from "../../company/CommonPopup";
import AddParticipantsandMembers from "./AddParticipantsandMembers";

const ParticipatingComponent = ({ type }: { type: string }) => {
  const { eventMembers, eventDetail } = useAppSelector((state) => state.events);
  const { clubMembers, clubDetail } = useAppSelector((state) => state.clubs);
  const { userId } = useAppSelector((state) => state.login.session);
  const [isOpen, setIsOpen] = useState(false);

  const acceptedEventParticipants = eventMembers.filter(
    (member) => member.status === "ACCEPTED"
  );

  const acceptedClubParticipants = clubMembers.filter(
    (member) => member.status === "ACCEPTED"
  );

  return (
    <div className="min-h-lvh">
      {type == "event" && (
        <div>
          <div className="flex items-center justify-start p-6  relative">
            <input
              type="text"
              placeholder=" Search"
              className="w-10/12 bg-lxgray-700 relative border border-lxgray-400 rounded-full py-2 pl-10 text-white"
            />
            <div className="absolute z-[10] left-10 ">
              <Search />
            </div>
            <div>
              <button
                onClick={() => setIsOpen(true)}
                className="ml-4 py-2 px-0.5 bg-golden text-center rounded "
              >
                + Participants
              </button>
              {isOpen && (
                <CommonPopup setIsOpen={setIsOpen} isOpen={isOpen}>
                  <AddParticipantsandMembers
                    type="event"
                    eventId={eventDetail?.id}
                    setIsOpen={setIsOpen}
                  />
                </CommonPopup>
              )}
            </div>
          </div>
          <div className=" grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3  gap-2 px-2">
            {acceptedEventParticipants.length > 0 &&
              acceptedEventParticipants?.reverse().map((member, index) => (
                member.user.userId && 
                <Participants
                  key={index + "Event"}
                  type={member.type}
                  eventId={member.eventId}
                  memberId={member.id}
                  fullName={`${member.user.firstName} ${member.user.lastName}`}
                  picUrl={member.user.picUrl}
                  designation={member.user.designation}
                  organization={member.user.organization}
                  status={member.status}
                  authorId={member.authorId}
                  memberUserId={member.userId}
                />
              ))}
          </div>
        </div>
      )}

      {type == "club" && (
        <div>
          <div className="flex items-center justify-start p-6 relative">
            <input
              type="text"
              placeholder=" Search"
              className="w-10/12 bg-lxgray-700 relative border border-lxgray-400 rounded-full py-2 pl-10 text-white"
            />
            <div className="absolute z-[10] left-10">
              <Search />
            </div>
            <div className="">
              <button
                onClick={() => setIsOpen(true)}
                className="ml-4 py-2 px-0.5 bg-golden text-center rounded "
              >
                + Participants
              </button>
              {isOpen && (
                <CommonPopup setIsOpen={setIsOpen} isOpen={isOpen}>
                  <AddParticipantsandMembers
                    type="club"
                    clubId={clubDetail?.id}
                    setIsOpen={setIsOpen}
                  />
                </CommonPopup>
              )}
            </div>
          </div>
          <div className="  grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3  gap-2 px-2">
            {acceptedClubParticipants?.reverse().map((member, index) => (
              <Participants
                key={index + "Club"}
                type={member.type}
                clubId={member.clubId}
                memberId={member.id}
                fullName={`${member.user.firstName} ${member.user.lastName}`}
                picUrl={member.user.picUrl}
                designation={member.user.designation}
                organization={member.user.organization}
                status={member.status}
                authorId={member.authorId}
                memberUserId={member.memberUserId}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ParticipatingComponent;
