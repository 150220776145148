import { tokenRepo } from '../../cache/db'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import { logout, removeSessionData } from '../../slices/loginSlice'
import { useNavigate } from 'react-router-dom'
import AccordianDownArrow from '../../assets/icons/AccordianDownArrow'
import SignInSecurity from './components/SignInSecurity'

import { persistor } from '../../config/store'
import { getLoginUserDetailApi, getUserDetailApi } from '../../slices/userSlice'

const Setting = () => {
    const {userId}=useAppSelector((state)=>state.login.session)
    const dispatch=useAppDispatch()
    const navigate=useNavigate()
    const [active,setActive]=useState(0)
    const data=["Signin & Security","Delete Account"]


    useEffect(()=>{
        dispatch(getLoginUserDetailApi({userId}))
    },[])

    const handleLogout=()=>{
        // navigate("/")
        dispatch(logout())
        navigate("/", { replace: true })
        persistor.purge()
        tokenRepo.appToken.delete(1)

    }
  return (
    <div className='flex  bg-black min-h-screen '>
        <div className='bg-lxgray-700 flex  flex-col pl-8 gap-6  w-1/4 border text-xl font-semibold text-white border-lxgray-400 p-4' >
            {data.slice(0,1).map((ele,i)=>{
                return (
                    <h1 key={i+"set"} onClick={()=>setActive(i)} className={`hover:text-golden ${i==active?"text-golden":""}`}>{ele}</h1>
                )
            })}
            <h1 onClick={handleLogout} className='hover:text-golden hover:cursor-pointer'>Logout</h1>
        </div>
        
        {/* <div className='bg-lxgray-700 mx-[10%] mt-[1%] text-white rounded-lg p-4 flex-1 h-fit'> */}
        <div className='bg-lxgray-700  m-2 text-white rounded-lg p-8 flex-1 h-[80vh] '>
          
          
          {/* Signin & Security */}
           { active===0 && <SignInSecurity/>}

         { active===1 &&  <div>
                <h2>Delete Account</h2>
            </div>}
        </div>
      
    </div>
  )
}

export default Setting
