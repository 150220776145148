import apiClient from "../config/axiosConfig";
import { AxiosPromise } from "axios";
import {
  GetOTPRequest,
  GetOTPResponse,
  NewSignUpRequest,
  NewSignUpResponse,
  VerifiedOTPResponse,
  VerifyOTPRequest,
} from "../models/auth";
import { ApiResponse } from "../models/base";

class AuthService {
  getOTP(authReq: GetOTPRequest): AxiosPromise<ApiResponse<GetOTPResponse>> {
    return apiClient.post(`/v1/auth/otp`, authReq);
  }
  verifyOTP(
    registerReq: VerifyOTPRequest
  ): AxiosPromise<ApiResponse<VerifiedOTPResponse>> {
    return apiClient.post(`/v1/auth/otp/verify`, registerReq);
  }
  loginThroughLinkedIn(
    authCode: string
  ): AxiosPromise<ApiResponse<VerifiedOTPResponse>> {
    return apiClient.post(`/v1/auth/sso`, { code: authCode });
  }
  newSignUp(
    newregisterReq: NewSignUpRequest
  ): AxiosPromise<ApiResponse<NewSignUpResponse>> {
    return apiClient.post(`/v1/auth/register`, newregisterReq);
  }
}

const authService = new AuthService();
export default authService;
