import apiClient from "../config/axiosConfig";
import { AxiosPromise } from "axios";
import { ApiResponse } from "../models/base";
import {
  ActionOnEvent,
  AddEventMedia,
  AddEventMemberRequest,
  AddEventMemberResponse,
  CreateEventResponse,
  CreateNewEventRequest,
  DeleteEventRequest,
  Event,
  EventAttachment,
  // GetAllEventRequest,
  GetAllEventResponse,
  GetEventDetails,
  GetEventMediaResponse,
  GetFilteredEventRequest,
  GetMyALLNetworkResponse,
  GetMyEventRequest,
  GetMyNetworksRequest,
  MemberResponse,
  UpdateEventRequest,
} from "../models/event";

class EventService {
  createEvent(
    newEventReq: CreateNewEventRequest
  ): AxiosPromise<ApiResponse<CreateEventResponse>> {
    return apiClient.post(`v1/events`, newEventReq);
  }

  updateEvent(
    updateEventReq: UpdateEventRequest
  ): AxiosPromise<ApiResponse<CreateEventResponse>> {
    return apiClient.put(`v1/events/${updateEventReq.eventId}`, updateEventReq);
  }

  deleteEvent(
    delEventReq: DeleteEventRequest
  ): AxiosPromise<ApiResponse<CreateEventResponse>> {
    return apiClient.delete(`v1/events/${delEventReq.eventId}`);
  }

  // getAllEvents(
  //   newEventReq: CreateNewEventRequest
  // ): AxiosPromise<ApiResponse<CreateEventResponse>> {
  //   return apiClient.post(`v1/events`, newEventReq);
  // }

  // getAllEvents(
  //   getEvents: GetAllEventRequest
  // ): AxiosPromise<ApiResponse<GetAllEventResponse>> {
  //   return apiClient.get(`/v1/events?authorId=${getEvents.authorId}`);
  // }
  getAllEvents(): AxiosPromise<ApiResponse<GetAllEventResponse>> {
    return apiClient.get(`/v1/events?tab=trending`);
  }
  
  getAIRecommendedEvents(
    getEventDetail: GetEventDetails
  ): AxiosPromise<ApiResponse<GetAllEventResponse>>{
    return apiClient.get(`/v1/get_event_recommendations?id=${getEventDetail.eventId}`);  //You can add filter also like "/v1/jobs?filter={"expMin":5}"
  }

  getAllFilteredEvent(
    getEvents: GetFilteredEventRequest
  ): AxiosPromise<ApiResponse<GetAllEventResponse>> {
    return apiClient.post(`/v1/events/filter`, getEvents);
  }

  getMyEvent(
    getEvents: GetMyEventRequest
  ): AxiosPromise<ApiResponse<GetAllEventResponse>> {
    return apiClient.get(`/v1/events/my?tab=${getEvents.status}`);
  }

  getEventByUrl(
    nextUrl: string
  ): AxiosPromise<ApiResponse<GetAllEventResponse>> {
    return apiClient.get(nextUrl);
  }

  getEventDeatils(
    getEventDetail: GetEventDetails
  ): AxiosPromise<ApiResponse<Event>> {
    return apiClient.get(`/v1/events/${getEventDetail.eventId}`);
  }

  // getMyNetworks(
  //   getNetwork: GetMyNetworksRequest
  // ): AxiosPromise<ApiResponse<GetMyALLNetworkResponse>> {
  //   return apiClient.get(`/v1/network/user?userId=${getNetwork.userId}`);
  // }

  addEventMembers(
    members: AddEventMemberRequest
  ): AxiosPromise<ApiResponse<AddEventMemberResponse>> {
    return apiClient.post(`/v1/events/members`, members);
  }


  inviteMembers(
    members: AddEventMemberRequest
  ): AxiosPromise<ApiResponse<AddEventMemberResponse>> {
    return apiClient.post(`/v1/events/${members.eventId}/invite`,
      members.data
    );
  }

  requestToEvent(
    eventData: AddEventMemberRequest
  ): AxiosPromise<ApiResponse<AddEventMemberResponse>> {
    return apiClient.post(`/v1/events/${eventData.eventId}/request`,
      eventData.data
    );
  }

  getEventMembers(
    event: GetEventDetails
  ): AxiosPromise<ApiResponse<Array<MemberResponse>>> {
    return apiClient.get(`/v1/events/${event.eventId}/members`);
  }


  actionEventInvite(
    event: ActionOnEvent
  ): AxiosPromise<ApiResponse<AddEventMemberResponse>> {
    return apiClient.patch(`/v1/events/${event.eventId}/members/${event.action}`
    );
  }


  actionsOnMembers(
    event: ActionOnEvent
  ): AxiosPromise<ApiResponse<AddEventMemberResponse>> {
    return apiClient.patch(`/v1/events/${event.eventId}/members/${event.memberId}/${event.action}`);
  }


  removeMember(
    event: ActionOnEvent
  ): AxiosPromise<ApiResponse<AddEventMemberResponse>> {
    return apiClient.delete(`/v1/events/${event.eventId}/members/${event.memberId}`);
  }

  getEventsByAuthorId(
    getEvents: GetMyEventRequest
  ): AxiosPromise<ApiResponse<GetAllEventResponse>> {
    return apiClient.get(`/v1/events?authorId=${getEvents.authorId}`);
  }

  uploadEventMedia(
    eventMedia: AddEventMedia
  ): AxiosPromise<ApiResponse<EventAttachment>> {
    return apiClient.post(`/v1/events/${eventMedia.eventId}/media`,
      eventMedia.media
    );
  }

  getEventMedia(
    eventId: string
  ): AxiosPromise<ApiResponse<GetEventMediaResponse>> {
    return apiClient.get(`/v1/events/${eventId}/media`);
  }

  deleteEventMedia(
    eventMedia: AddEventMedia
  ): AxiosPromise<ApiResponse<AddEventMedia>> {
    return apiClient.get(`/v1/events/${eventMedia.eventId}/media`);
  }

}

const eventService = new EventService();
export default eventService;
