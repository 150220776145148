import React from 'react'

const AlternateProfile = ({firstName,lastName,height="16",width="16",textSize="xl"}) => {
  return (
    <h1 className={`bg-golden rounded-full w-${width} h-${height} flex items-center justify-center text-white text-${textSize} font-semibold`}>
        {firstName?.slice(0, 1).toUpperCase()}{lastName?.slice(0, 1).toUpperCase()}
      </h1>
  )
}

export default AlternateProfile
