export function dropDownByTitle(searchId:any,arr: Array<{ Id: any; Title: string }>){
   
    if(isNaN(searchId)){
        return  searchId
      }
    if(searchId){
        const data=arr.find((ele)=>ele.Id==(searchId))
        // console.log(data)
        return data.Title 
    }
    else{
        return;
    }
}


export function dropDownByName(searchId:any,arr: Array<{ Id: any; Name: string }>){
    if(isNaN(searchId)){
        return  searchId
      }
    if(searchId){
        const data=arr.find((ele)=>ele.Id==(searchId))
        // console.log(data)
        return data.Name
    }
    else{
        return;
    }  
}

export  function multipleValueDropdown(searchArray,arr:Array<{ Id: string; Title: string }>){
    // console.log(searchArray,"S")
    // console.log(JSON.stringify(arr))
    if(isArrayofStrings(searchArray)){
        return searchArray.join(', ')
    }
    
    if(searchArray){
        if(!Array.isArray(searchArray)){
            const newArray=searchArray.split(",")
            searchArray=newArray
        }
        const data= arr.filter((ele)=>{
            return searchArray.some((item)=>{
                if(isNaN(item)){
                  return  item==ele.Title
                }else{

                 return   item==ele.Id
                  }
              }
            )   
           })
           return data.map((ele)=>ele.Title).join(", ")
    }
    else{
        return;
    }
}

export  function multipleValueDropdownByName(searchArray,arr:Array<{ Id: number | string; Name: string }>){
   
    if(isArrayofStrings(searchArray)){
        return searchArray.join(', ')
    }
    console.log("SEACRH ARRAY,",searchArray)
    if(searchArray){
        
        if(!Array.isArray(searchArray)){
            console.log("NOT AN ARART")
            return searchArray
        }

        console.log("HELLO")
        const data = arr.filter((ele) => {
            // console.log(searchArray, ele);
            return searchArray.some((item) => {
                // Check if item is a string that can be converted to a number
                const parsedItem = parseInt(item);
                console.log("ParsedITEM",parsedItem)
                if (isNaN(parsedItem)) {
                    // If item is not a number, compare with Name
                    return item === ele.Name; // Trim to handle spaces
                } else {
                    // If item is a number, compare with Id
                    return parseInt(item) === parseInt(ele.Id);
                }
            });
        }
        );
           return data.map((ele)=>ele.Name).join(", ")
    }
    else{
        return;
    }
}

export  function multipleValueDropdownByCategories(searchArray,arr:Array<{ Id: number | string ; Categories: string }>){
    if(isArrayofStrings(searchArray)){
        return searchArray.join(', ')
    }
    
    if(searchArray){
        if(!Array.isArray(searchArray)){
            const newArray=searchArray.split(",")
            searchArray=newArray
        }
        const data= arr.filter((ele)=>{
            return searchArray.some((item)=>{
                if(isNaN(item)){
                  return  item==ele.Categories
                }else{
                 return   item==ele.Id
                  }
              }
            )   
           })
           return data.map((ele)=>ele.Categories).join(", ")
    }
    else{
        return;
    }
}

export function isArrayofStrings(searchArray) {
    return Array.isArray(searchArray) && 
           searchArray.every(item => typeof item === 'string' && isNaN(item));
}