import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import UserJobProfile from "./UserJobProfile";
import NoJobs from "./NoJobs";
import { updateApplication } from "../../../../slices/jobSlice";


const Applied = () => {
  const { appliedJobApplications } = useAppSelector((state) => state.job);
 

  return (
    <div className="">
      {appliedJobApplications.length > 0 ?
        appliedJobApplications.map((ele) => {
          return (
            <div key={ele.id}>
              {ele.userId !== undefined && <UserJobProfile id={ele.userId}  jobId={ele.jobId} applicationId={ele.id} appliedJobTime={ele.createdAt} attachment={ele.attachment} percentageMatch={ele.percentageMatch}/>}
            </div>
          );
        }):
          <NoJobs text={"There are currently no applications on record."}/>
        }
          
    </div>
  );
};

export default Applied;
