import React, { useEffect, useState } from 'react'
import { getJobDetailApi } from '../../../slices/jobSlice';
import { Link, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../config/hooks';
import Industry from '../../../assets/Job Icon/Industry';
import Job_Time from '../../../assets/Job Icon/Job_Time';
import Location from '../../../assets/Job Icon/Location';
import Experience from '../../../assets/Job Icon/Experience';
import Salary from '../../../assets/Job Icon/Salary';
import Skills from '../../../assets/Job Icon/Skills';
import { AppLink, Statictoken } from '../../../assets/images/url/url';
import { baseUrl } from '../../../utils/contant';

const StaticJobDetails = () => {
    const dispatch=useAppDispatch()
    const [jobDes, setJobDes] = useState(null);

    const {id} =useParams()

    useEffect(() => {
         fetchRecommendedJobs()
      }, [id]);

      const fetchRecommendedJobs = async () => {

        try {
          const response = await fetch(`${baseUrl}/v1/jobs/${id}`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${Statictoken}`,
              "Content-Type": "application/json",
            },
          });
          const data = await response.json();
          console.log("Response.json",data.data[0])
          setJobDes(data.data[0]);
        } catch (error) {
          console.error("Error fetching jobs:", error);
        }
      };

  return (
    <>
      {jobDes && <div className='min-h-screen bg-lxgray-600 pt-[20%] sm:pt-[15%] md:pt-[10%] lg:pt-[7%] px-5 md:px-10 lg:px-52 xl:px-64'>
        
        <div className='flex flex-col justify-center py-2 '>
          <h1 className='text-white font-semibold  text-lg md:text-xl'>{jobDes.title}</h1>
          <div className='flex justify-between items-end'>
              <h4 className='text-golden font-semibold text-base'>Confidential</h4>
              <Link to={`/tab/jobs/description/Recommended/${id}?redirected=true`}>
                  <button className='bg-golden px-8 py-1 md:px-10 md:py-2 rounded-lg text-white hover:text-golden hover:bg-white'>Apply</button>
              </Link>
          </div>
        </div>

        <div className='border-t border-lxgray-300 '>

          <div className='text-white py-2 text-base lg:text-lg'>
              <div className="flex p-1"><Industry/> <span className="ml-4">{jobDes.industries.join(" ,")}</span></div>
              <div className="flex p-1"><Job_Time/><span className="ml-4">{jobDes.jobType}</span></div>
              <div className="flex p-1"><Location/><span className="ml-4">{jobDes.location.join(" ,")}</span></div>
              <div className="flex p-1"><Experience/><span className="ml-4">{jobDes?.experience?.min} Yrs - {jobDes?.experience?.max===0?"Not disclosed":`${jobDes?.experience?.max} Yrs`} </span></div>
              <div className="flex p-1"><Salary/><span className="ml-4">{`${jobDes?.salary?.min } ${jobDes?.salary?.min.toString().length==1?"Cr":"Lakh"} - ${jobDes?.salary?.max==0?"Not disclosed":`${ jobDes?.salary?.max} ${jobDes?.salary?.max.toString().length==1?"Cr":"Lakh"}`}`}</span></div>
              <div className="flex p-1"><Skills/><span className="ml-4">{jobDes.skills.join(" ,")}</span></div>
          </div>
        </div>

        <div className=' border-t border-lxgray-300 py-3'>
              <h1 className='text-white text-lg md:text-xl pb-2'>Job Details</h1>
              <p className='text-sm md:text-base text-lxgray-100 break-words whitespace-pre-line'>{jobDes.description}</p>
        </div>
      </div>}
    </>
  )
}

export default StaticJobDetails
