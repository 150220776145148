import React, { useState } from 'react'
import OTPInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../config/hooks';
import { verifyApi } from '../../../slices/userSlice';
import { toast } from 'react-toastify';

const OtpEmailPhone = ({reqId,type,setIsOptScreenOpen,onClose}) => {
    const navigate=useNavigate()
    const dispatch=useAppDispatch()
    const [code, setCode] = useState(""); // State to hold OTP input
    const [err,setErr]=useState("")

    const handleResend = () => {
        // Implement resend OTP functionality here
        // Example: Trigger API call to resend OTP
        console.log("Resend OTP functionality goes here");
    };
    

    const handleContinue = async() => {
        // Implement continue functionality here
        // Example: Validate OTP and proceed
        if (code.length<6) {
            setErr("Please enter a valid OTP before continuing.");
            setTimeout(()=>{
                setErr("")
            },2000)
            return;
        }
        // console.log("Continue functionality goes here with OTP:", code);

        try{
            const res =await dispatch(verifyApi({requestId:reqId,otp:Number(code),type:type}))
            
            // console.log(res)
            // console.log(res.meta.requestStatus)
            if(res.payload.status==200){
                toast.success(`${type} updated successfully`)
                setIsOptScreenOpen(false)
                onClose()
            }
            else{
                setErr("Invalid OTP")
            }
       
        }
        catch(err){
            console.log(err)
        }
        
      
        // setIsOpen(!isOpen) 
        // Implement continue functionality here
        // Example: Validate OTP and proceed
    };
    
   
      
  return (
    <div className='flex flex-col items-center gap-2 px-8 py-8'>
            <h1 className='text-xl text-white font-semibold'>Enter Your OTP</h1>
            <OTPInput 
                onChange={setCode}
                numInputs={6}
                renderSeparator={<span style={{ width: "10px" }}></span>}               
                inputType='tel'
                
                inputStyle={{
                    border: "1px solid transparent",
                    borderRadius: "100%",
                    width: "54px",
                    height: "54px",
                    fontSize: "14px",
                    color: "#000",
                    fontWeight: "500",
                    caretColor: "blue",
                    WebkitAppearance:"none",
                    MozAppearance: "textfield",
                    appearance: "none",                  
                }}
                renderInput={(props) => <input {...props} />}
                value={code}
            />
            <p className='text-red-700'>{err}</p>
            <div className='flex gap-2 text-sm'>
                <p className='text lxgray-200 '>Didn't recieved the OTP ?</p>
                 <button className='text-golden font-semibold hover:text-white' onClick={handleResend}>Resend</button>
            </div>
            <button className='bg-golden text-white px-6 py-2 w-full font-semibold rounded-lg' onClick={handleContinue}>Continue</button>
        </div>
  )
}

export default OtpEmailPhone
