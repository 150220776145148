import React, { useEffect, useState } from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";
import CrossIcon from "../assets/icons/CrossIcon";
import useSeoMetaTags from "../utils/hooks/useSeoMetaTags";

const MultipleShareButtons = ({ setShowShareButton, shareUrl }) => {
  // SEO Meta Tags
//   const seoMetaTags = useSeoMetaTags({
//     title: "My Awesome Page",
//     description: "This is a description of my awesome page.",
//     image: "https://cdn-imgix.headout.com/media/images/b6c2fb6c7768967b6daafb1301acd2a1-15513.jpg?auto=format&w=900&h=562.5&q=90&fit=crop&ar=16%3A10",
//     url: "https://linkcxo.com",
//   });

  const [screenSize, setScreenSize] = useState({
    width: 0,
    height: 0,
  });

  const title = "Check out this awesome website!";
  const share_button = `hover:scale-110`;

  // Handle screen dimensions safely
  useEffect(() => {
    setScreenSize({
      width: Math.floor(window.screen.width),
      height: Math.floor(window.screen.height),
    });
  }, []);

  return (
    <div>
      {/* Render SEO Meta Tags */}
      {/* {seoMetaTags} */}

      <div className="flex justify-between mb-4">
        <h1 className="font-semibold text-lxgray-100">Share</h1>
        <div
          onClick={() => setShowShareButton(false)}
          className="hover:scale-95 cursor-pointer"
        >
          <CrossIcon />
        </div>
      </div>

      <div className="flex gap-2">
        <WhatsappShareButton
          className={share_button}
          url={shareUrl}
          windowWidth={screenSize.width}
          windowHeight={screenSize.height}
    
        >
          <WhatsappIcon size={40} round />
        </WhatsappShareButton>

        <FacebookShareButton
          className={share_button}
          url={shareUrl}
          windowWidth={screenSize.width}
          windowHeight={screenSize.height}
        >
          <FacebookIcon size={40} round />
        </FacebookShareButton>

        <TwitterShareButton
          className={share_button}
          url={shareUrl}
          windowWidth={screenSize.width}
          windowHeight={screenSize.height}
        >
          <TwitterIcon size={40} round />
        </TwitterShareButton>

        <EmailShareButton
          className={share_button}
          url={shareUrl}
          windowWidth={screenSize.width}
          windowHeight={screenSize.height}
        >
          <EmailIcon size={40} round />
        </EmailShareButton>
      </div>
    </div>
  );
};

export default MultipleShareButtons;
