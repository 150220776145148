const useComment = () => {
  const addNewComments = (tree, commentId, newComment) => {
    if (tree.id === commentId) {
      tree.replies = newComment;
      return tree;
    }

    const updatedTree = tree?.replies?.map((ele) =>
      addNewComments(ele, commentId, newComment)
    );

    return { ...tree, replies: updatedTree };
  };

  const updateCommentContent = (comments, targetId, newContent) => {
    for (let comment of comments) {
      if (comment.id === targetId) {
        comment.content = newContent;
        return; // Exit once updated
      }

      // If the comment has replies, recursively search in replies
      if (comment.replies && comment.replies.length > 0) {
        updateCommentContent(comment.replies, targetId, newContent);
      }
    }
  };

  const postNewComments = (tree, commentId, newComment) => {
    if (tree.id == commentId) {
      tree.replies.unshift(newComment);
      tree.counts.reply = tree.counts.reply + 1;
      return tree;
    }

    const updatedTree = tree?.replies?.map((ele) => {
      return postNewComments(ele, commentId, newComment);
    });

    return { ...tree, replies: updatedTree };
  };

  const deleteComment = (tree, commentId) => {
    // If the current node matches the commentId, return null to remove it
    if (tree.id === commentId) {
      return null;
    }

    // Filter and process replies, removing the matching comment subtree
    const updatedReplies = tree.replies
      ?.map((reply) => deleteComment(reply, commentId)) // Recursively check nested replies
      .filter((reply) => reply !== null); // Remove any null results (deleted trees)

    return { ...tree, replies: updatedReplies };
  };

  const addDepthRecursively = (comments, depth = 0) => {
    comments.forEach((comment) => {
      comment.depth = depth;
      if (comment.replies.length > 0) {
        addDepthRecursively(comment.replies, depth + 1);
      }
    });
  };

  return {
    addNewComments,
    postNewComments,
    addDepthRecursively,
    updateCommentContent,
    deleteComment,
  };
};

export default useComment;
