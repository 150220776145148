import LxOutlinedButton from "../../../components/basic/LxOutlinedButton";
import Download from "../../../assets/icons/Download";
import { useEffect, useState } from "react";
import AboutMe from "./AboutMe";
import UserPosts from "./UserPosts";
import { getMyAllPost, getOtherUsersAllPost } from "../../../slices/userSlice";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";

const ProfileInfo = () => {
  const [openTab, setOpenTab] = useState<number>(1);
  const dispatch = useAppDispatch();

  const { profileId } = useParams();

  const session = useAppSelector((state) => state.login.session);
  const user = useAppSelector((state) => state.user);

  const { userId } = session;
  const { userDetails, userPosts } = user;
  const [otherFeeds, setOtherFeeds] = useState(null);
  console.log("profileId :>> ", profileId);
  useEffect(() => {
    if (!profileId) {
      dispatch(
        getMyAllPost({
          authorId: userId,
        })
      );
    }
  }, [dispatch, userDetails, userId, profileId]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(
        getOtherUsersAllPost({
          authorId: profileId,
        })
      ).then(unwrapResult);
      console.log("response :>> ", response.data.data);
      setOtherFeeds(response.data.data);
    };
    if (profileId) {
      fetchData();
    }
  }, [dispatch, profileId]);

  const onChangeTabHandler = (tabId: number) => {
    setOpenTab(tabId);
  };

  return (
    <div className="w-[96%] mx-auto mt-2">
      <header className="w-full">
        <div className="flex  items-center justify-between">
          <h1 className="text-2xl text-white font-bold">My Profile</h1>
          {/* <div>
            <LxOutlinedButton
              btnIcon={<Download />}
              btnTitle="Download"
              customeStyles="w-[160px] border-golden text-golden"
            />
          </div> */}
        </div>

        <div className="w-full mt-6">
          <ul className="w-full flex items-center justify-around text-lxgray-200 text-xl font-light">
            <li className="flex-1" onClick={() => onChangeTabHandler(1)}>
              <h4
                className={`w-full px-4 pb-2 cursor-pointer mx-auto transition duration-300 ease-in-out text-center ${
                  openTab === 1
                    ? "font-bold text-golden border-b-2 border-golden"
                    : "border-b-2 border-lxgray-400/40"
                }`}
              >
                About me
              </h4>
            </li>
            <li className="flex-1" onClick={() => onChangeTabHandler(2)}>
              <h4
                className={`w-full px-4 pb-2  cursor-pointer mx-auto transition duration-300 ease-in-out text-center ${
                  openTab === 2
                    ? "font-bold text-golden border-b-2 border-golden"
                    : "border-b-2 border-lxgray-400/40"
                }`}
              >
                Post
              </h4>
            </li>
            {/* <li className="flex-1" onClick={() => onChangeTabHandler(3)}>
              <h4
                className={`w-full text-center pb-2 px-4 w-fit cursor-pointer mx-auto transition duration-300 ease-in-out ${
                  openTab === 3
                    ? "font-bold text-golden border-b-2 border-golden"
                    : "border-b-2 border-lxgray-400/40"
                }`}>
                Insights
              </h4>
            </li>
            <li className="flex-1" onClick={() => onChangeTabHandler(4)}>
              <h4
                className={`w-full text-center pb-2 px-4 w-fit cursor-pointer mx-auto transition duration-300 ease-in-out ${
                  openTab === 4
                    ? "font-bold text-golden border-b-2 border-golden"
                    : "border-b-2 border-lxgray-400/40"
                }`}>
                Clubs
              </h4>
            </li> */}
          </ul>
        </div>
      </header>

      <div className="w-full mt-2 transition-opacity delay-500 ease-in-out">
        <div
          className={`w-full ${
            openTab === 1 ? "block opacity-100" : "hidden opacity-0"
          }`}
        >
          {openTab === 1 && <AboutMe />}
        </div>
        <div
          className={`${
            openTab === 2 ? "block opacity-100" : "hidden opacity-0"
          }`}
        >
          {openTab === 2 && profileId ? (
            otherFeeds && <UserPosts feeds={otherFeeds} />
          ) : (
            <UserPosts feeds={userPosts} />
          )}
        </div>
        <div
          className={`text-lxgray-200  ${
            openTab === 3 ? "block opacity-100" : "hidden opacity-0"
          }`}
        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore quas
          officia hic, aliquam culpa at illo, illum deserunt corporis numquam
          molestiae quaerat corrupti sunt vero doloremque aperiam fugit, minima
          laborum reiciendis? Ad rerum laudantium, placeat dolore eius
          reprehenderit? Sunt labore, voluptate iure vel suscipit quisquam
          expedita delectus repellendus amet explicabo ipsa mollitia assumenda
          non modi consectetur <br />
          deserunt tempore exercitationem in animi a dicta pariatur commodi! Est
          libero maxime quae sint rerum excepturi perspiciatis repudiandae
          expedita ea adipisci sit unde, dolore vero amet in, aperiam iusto
          magnam cum non aspernatur autem pariatur delectus? Incidunt iste
          delectus, ipsum culpa, optio, quam pariatur quisquam unde praesentium
          alias eaque deleniti? Aut modi inventore perferendis blanditiis saepe
          repudiandae animi fugiat ducimus temporibus nostrum. Expedita non
          laudantium veniam asperiores! Optio reprehenderit saepe rerum. Maxime
          dolor sunt est rem quod, dicta culpa. Illo ratione dicta obcaecati
          minima incidunt ipsa rem tempore fugiat dolorum, molestiae in?
          Assumenda, <br />
          molestias. Magni porro saepe animi deleniti, illo voluptas dolorum
          quia earum dolores vitae, ullam excepturi odit sed minus. Non
          necessitatibus cupiditate, saepe sapiente beatae minus ut neque
          ratione. Fugit omnis doloremque veniam! Cupiditate dolor blanditiis,
          ducimus pariatur reiciendis debitis placeat cum fugit esse nisi eaque
          maxime commodi, odit, unde itaque? Quisquam officiis libero placeat
          facilis. Quibusdam illum dignissimos magni, doloribus voluptatibus
          iusto. Qui magni maiores cumque, ab temporibus amet sunt eligendi fuga
          odit vel aperiam assumenda alias iure voluptate nulla dolorem earum
          aliquid dignissimos rem. Illo possimus laborum culpa assumenda beatae
          nulla earum enim voluptatum obcaecati, qui, perspiciatis eum animi
          corrupti ab ex corporis voluptas accusantium vitae! Cumque et
          laboriosam <br />
          molestiae labore fugiat illo eos fugit, sint provident obcaecati non
          voluptate recusandae ea quaerat culpa quisquam minima magni vel minus
          incidunt, eaque nulla corporis. Expedita eius atque ipsum inventore
          rerum! Minus cumque non distinctio, soluta dolor pariatur, dolore{" "}
          <br />
          unde doloribus accusantium quos explicabo maxime mollitia obcaecati a
          molestias omnis laboriosam rerum aperiam ipsa quibusdam temporibus et.
          Ratione, modi harum sequi maiores facere quae laudantium expedita rem
          commodi temporibus, nihil odio adipisci quisquam soluta quibusdam
          architecto quod tempora sint? Quod placeat accusantium, earum
          veritatis laudantium, voluptatum harum amet assumenda reprehenderit
          sit minima reiciendis quis libero praesentium ea incidunt sapiente
          molestiae excepturi exercitationem, quaerat dolor dignissimos
          molestias a fugit. Corporis similique voluptate blanditiis libero
          dignissimos. Molestias beatae excepturi in illo a dolorem quas fugiat
          sint architecto vel. Soluta neque, dolor voluptas veniam veritatis
          modi unde laboriosam? Voluptatem repellendus consectetur ipsum sint
          aliquam error architecto nam alias itaque. Nam dolorem distinctio
          esse, ratione iusto voluptates in ipsa dolorum ducimus laboriosam,
          consequatur facilis numquam. Culpa, corporis aut atque iste repellat
          praesentium itaque quam id ipsa, quo doloribus earum error. Fugiat
          modi quas odit amet iste laboriosam sunt, totam tenetur explicabo iure
          nisi nihil facilis dolorem aliquam non impedit velit dignissimos quae
          sapiente voluptas accusantium perspiciatis cupiditate. Dolores atque
          iure magni quaerat! Cupiditate, ipsa. Ab impedit omnis voluptatibus
          provident voluptatum ut consequatur dolores necessitatibus unde
          similique autem nam odit nihil soluta ratione doloribus rem doloremque
          quae, velit fugit excepturi qui. Architecto doloremque temporibus aut
          beatae rerum.
        </div>
        <div
          className={`text-lxgray-200  ${
            openTab === 4 ? "block opacity-100" : "hidden opacity-0"
          }`}
        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore quas
          officia hic, aliquam culpa at illo, illum deserunt corporis numquam
          molestiae quaerat corrupti sunt vero doloremque aperiam fugit, minima
          laborum reiciendis? Ad rerum laudantium, placeat dolore eius
          reprehenderit? Sunt labore, voluptate iure vel suscipit quisquam
          expedita delectus repellendus <br />
          amet explicabo ipsa mollitia assumenda non modi consectetur deserunt
          tempore exercitationem in animi a dicta pariatur commodi! Est libero
          maxime quae sint rerum excepturi perspiciatis repudiandae expedita ea
          adipisci sit unde, dolore vero amet in, aperiam iusto magnam cum non
          aspernatur autem pariatur delectus? Incidunt iste delectus, ipsum cu
          Non necessitatibus cupiditate, saepe sapiente beatae minus ut neque
          ratione. Fugit omnis doloremque veniam! Cupiditate dolor blanditiis,
          ducimus pariatur reiciendis debitis placeat cum fugit esse nisi eaque
          maxime commodi, odit, unde itaque? Quisquam officiis libero placeat
          facilis. Quibusdam illum dignissimos magni, doloribus voluptatibus
          iusto. Qui magni maiores cumque, ab temporibus amet sunt eligendi fuga
          odit vel aperiam assumenda alias iure voluptate nulla dolorem earum
          aliquid dignissimos rem. Illo possimus laborum culpa assumenda beatae
          nulla earum enim voluptatum obcaecati, qui, perspiciatis eum animi
          corrupti ab ex corporis voluptas accusantium vitae! Cumque et
          laboriosam molestiae labore fugiat illo eos fugit, sint provident
          obcaecati non voluptate recusandae ea quaerat culpa quisquam minima
          magni vel minus incidunt, eaque nulla corporis. Expedita eius atque
          ipsum inventore rerum! Minus cumque non distinctio, soluta dolor
          pariatur, dolore unde doloribus accusantium quos explicabo maxime
          mollitia obcaecati a molestias omnis laboriosam rerum aperiam ipsa
          quibusdam temporibus et. Ratione, modi harum sequi maiores facere quae
          laudantium expedita rem commodi temporibus, nihil odio adipisci
          quisquam soluta quibusdam architecto quod tempora sint? Quod placeat
          accusantium, earum veritatis laudantium, voluptatum harum amet
          assumenda reprehenderit sit minima reiciendis quis libero praesentium
          ea incidunt sapiente molestiae excepturi exercitationem, quaerat dolor
          dignissimos molestias a fugit. Corporis similique voluptate blanditiis
          libero dignissimos. Molestias beatae excepturi in illo a dolorem quas
          fugiat sint architecto vel. Soluta neque, dolor voluptas veniam
          veritatis modi unde laboriosam? Voluptatem repellendus consectetur
          ipsum sint aliquam error architecto nam alias itaque. Nam dolorem
          distinctio esse, ratione iusto voluptates in ipsa dolorum ducimus
          laboriosam, consequatur facilis numquam. Culpa, corporis aut atque
          iste repellat praesentium itaque quam id ipsa, quo doloribus earum
          error. Fugiat modi quas odit amet iste laboriosam sunt, totam tenetur
          explicabo iure nisi nihil facilis dolorem aliquam non impedit velit
          dignissimos quae sapiente voluptas accusantium perspiciatis
          cupiditate. Dolores atque iure magni quaerat! Cupiditate, ipsa. Ab
          impedit omnis voluptatibus provident voluptatum ut consequatur dolores
          necessitatibus unde similique autem nam odit nihil soluta ratione
          doloribus rem doloremque quae, velit fugit excepturi qui. Architecto
          doloremque temporibus aut beatae rerum.
        </div>
      </div>
    </div>
  );
};

export default ProfileInfo;
