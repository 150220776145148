import React, { useEffect } from 'react'
import { getJobDetailApi } from '../slices/jobSlice';
import { useAppDispatch, useAppSelector } from '../config/hooks';
import JobCardBox from '../pages/job/components/JobCardBox';
import { Link } from 'react-router-dom';

const JobFeedCard = ({ userContent }) => {
    const dispatch=useAppDispatch()
    const data=useAppSelector((state)=>state.job)
    const {jobDes}=data
    const {metadata}=userContent
    const {postId}=metadata

    useEffect(() => {
        dispatch(getJobDetailApi({ jobId:postId }));    
      }, [postId]);

  return (
    <div className='w-[80%] p-2'>
        { jobDes &&  (<Link to={`/tab/jobs/description/Recommended/${postId}`}>
            <JobCardBox data={jobDes} title=' '/> 
        </Link>)}
    </div>
  )
}

export default JobFeedCard
