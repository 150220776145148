import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getApplicantsApi, getJobDetailApi } from "../../../slices/jobSlice";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import JobCardBox from "./JobCardBox";

import JobDetail from "./JobDetail";
import { Job } from "../../../models/job";
import { useEventLogger } from "../../../utils/hooks/useEventLogger";


const JobDescriptionCard = () => {
  const dispatch = useAppDispatch();
  const { jobDes,createdJobList, savedJobList, appliedJobList, recommendedJobList,closedJobList,inActiveJobList } =
    useAppSelector((state) => state?.job);
  const { jobId, title } = useParams();
  const {logUserEvent,isLogging}=useEventLogger();
 


  useEffect(() => {
    dispatch(getJobDetailApi({ jobId }));
    logUserEvent({id:jobId,event:"viewJobDetails",module:"Jobs",subModule:"viewJobDetails"})
    
    // dispatch(getApplicantsApi({jobId}))
  }, [jobId]);

 console.log(title)

  const getListByTitle = (title) => {
    switch (title) {
      case "Recommended":
        return Object.values(recommendedJobList?.entities);
      case "Open":
        return Object.values(createdJobList?.entities);
      case "Company":
      return Object.values(createdJobList?.entities);
      case "Closed":
        return Object.values(closedJobList?.entities);
      case "Saved":
        return Object.values(savedJobList?.entities);
      case "Applied":
        return Object.values(appliedJobList?.entities).map((ele)=>ele?.job);
      case "InActive":
        return Object.values(inActiveJobList?.entities);
      default:
        return [];
    }
  };

  return (
    <>
      <div>
        <h1 className=" px-16 py-2 text-lxgray-400">{title} Jobs</h1>
      </div>
      <div className="flex px-12 py-1">
        
         
         { jobDes && <div className=" hidden sm:flex sm:flex-col sm:gap-1.5 max-h-[80vh]  overflow-y-scroll no-scrollbar w-1/3 2xl:w-1/4 pr-1 ">
            {getListByTitle(title).map((ele, i) => {
              return (
                <Link
                  key={ele.id}
                  to={`/tab/jobs/description/${title}/${ele.id}`}
                >
                  <div
                    className={
                      ele.id === jobDes.id ? "border-l-4 border-golden " : ""
                    }
                  >
                    <JobCardBox key={jobDes.id} data={ele} title={title}/>
                  </div>
                </Link>
              );
            })}
          </div>}
        

       {jobDes && <div className="max-h-[80vh] sm:w-2/3  overflow-y-scroll no-scrollbar"><JobDetail jobDes={jobDes} title={title}/> </div>}
      </div>
    </>
  );
};

export default JobDescriptionCard;
