import React, { useEffect } from 'react'
import CompanyCard from './CompanyCard'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import { getCompaniesApi, getNextCompanyApi } from '../../slices/companySlice'
import NoCompany from "../../../src/assets/images/NoCompanies.png"
import InfiniteScroll from 'react-infinite-scroll-component'

const MyCompanies = () => {
  const dispatch=useAppDispatch()
  const {companies,companyMetadata}=useAppSelector((state)=>state.company)
  useEffect(()=>{
     dispatch(getCompaniesApi({}))
  },[])

  const getMoreDataHandler=()=>{
    dispatch(getNextCompanyApi({}))
  }
  return (
    <>
     {
        companies.length==0 && 
        <div className='flex items-center justify-center '>
          <img src={NoCompany} alt="NO Companies Available" />
        </div>
      }
    
    <InfiniteScroll
                  className="space-y-2 pb-4 no-scrollbar"
                  dataLength={companies?.length-2}
                  next={() => getMoreDataHandler()}
                  hasMore={companyMetadata?.nextResultURL?true:false }
                  // height={"calc(100vh - 155px)"}
                  loader={""}
                >
    <div className='flex flex-wrap gap-3'>
     
      {
        companies?.map((ele)=>{
          return (
            <Link key={ele.id} to={ `/tab/company/${ele.id}`}><CompanyCard bannerImage={ele.bannerImage} profileImage={ele.profileImage} location={ele.location} title={ele.title} tagline={ele.tagline}/></Link>
          )
        })
      }
      
    </div>
    </InfiniteScroll>
      </>
  )
}

export default MyCompanies
