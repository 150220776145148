import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../config/hooks';
import { searchApi } from '../../slices/globalSearchSlice';
import background from "./../../assets/images/Blank_Placeholders.png"

import GlobalPosts from './GlobalPosts';
import OtherLists from './OtherLists';
import JobCardBox from '../job/components/JobCardBox';

const tabs=["All","People","Posts","Jobs","Events","Clubs","Companies"]

const GlobalSearch = () => {
  const {userId}=useAppSelector((state)=>state.login.session)
  const {postSearchList,userList,jobSearchList,eventSearchList,clubSearchList,companySearchList}= useAppSelector((state)=>state.globalSearch)
  const [activeTab, setActiveTab] = useState(tabs[0]);

  // const [showUser,setShowUser]=useState(3)
   const dispatch=useAppDispatch()
    const location = useLocation();

    // Function to parse query parameters from URL
  const getQueryParams = () => {
    const query = new URLSearchParams(location.search);
    return query.get('query') || ''; // Return the search query parameter
  };

  const searchQuery = getQueryParams();

  useEffect(()=>{
    let newTab="all";
    if(activeTab=="People"){
      newTab="user"
    }
    else if(activeTab=="Posts"){
      newTab="post"
    }
    else if(activeTab=="Jobs"){
      newTab="job"
    }
    else if(activeTab=="Events"){
      newTab="event"
    }
    else if(activeTab=="Clubs"){
      newTab="club"
    }
    else if(activeTab=="Companies"){
      newTab="company"
    }
    else{
      newTab="all"
    }
    
    dispatch(searchApi({text:searchQuery,tab:newTab}))
  },[searchQuery,activeTab])



  return (
    <div className='bg-black min-h-screen pt-1 pb-4'>
        <div className=' flex items-center justify-start gap-[5%] pl-[5%] mx-2 bg-lxgray-700  text-lxgray-200 py-3 text-base'>
           {
            tabs.map((ele,i)=>{
                return (
                  <h1
                  key={i+"glb"}
                  onClick={() => setActiveTab(ele)}
                  className={`cursor-pointer ${activeTab === ele ? 'text-golden' : 'text-lxgray-200 hover:text-white'}`}
              >
                  {ele}
              </h1>
                )
            })
           }
        </div>
        
        <div className=' '>
          {/* People Results */}
            { (userList!==null && userList?.length>0) &&
              <div className='text-white  bg-lxgray-700 mt-3 w-2/3 2xl:w-2/4  mx-auto p-3'>
                 <h1 className='text-lg font-medium py-2'>People Results</h1>
                 {
                userList.map((ele)=>{
                    return (
                      <div key={ele.id} className='px-4 py-2 border-b border-lxgray-400 hover:bg-lxgray-400/30'>
                        <OtherLists title={`${ele.firstName} ${ele.lastName}`} imgUrl={ele.profilePicture} description={ele.designation} description2={ele.organization} buttonText={"Connect"} urlLink={`/tab/profile/${ele.id}`} isConnected={ele.isConnected}/>
                      </div>
                    )
                  })
                 }

                { activeTab!=="People" && <div onClick={()=>{setActiveTab("People")}} className='text-end text-lxgray-100 pt-2 font-semibold'>
                    <h1 className='hover:text-golden cursor-pointer'>Show more</h1>
                 </div>}
                 
              </div>
            }

            {/* Post Results */}
            { (postSearchList!==null && postSearchList?.length>0) &&
              <div className='text-white bg-lxgray-700 mt-3 w-2/3 2xl:w-2/4 mx-auto p-3 '>
                 <h1 className='text-lg font-medium py-2'>Post Results</h1>
                <GlobalPosts feeds={postSearchList.slice(0,3)}/> 
                { activeTab!=="Posts" && <div onClick={()=>{setActiveTab("Posts")}} className='text-end text-lxgray-100 p-2 font-semibold'>
                    <h1 className='hover:text-golden cursor-pointer'>Show more</h1>
                 </div>}
              </div>
            }

            {/* Job Results */}
            { (jobSearchList!==null && jobSearchList?.length>0) && 
              <div className='text-white bg-lxgray-700 mt-3 w-2/3 2xl:w-2/4 mx-auto p-3 '>
                 <h1 className='text-lg font-medium py-2'>Job Results</h1>
                 {
                jobSearchList.slice(0,3).map((ele)=>{
                    return (
                      <div key={ele.id} className='px-4 py-2 border-b border-lxgray-400 hover:bg-lxgray-400/30'>
                        {/* <JobCardBox data={ele} title=''/> */}
                        <OtherLists title={`${ele.title}`} imgUrl={"Job"} description={ele.company} description2={ele?.location} buttonText={"Apply"} urlLink={`/tab/jobs/description/${ele.authorId===userId?"Open":"Recommended"}/${ele.id}`} authorId={ele.authorId}/>
                      </div>
                    )
                  })
                 }
                  { activeTab!=="Jobs" && <div onClick={()=>{setActiveTab("Jobs")}} className='text-end text-lxgray-100 p-2 font-semibold'>
                    <h1 className='hover:text-golden cursor-pointer'>Show more</h1>
                 </div>}
              </div>
            }

            {/* Event Results */}
            {
              (eventSearchList!==null && eventSearchList?.length>0) && 
              <div className='text-white bg-lxgray-700 mt-3 w-2/3  2xl:w-2/4 mx-auto p-3 '>
                 <h1 className='text-lg font-medium py-2'>Events Results</h1>
                 {
                eventSearchList.slice(0,3).map((ele)=>{
                    return (
                      <div key={ele.id} className='px-4 py-2 border-b border-lxgray-400 hover:bg-lxgray-400/30'>
                        <OtherLists title={`${ele.title}`} imgUrl={ele.bannerImageUrl} description={ele.details} description2={ele?.isOffline.toString()=="true"?"Offline":"Online"} buttonText={"Attend"} urlLink={`/tab/events/${ele.id}`}/>
                      </div>
                    )
                  })
                 }
                  { activeTab!=="Events" && <div onClick={()=>{setActiveTab("Events")}} className='text-end text-lxgray-100 p-2 font-semibold'>
                    <h1 className='hover:text-golden cursor-pointer'>Show more</h1>
                 </div>}
              </div>
            }

            {/* ClubResults */}
            {
              (clubSearchList!==null && clubSearchList?.length>0) && 
              <div className='text-white bg-lxgray-700 mt-3 w-2/3 2xl:w-2/4 mx-auto p-3 '>
                 <h1 className='text-lg font-medium py-2'>Clubs Results</h1>
                 {
                clubSearchList.slice(0,3).map((ele)=>{
                    return (
                      <div key={ele.id} className='px-4 py-2 border-b border-lxgray-400 hover:bg-lxgray-400/30'>
                        <OtherLists title={`${ele.title}`} imgUrl={ele.bannerImage} description={`${ele.noOfApplicants.toString()} members`}  buttonText={"Join"} urlLink={`/tab/clubs/${ele.id}`}/>
                      </div>
                    )
                  })
                 }
                 { activeTab!=="Clubs" && <div onClick={()=>{setActiveTab("Clubs")}} className='text-end text-lxgray-100 p-2 font-semibold'>
                    <h1 className='hover:text-golden cursor-pointer'>Show more</h1>
                 </div>}
              </div>
            }


             {/* Companies Results */}
             {
              (companySearchList!==null && companySearchList?.length>0) && 
              <div className='text-white bg-lxgray-700 mt-3 w-2/3 2xl:w-2/4 mx-auto p-3 '>
                 <h1 className='text-lg font-medium py-2'>Company Results</h1>
                 {
                companySearchList.slice(0,3).map((ele)=>{
                    return (
                      <div key={ele.id} className='px-4 py-2 border-b border-lxgray-400 hover:bg-lxgray-400/30'>
                        <OtherLists title={`${ele.title}`} imgUrl={ele.bannerImage} description={`${ele.description}`}  buttonText={"Follow"} urlLink={`/tab/company/${ele.id}`}/>
                      </div>
                    )
                  })
                 }
                 { activeTab!=="Companies" && <div onClick={()=>{setActiveTab("Companies")}} className='text-end text-lxgray-100 p-2 font-semibold'>
                    <h1 className='hover:text-golden cursor-pointer'>Show more</h1>
                 </div>}
              </div>
            }




        </div>
      
      
    </div>
  )
}

export default GlobalSearch
