import apiClient from "../config/axiosConfig";
import { AxiosPromise } from "axios";
import { ApiResponse } from "../models/base";
import {
  Comment,
  CommentResponse,
  PaginatedCommentMetadata,
  PaginatedCommentResponse,
} from "../models/comment";

class CommentService {
  commentOnPost(comment: Comment): AxiosPromise<ApiResponse<CommentResponse>> {
    return apiClient.post(`/v1/posts/${comment.postId}/comments`, comment);
  }

  getPaginatedComments(
    req: PaginatedCommentMetadata
  ): AxiosPromise<ApiResponse<PaginatedCommentResponse>> {
    let queryParam = "";
    if (req.commentId) {
      queryParam = `commentId=${req.commentId}`;
    }
    return apiClient.get(`v1/posts/${req.postId}/comments?${queryParam}`);
  }
  doAction(
    postId: string,
    commentId: string,
    action: string
  ): AxiosPromise<ApiResponse<PaginatedCommentResponse>> {
    return apiClient.post(`/v1/posts/${postId}/comments/${commentId}/actions`, {
      action,
    });
  }

  updateComment(
    comment: Comment
  ): AxiosPromise<ApiResponse<CommentResponse>> {
    return apiClient.put(`/v1/posts/${comment.postId}/comments/${comment?.id}`, comment);
  }

  deleteComment(
    comment: Comment
  ): AxiosPromise<ApiResponse<CommentResponse>> {
    return apiClient.delete(`/v1/posts/${comment.postId}/comments/${comment?.id}`);
  }
  
}

const commentService = new CommentService();
export default commentService;
