import { useState, useCallback, useEffect } from 'react';
import { osName, osVersion, deviceType, browserName, fullBrowserVersion } from 'react-device-detect'; // from react-device-detect
import { useAppDispatch, useAppSelector } from '../../config/hooks';
import { saveUserLogsApi } from '../../slices/userSlice';

// Function to get device information
const getDeviceInfo = () => {
  return {
    ID: fullBrowserVersion,
    device: osName,
    deviceName: `${deviceType} ${browserName}`,
    OsVersion: osVersion,
  };
};

// Function to get location
const getLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          resolve({ lat: latitude, lng: longitude });
        },
        error => {
          if (error.code === 1) {
            // Error code 1 is for "Permission Denied"
            
            reject('User denied geolocation access.');
          } else if (error.code === 2) {
            // Error code 2 is for "Position unavailable"
            ;
            reject('Position unavailable.');
          } else if (error.code === 3) {
            // Error code 3 is for "Timeout"
        
            reject('Geolocation request timed out.');
          } else {           
            reject('Unknown error occurred while fetching geolocation.');
          }
        }
      );
    } else {
      reject('Geolocation is not supported by this browser.');
    }
  });
};

// Custom Hook for logging events
export const useEventLogger = () => {
  const [isLogging, setIsLogging] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const {userId}=useAppSelector((state)=>state.login.session)
  const dispatch=useAppDispatch()


  // Fetch location only once when component mounts
  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const location = await getLocation();
        setUserLocation(location); // Set location once
      } catch (error) {
        console.error("Error fetching location:", error);
        setUserLocation({ lat: null, lng: null }); // Fallback to null if error occurs
      }
    };

    fetchLocation(); // Fetch location on mount
  }, []); // Empty dependency array ensures this only runs once

  // Log user event function
  const logUserEvent = useCallback(async ({
    id,
    event,
    module,
    subModule,
  }) => {
    setIsLogging(true);

    // Get device info dynamically
    const deviceInfo = getDeviceInfo();

    // Construct the log data
    const logData = {
      userId,
      event: event,
      metadata: {
        id: id,
        module: module,
        subModule: subModule,
      },
      device: deviceInfo,
      location: userLocation || { lat: null, lng: null }, // Use location from state
    };

    try {
      // Log the event (you can call your API here)
      console.log(logData,"LOGDATA");
      // dispatch(saveUserLogsApi({events:[logData]}))
      // await savedUserLogs(logData); // assuming savedUserLogs is an async function to save data to your backend
      console.log('Event logged successfully');
    } catch (error) {
      console.error('Error logging event:', error);
    } finally {
      setIsLogging(false);
    }
  }, [userLocation]); // Ensure logUserEvent uses the latest userLocation

  return { logUserEvent, isLogging };
};
