import React, { useState } from "react";
import AccordianDownArrow from "../../../assets/icons/AccordianDownArrow";
import Delete_Icon from "../../../assets/icons/Delete_Icon";
import PlusIcon from "../../../assets/icons/PlusIcon";
import AddEmailPhone from "./AddEmailPhone";
import Modal from "./Modal";
import { useAppSelector } from "../../../config/hooks";

const SignInSecurity = () => {
  const [active, setActive] = useState(0);
  const [activeAddEmailPhone,setActiveEmailPhone]=useState("")
  const {userDetails}=useAppSelector((state)=>state.user)
  const [isModalOpen, setModalOpen]=useState(false)

  const data = ["Email", "Phone"];

  const openModal = (type) => {
    setActiveEmailPhone(type);
    setModalOpen(true);
  };

  return (
    <div>
      <h1 className="text-xl">Signin & Security</h1>
      <div className="pl-5 pt-7 gap-8 flex flex-col text-base text-lxgray-100 ">
        {data.map((ele, i) => {
          return (
            <div key={i+"ep"}>

                <div  onClick={()=>setActive(i)} className="flex items-center justify-between cursor-pointer">
                <h1 className="text-lg font-semibold hover:text-golden">{ele}</h1>
                <div className={`transform  ${active==i?"":"-rotate-90"}`}>
                    <AccordianDownArrow width="14" />
                </div>  
                    
               </div> 
               {
                 active==i && ele=="Email" && (
                        <div className=" pt-2">
                            <p className="text-lxgray-300 text-xs mb-2">Please note that your email address is considered the primary ID. You can modify it as per your preference.</p>
                            <div className="  flex items-center justify-between p-4">
                               <h1 className="text-base">{userDetails?.email}</h1>
                               <div className="flex gap-7 items-center">
                                    <h5 className="text-xs text-lxgreen-100">Primary</h5>
                                    {/* <Delete_Icon width="12"/> */}
                               </div>
                            </div>
                            {userDetails?.secondaryEmail.length>0 && <div className="  flex items-center justify-between p-4">
                               <h1 className="text-base">{userDetails?.secondaryEmail}</h1>
                               <div className="flex items-center justify-center">
                                    {/* <h5 className="text-xs text-lxgreen-100">Primary</h5> */}
                                  <div className="flex items-center  gap-2 ">
                                      <PlusIcon width="12" color="#aa933e"/>
                                      <h4 onClick={() => openModal(ele)} className="text-golden text-sm hover:cursor-pointer">Update Email Id</h4>
                                  </div>
                                  <div >
                                    {/* <Delete_Icon width="12"/> */}
                                  </div>
                               </div>
                            </div>}
                            { !userDetails?.secondaryEmail && <div className="flex items-center justify-end gap-2 pt-4 ">
                                <PlusIcon width="12" color="#aa933e"/>
                                <h4 onClick={() => openModal(ele)} className="text-golden text-sm hover:cursor-pointer">Add Email Id</h4>
                             </div>}
                        </div>
                    )
                }   

                {
                 active==i && ele=="Phone" && (
                    <div className=" pt-2">
                    <p className="text-lxgray-300 text-xs mb-2">Your phone number helps us keep your account secure by adding an additional layer of verification. It also helps others, who already have your phone number, discover and connect with you. You can always decide how you want your phone number used.</p>
                    <div className="  flex items-center justify-between p-4">
                       <h1 className="text-base">+{userDetails?.countryCode} {userDetails?.mobile}</h1>
                       <div className="flex gap-7 items-center">
                            <h5 className="text-xs text-lxgreen-100">Primary</h5>
                            {/* <Delete_Icon width="12"/> */}
                       </div>
                    </div>

                   { userDetails?.secondaryMobile!==0 && <div className="  flex items-center justify-between p-4">
                       <h1 className="text-base">+{userDetails?.secondaryMobileCountryCode} {userDetails?.secondaryMobile}</h1>
                       <div className="flex gap-7 items-center justify-center">
                        <div className="flex items-center justify-end gap-2 pt-4">
                          <PlusIcon width="12" color="#aa933e"/>
                          <h4 onClick={() => openModal(ele)} className="text-golden text-sm hover:cursor-pointer">Update Phone Number</h4>
                        </div>
                            {/* <Delete_Icon width="12"/> */}
                       </div>
                    </div>}
                   
                     {userDetails?.secondaryMobile==0 && <div className="flex items-center justify-end gap-2 pt-4">
                        <PlusIcon width="12" color="#aa933e"/>
                        <h4 onClick={() => openModal(ele)} className="text-golden text-sm hover:cursor-pointer">Add Phone Number</h4>
                     </div>}
                     
                   
                 
                </div>
                    )
                }

                  {  <div className="">
                       { <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)} type={activeAddEmailPhone} />}
                    </div>}
                 
                  
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SignInSecurity;
