import { createSlice } from "@reduxjs/toolkit";
import { act } from "react";

interface modalReduxState {
  isOpenModal: boolean;
  iscreatePostModalTypeOpen: boolean;
  openedModalType: string;
  createPostModalType: string;
  articleAuthorId:string,
  articleAuthorType:string
}

const initialState: modalReduxState = {
  isOpenModal: false,
  iscreatePostModalTypeOpen: false,
  openedModalType: "",
  createPostModalType: "",
  articleAuthorId:null,
  articleAuthorType:null
};

const homeFeedSlice = createSlice({
  name: "createHomeFeedsData",
  initialState,
  reducers: {
    openFeedsModal: (state, action) => {
      state.isOpenModal = !state.isOpenModal;
      state.openedModalType = action.payload;
    },
    closeFeedsModal: (state) => {
      state.isOpenModal = false;
    },
    togggleFeedsModalType: (state) => {
      state.openedModalType = "";
    },
    togggleCreatePostModalType: (state) => {
      state.createPostModalType = "";
    },
    openCreatePostModalType: (state, action) => {
      state.iscreatePostModalTypeOpen = true;
      state.createPostModalType = action.payload;
    },
    closeCreatePostModalType: (state) => {
      state.iscreatePostModalTypeOpen = false;
    },
    setArticleAuthor:(state,action)=>{
      state.articleAuthorId= action.payload.authorId;
      state.articleAuthorType=action.payload.authorType;
    }
  },
});

export const {
  openFeedsModal,
  closeFeedsModal,
  togggleFeedsModalType,
  openCreatePostModalType,
  closeCreatePostModalType,
  togggleCreatePostModalType,
  setArticleAuthor
} = homeFeedSlice.actions;
export default homeFeedSlice.reducer;
