import "./App.css";

import Routes from "./config/route";
import { persistor, store } from "../src/config/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import AppWrapper from "./config";
import ErrorPage from "./pages/error/ErrorPage";
import "./theme/variables.css";
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga4";
import { useEffect } from "react";

const App: React.FC = () => {
  const id = "G-9P1RB2ZRHV";

  useEffect(() => {
    ReactGA.initialize(id);
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Landing Page",
    });
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <AppWrapper>
          <ErrorPage>
            <Routes />
          </ErrorPage>
        </AppWrapper>
      </PersistGate>
    </Provider>
  );
};

export default App;
