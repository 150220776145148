import React, { useEffect } from 'react'
import CommonCard from '../BothCommon/CommonCard'
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../config/hooks';
import LinksCard from '../BothCommon/LinksCard';
import FilteredCard from '../BothCommon/FilteredCard';
// import { getAllEventApi } from '../../../slices/eventSlice';
import { getAllClubsApi } from '../../../slices/clubSlice';
import ProfilePhoto from '../../job/components/ProfilePhoto';

const DiscoverClubs = () => {
  const dispatch = useAppDispatch();
  const { clubs } = useAppSelector((state) => state?.clubs.clubs);
  useEffect(() => {
    dispatch(getAllClubsApi());
  }, []);

  // console.log(clubs,"DISCOVER")
  
  return (
    <div className="flex flex-row justify-between flex-wrap  min-h-screen">
      <div className=" pr-2 ">
        <div className="flex justify-between text-white">
          <h1 className="text-xl text-golden font-semibold">Trending</h1>
         { clubs.length>7 && <Link to="/tab/clubs/all">
            {" "}
            <span className="text-base font-semibold cursor-pointer text-lxgray-200 hover:text-golden">View all</span>
          </Link>}
        </div>
        <div className="py-2 pl-8 no-scrollbar flex justify-start flex-wrap gap-2 ">
          {clubs?.slice(0, 6).map((club) => (
            <Link  key={club.id} to={ `/tab/clubs/${club.id}`}>
            <CommonCard
              details={{ title: club?.title, detail: club?.details ,bannerImage:club?.bannerImage,members:club.members,cardType:"club",isPrivate:club.isPrivate}}
            />
            </Link>
          ))}
        </div>
        {/* <div className="flex overflow-x-scroll no-scrollbar">
          <LinksCard title="LinkCxo's Clubs" />
          <LinksCard title="New Clubs" />
          <LinksCard title="Game Clubs" />
          <LinksCard title="LinkCxo Clubs" />
          <LinksCard title="LinkCxo Clubs" />
          <LinksCard title="LinkCxo Clubs" />

        </div> */}
        {/* <div className="px-1 mt-1">
          <h1 className="text-white font-semibold text-lg py-4">
            You May Also Like This
          </h1>
          <div className="">
            <FilteredCard />
            <FilteredCard />
            <FilteredCard />
            <FilteredCard />
          </div>
        </div> */}
      </div>
      {/* <div className="shadow-sm shadow-gray-300 h-fit w-1/4">
        <CommonCard details={{ title: "Advertisement", detail: "New Advertisement HELLO" ,cardType:"club"}} />
        <ProfilePhoto />
      </div> */}
    </div>
  );
}

export default DiscoverClubs
