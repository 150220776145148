import React, { useState } from "react";
import vector from "../../../assets/icons/Vector.png";
import jobLogo from "../../../assets/images/Blank_Placeholders.png";
import { useAppSelector } from "../../../config/hooks";
import { Job } from "../../../models/job";
import { LastAppliedDay } from "../../../utils/dateUtils";
import {baseUrl,contentUrl}from  '../../../utils/contant'
import { multipleValueDropdown, multipleValueDropdownByName } from "../../../utils/nocoFunctions";
import { useParams } from "react-router-dom";
import Location from "../../../assets/Job Icon/Location";
import Industry from "../../../assets/Job Icon/Industry";
// import EditDots from "../../../assets/icons/EditDots";
// import EditDotPopup from "../EditDotPopup";
// import { useLocation } from "react-router-dom";

interface Props {
  data: Job;
  title: string;
}

const JobCardBox: React.FC<Props> = ({ data, title }) => {
  const {userId,token} =useAppSelector((state)=>state.login.session)
  const {preferredLocation,industries} =useAppSelector((state)=>state.appConfig)
  const [popup, setPopup] = useState<Boolean>(false);
  const { jobId} = useParams();
  // const {pathname}=useLocation()
  const {location,  id,workplaceType} = data;
  //  console.log(data,"Data")
  
  const handlePopup = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setPopup(!popup);
  };


//  console.log("DATAT",data)
  // console.log("Created At",data.createdAt)
  return (
    <div className={`flex flex-col shrink-0  p-2 rounded  hover:bg-lxgray-200/50 ${jobId===id?"bg-lxgray-600":"bg-lxgray-600/60"} border border-lxgray-400 `}>
      
        {/* {title!=='Closed' && <div className="px-4" onClick={handlePopup}><EditDots/></div>}
        {popup && <EditDotPopup data={["CopyLink","Send to Private Message",...(title==("Open")?["Inactive","Close"]:["Report"])]}/>} */}
      
      <div className="flex justify-between items-center  ">
        <div className="text-lg text-white line-clamp-1 font-semibold  py-1.5">{data?.title}</div>
        {  data?.authorType==="COMPANY" && <img
          src={`${data?.authorType==="COMPANY"?`${baseUrl}/${contentUrl}/${data?.author.picUrl}?t=${token}`:jobLogo}`}
          alt="image"
          className="object-cover border border-lxgray-200 rounded-lg w-10 h-10"
        />}
      </div>
        {/* <p className="text-sm text-golden font-semibold">{(!company||data.authorId===userId)?company:"Confidential"}</p> */}
        <div className="flex items-center gap-1 ">
          <Industry/>
          <h4 className="text-sm text-lxgray-200  line-clamp-1">{data?.industries.length>0 ? multipleValueDropdownByName(data?.industries,industries) :"Not Specified"}</h4>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex  items-center gap-1  w-3/4 line-clamp-1 ">
            <Location/>
            <p className="text-sm text-lxgray-200 line-clamp-1">
              {multipleValueDropdown(location,preferredLocation)}{" "}
            </p>
          </div>
          <span className="text-golden text-xs line-clamp-1"> {workplaceType}</span>
        </div>
        {data?.createdAt && <div className="flex items-center justify-end">
          <img src={vector} alt="vector" className="pr-1 w-4 h-4 object-contain" />
          <p className="text-xs text-lxgray-100  ">
            {`Posted ${LastAppliedDay(data?.createdAt)}`}
          </p>
        </div>}
    
    </div>
  );
};

export default JobCardBox;
