import React, { useRef, useState } from 'react'
import OtpEmailPhone from './OtpEmailPhone'
import { useAppDispatch } from '../../../config/hooks'
import { updateEmailApi, updatePhoneApi } from '../../../slices/userSlice'
import { GetOtpResponse } from '../../../models/company'

const AddEmailPhone = ({type,onClose}:{type:string,onClose:Function}) => {
    const dispatch=useAppDispatch()
    const [isOtpScreenOpen,setIsOptScreenOpen]=useState(false)
    const [reqId,setReqId]=useState(null)
    const email=useRef(null)
    const phone=useRef(null)

    const handleSubmit=async (e)=>{
        e.preventDefault()
        if(email.current){

            // console.log(email?.current?.value)
            try {
                const res  = await dispatch(updateEmailApi({ email: email?.current?.value }));
                // Destructure requestId directly from the response payload
               
                const requestId = res.payload?.data?.data?.requestId;
                if (requestId) {
                  // console.log(requestId);
                  setReqId(requestId);
                } else {
                  console.error("Request ID not found in the response.");
                }
              } catch (error) {
                console.error("Failed to update email:", error);
              }
        }

        if(phone.current){
            if (phone.current.value.length !== 10) {
                alert('Phone number must be exactly 10 digits.');
                return;
              }
            
            // console.log(phone?.current?.value)

            try {
                 
                 const res  = await dispatch(updatePhoneApi({phone:Number(phone.current.value),countryCode:91})).then()

                // Destructure requestId directly from the response payload
               
                const requestId = res.payload?.data?.data?.requestId;
                if (requestId) {
                  // console.log(requestId);
                  setReqId(requestId);
                } else {
                  console.error("Request ID not found in the response.");
                }
              } catch (error) {
                console.error("Failed to update email:", error);
              }
        }
        
        setIsOptScreenOpen(true)
    }

  return (
    <div>
            {!isOtpScreenOpen && <form onSubmit={handleSubmit}>
                { type=="Email" &&
                    <div className='flex flex-col gap-5'>
                        <h1 className='text-lxgray-100 text-lg'>Email Address</h1>
                        <p className='text-xs text-lxgray-200'>Please note that your email address is considered the primary ID. You can modify it as per your preference.</p>
                         <div className='flex flex-col'>
                            <label className='text-xs text-lxgray-200'>Email ID</label>
                            <input type="email"
                            ref={email}
                            placeholder='Enter Email here '
                            required
                            className='bg-lxgray-700 border text-white border-lxgray-500 rounded p-2 ' />
                            </div>
                    </div>
                }
                { type=="Phone" &&
                    <div className='flex flex-col gap-5'>
                        <h1 className='text-lxgray-100 text-lg'>Phone Number</h1>
                        <p className='text-xs text-lxgray-200'>Your phone number helps us keep your account secure by adding an additional layer of verification. It also helps others, who already have your phone number, discover and connect with you. You can always decide how you want your phone number used.</p>
                        <div className='flex items-center gap-4'>
                             <label className='border text-white border-lxgray-500 p-2 rounded-lg ' >+91</label>
                            <input type="tel"
                            ref={phone}
                            placeholder='Enter Phone Number'
                            className='bg-lxgray-700 border text-white border-lxgray-500 rounded p-2 w-full '
                            pattern="[0-9]*" // This restricts input to numbers only
                            inputMode="numeric"
                            required />
                        </div>
                </div>

                }

                <div className='flex justify-end'>
                    <button className='bg-golden text-white px-10 py-2 rounded-lg font-semibold mt-4'>Save</button>
                </div>
            </form>}
            {
                isOtpScreenOpen && (
                    <OtpEmailPhone reqId={reqId} type={type.toLowerCase()} setIsOptScreenOpen={setIsOptScreenOpen} onClose={onClose}/>
                )
            }
                
    </div>
  )
}

export default AddEmailPhone
