import { useEffect, useRef, useState } from "react";
import moment from "moment";

import { baseUrl, contentUrl } from "../utils/contant";

import profile from "../assets/images/Blank_Placeholders.png";
import Comments from "./Comments";

import EclipsVerticleDotsIcon from "../assets/icons/EclipsVerticleDotsIcon";
import LikeIcon from "../assets/icons/LikeIcon";
import CommentIcon from "../assets/icons/CommentIcon";
import ShareIcon from "../assets/icons/ShareIcon";

import { useAppDispatch, useAppSelector } from "../config/hooks";
import { setPostId } from "../slices/profileSlice";
import { openCreatePostModalType } from "../slices/homeFeedSlice";
import { useNavigate } from "react-router-dom";
import { bookmarkPostApi, deletePostApi, doActionOnPostApi } from "../slices/postSlice";
import { dropDownByTitle } from "../utils/nocoFunctions";
import VerificationPopup from "./VerificationPopup";
import AlternateProfile from "./AlternateProfile";
import ReportPopup from "./ReportPopup";

const PostCard = ({ userContent, postId, openComments = null, children }) => {
  const dispatch = useAppDispatch();
  const {designations} = useAppSelector((state) => state.appConfig);

  const navigate = useNavigate();
  const menuRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const shareOptionRef = useRef<HTMLDivElement>(null);
  const shareOptionParentRef = useRef<HTMLButtonElement>(null);
  const [toggleComment, setToggleComment] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleShareOption, setToggleShareOption] = useState(false);
  const session = useAppSelector((state) => state.login.session);
  const postComments = useAppSelector((state) => state.comment);
  const options = useAppSelector((state) => state.appConfig.designations);
  const modalState = useAppSelector((state) => state.createHomeFeedsData);
  const { userDetails } = useAppSelector((state) => state.user);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isPopupReportVisible, setPopupReportVisible] = useState(false);
  const { createPostModalType } = modalState;
  const {
    author,
    counts = { comment: 0, like: 0, share: 0 },
    createdAt,
    id,
    authorId,
    postType,
    authorType,
    isLiked,
    createdBy
  } = userContent;
  const { comment, like, share } = counts;

  const { picUrl, firstName, lastName, organization, designation, userId } =
    author;
  const { comments } = postComments;

  console.log("userContent :>> ", userContent);

  const postRelativeTime = moment(createdAt).fromNow();

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (
        menuRef.current &&
        parentRef.current &&
        !menuRef.current.contains(e.target as Node) &&
        !parentRef.current.contains(e.target as Node)
      ) {
        // onClose();
        setToggleModal(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (
        shareOptionRef.current &&
        shareOptionParentRef.current &&
        !shareOptionRef.current.contains(e.target as Node) &&
        !shareOptionParentRef.current.contains(e.target as Node)
      ) {
        // onClose();
        setToggleShareOption(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const toggleCommentHandler = () => {
    if(userDetails?.status!=="Verified"){
      setPopupVisible(true)
      return
    } 
    
    if (toggleComment) {
      if (postId === id) {
        setToggleComment(false);
      } else {
        dispatch(setPostId(id));
      }
    } 
    if (!toggleComment) {
      if (postId === id) {
        setToggleComment(true);
      } else {
        setToggleComment(true);
        dispatch(setPostId(id));
      }
    }
  };

  const togglePostModal = () => {
    setToggleModal(!toggleModal);
  };
  

  const toggleShareModal = () => {
    if(userDetails?.status!=="Verified"){
      setPopupVisible(true)
      return
    }
    setToggleShareOption(!toggleShareOption);
  };

  const toggleRepostModalhandler = () => {
    setToggleShareOption(false);
    dispatch(openCreatePostModalType(`repost?=${userContent.id}`));
  };


  const navigationHandler = () => {
    if (authorType === "USER") navigate(`/tab/profile/${userId}`);
    if (authorType === "CLUB") navigate(`/tab/clubs/${authorId}`);
    if (authorType === "EVENT") navigate(`/tab/events/${authorId}`);
  };

  const designantionData =
    !isNaN(designation) && designation
      ? options?.find((opt) => opt.Id === designation)?.Title
      : designation
      ? designation
      : "";


    const  handleBookmark=()=>{
  //  console.log(postId,"POstId",userContent)
      dispatch(bookmarkPostApi({tableType: "post",
      tableId: id}))
      setToggleModal(!toggleModal);
    }

    const handleLike=()=>{
      if(userDetails?.status!=="Verified"){
        setPopupVisible(true)
        return
      }   
      if(isLiked){
          dispatch(doActionOnPostApi({postId:id,action:"unlike"}))
        }     
      if(!isLiked)  { 
          dispatch(doActionOnPostApi({postId:id,action:"like"}))
        }
          
    }

    const handleDeletePost=()=>{
      dispatch(deletePostApi({postId:id}))
    }
    
    const handleReport=()=>{
      setPopupReportVisible(true)
    }
  return (
    <div className="bg-lxgray-800 p-4 rounded-xl space-y-5">
      <div className="flex gap-4 items-start relative">
        <div className="w-[50px]">
          {picUrl ? (
            <div
              className={`w-[50px] h-[50px] bg-no-repeat bg-center bg-cover rounded-full cursor-pointer`}
              style={{
                backgroundImage: `url(${baseUrl}/${contentUrl}/${picUrl}?t=${session.token})`,
              }}
              onClick={navigationHandler}
            ></div>
          ) : (
            // <div
            //   className={`w-[40px] h-[40px] bg-no-repeat bg-center bg-cover rounded-full cursor-pointer`}
            //   style={{
            //     backgroundImage: `url(${profile})`,
            //   }}
            //   onClick={navigationHandler}
            // ></div>
            <AlternateProfile firstName={firstName} lastName={lastName} width="12" height="12"/>
          )}
        </div>
        <div className="text-start grow min-w-[70%] ">
          <h1
            className="text-lg text-white font-semibold leading-5 cursor-pointer  w-fit"
            onClick={navigationHandler}
          >{`${firstName} ${lastName}`}</h1>
          <p className="text-base text-lxgray-400 font-normal flex flex-wrap items-center justify-between">
            <span
              className="text-start truncate cursor-pointer "
              onClick={navigationHandler}
            > { authorType=="COMPANY" ? `${dropDownByTitle(designation,designations)}` : ` ${dropDownByTitle(designation,designations)} | ${organization}`}</span>
            <span className="text-end">{postRelativeTime}</span>
          </p>
        </div>

        <div
          className="self-start ml-auto mr-0 cursor-pointer"
          ref={parentRef}
          onClick={() => togglePostModal()}
        >
          <EclipsVerticleDotsIcon />
        </div>

        {toggleModal && (
          <div
            className="w-[265px] absolute top-6 right-1 z-40 rounded-s-xl rounded-br-xl"
            ref={menuRef}
          >
            <div
              className={`w-full h-full bg-lxgray-600 shadow-2xl text-lg text-white/60 text-start font-normal tracking-wide rounded-s-xl rounded-br-xl flex flex-col justify-between divide-y-2 divide-lxgray-400/30 `}
            >
              {/* {authorId === session.userId && (
                <h1 className="w-full py-2 pl-3 cursor-pointer hover:text-white/100">
                  Edit
                </h1>
              )} */}
             { (authorId !== session.userId && createdBy !==session.userId ) && <h1 onClick={handleBookmark} className="w-full py-2 pl-3 cursor-pointer hover:text-white/100">
                Bookmark
              </h1>}
              {(authorId === session.userId || createdBy ===session.userId )&& (
                <h1 onClick={handleDeletePost} className="w-full  py-2 pl-3 cursor-pointer hover:text-white/100">
                  Delete
                </h1>
              )}

              {(authorId !== session.userId && createdBy !==session.userId ) && (
                <h1 onClick={handleReport} className="w-full py-2 pl-3 cursor-pointer hover:text-white/100">
                  Report
                </h1>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="space-y-1">{children}</div>

      <div className="w-full relative flex items-center justify-between border-t-2 border-lxgray-700 text-sm text-lxgray-400 font-semibold py-2 px-4">
        <h1 
        onClick={handleLike}
        className={`flex gap-2 items-center justify-center cursor-pointer px-2 text-center ${isLiked && "text-golden"}`}>
          <LikeIcon color={isLiked && "#AA933E"}/>
          {`${like} Applauses`}
        </h1>
        <h1
          ref={openComments}
          className="flex gap-2 items-center justify-center cursor-pointer px-2 text-center"
          onClick={() => toggleCommentHandler()}
        >
          <CommentIcon />
          {`${comment} ${postType === "QUERY" ? "Answers" : "Comments"}`}
        </h1>
        <button
          ref={shareOptionParentRef}
          className="flex gap-2 items-center justify-center cursor-pointer px-2 text-center"
          onClick={toggleShareModal}
        >
          <ShareIcon />
          {`${share} Shares`}
        </button>
        {toggleShareOption && (
          <div
            className="w-[265px] absolute top-8 right-20 z-40 rounded-s-xl rounded-br-xl"
            ref={shareOptionRef}
          >
            <div
              className={`w-full h-full bg-lxgray-600 shadow-2xl text-lg text-white/60 tracking-tight text-start font-normal  rounded-s-xl rounded-br-xl flex flex-col justify-between divide-y-2 divide-lxgray-400/30 `}
            >
              <h1
                className="w-full  py-2 pl-3 cursor-pointer hover:text-white/100"
                onClick={toggleRepostModalhandler}
              >
                On Feed
              </h1>
              {/* <h1 className="w-full py-2 pl-3 cursor-pointer hover:text-white/100">
                In Club
              </h1> */}
              {/* <h1 className="w-full py-2 pl-3 cursor-pointer hover:text-white/100">
                With Connections
              </h1> */}
              {/* <h1 className="w-full py-2 pl-3 cursor-pointer hover:text-white/100">
                Copy Link
              </h1> */}
            </div>
          </div>
        )}
      </div>

      {toggleComment && postId === id && (
        <Comments
          userContent={userContent}
          comments={comments}
          parentId=""
          key={id}
        />
      )}
      <VerificationPopup
        visible={isPopupVisible} 
        onClose={setPopupVisible} 
      />
      <ReportPopup
      visible={isPopupReportVisible}
      onClose={setPopupReportVisible}
      postId={id}/>
    </div>
  );
};

export default PostCard;
