import apiClient from "../config/axiosConfig";
import { AxiosPromise } from "axios";
import { ApiResponse } from "../models/base";
import {
  CreateJobRequest,
  CreateJobResponse,
  GetJobApplicationsRequest,
  GetAppliedJobList,
  GetJobDetailRequest,
  GetRecommendedJobRequest,
  Job,
  JobApplication,
  JobApplicationResponse,
  JobApplyRequest,
  JobApplyResponse,
  JobSaveRequest,
  PaginatedAppliedJobResponse,
  PaginatedJobResponse,
  UpdateJobApplication,
  JobStatusUpdate,
  SendComment,
  ApplicationComment,
  GetComments,
  GetAllCommentResponse,
  ProfanityCheck,
  GetAIRecommendedJobRequest
} from "../models/job";

class JobService {

  createJob(
    createJob: CreateJobRequest
  ): AxiosPromise<ApiResponse<Job>> {
    return apiClient.post(`/v1/jobs`, createJob);
  }

  getRecommendedJobs(
    filterData: GetRecommendedJobRequest
  ):
    AxiosPromise<ApiResponse<PaginatedJobResponse>> {
    return apiClient.get(`/v1/jobs?filter=${JSON.stringify(filterData)}`);  //You can add filter also like "/v1/jobs?filter={"expMin":5}"
  }

  getAIRecommendedJobs(
    data: GetAIRecommendedJobRequest
  ):
    AxiosPromise<ApiResponse<PaginatedJobResponse>> {
    return apiClient.get(`/v1/get_job_recommendations?id=${(data.id)}`);  //You can add filter also like "/v1/jobs?filter={"expMin":5}"
  }

  getJobByUrl(
    nextUrl: string
  ):
    AxiosPromise<ApiResponse<PaginatedJobResponse>> {
    return apiClient.get(nextUrl);  //You can add filter also like "/v1/jobs?filter={"expMin":5}"
  }

  getAppliedJob():
    AxiosPromise<ApiResponse<Array<JobApplication>>> {
    return apiClient.get(`/v1/jobs/applied`);  //You can add filter also like "/v1/jobs?filter={"expMin":5}"
  }

  getSavedJob():
    AxiosPromise<ApiResponse<PaginatedJobResponse>> {
    return apiClient.get(`/v1/jobs/saved`);  //You can add filter also like "/v1/jobs?filter={"expMin":5}"
  }


  updateJob(
    job: CreateJobRequest
  ): AxiosPromise<ApiResponse<Job>> {
    return apiClient.put(`/v1/jobs/${job.jobId}`, job);
  }
  
  saveJob(
    jobSave: JobSaveRequest
  ):
    AxiosPromise<ApiResponse<Job>> {
    return apiClient.post(`/v1/jobs/saved`,
      jobSave
    );  //You can add filter also like "/v1/jobs?filter={"expMin":5}"
  }

  unsaveJob(
    jobSave: JobSaveRequest
  ):
    AxiosPromise<ApiResponse<Job>> {
    return apiClient.delete(`/v1/jobs/saved/${jobSave.jobId}`);  //You can add filter also like "/v1/jobs?filter={"expMin":5}"
  }

  applyToJob(
    jobApplication: JobApplyRequest
  ):
    AxiosPromise<ApiResponse<JobApplyResponse>> {
    return apiClient.post(`/v1/jobs/${jobApplication.jobId}/applications`,
      jobApplication
    );  //You can add filter also like "/v1/jobs?filter={"expMin":5}"
  }

  getJobDetail(
    getJob: GetJobDetailRequest
  ):
    AxiosPromise<ApiResponse<Job>> {
    return apiClient.get(`/v1/jobs/${getJob.jobId}`);  //You can add filter also like "/v1/jobs?filter={"expMin":5}"
  }

  getApplicants(
    job: GetJobApplicationsRequest
  ):
    AxiosPromise<ApiResponse<JobApplicationResponse>> {
    return apiClient.get(`/v1/jobs/${job.jobId}/applications`);  //You can add filter also like "/v1/jobs?filter={"expMin":5}"
  }

  updateApplication(
    jobApplication: UpdateJobApplication
  ):
    AxiosPromise<ApiResponse<JobApplication>> {
    return apiClient.patch(`/v1/jobs/${jobApplication.jobId}/applications/${jobApplication.applicationId}/action`,
      jobApplication
    );  //You can add filter also like "/v1/jobs?filter={"expMin":5}"
  }

  updateJobStatus(
    job: JobStatusUpdate
  ): AxiosPromise<ApiResponse<Job>> {
    return apiClient.patch(`/v1/jobs/${job.jobId}/status`, { status: job.status });
  }

  sendApplicationMessage(
    sendComment: SendComment
  ):
    AxiosPromise<ApiResponse<ApplicationComment>> {
    return apiClient.post(`/v1/jobs/${sendComment.jobId}/applications/${sendComment.applicationId}/comments`,
      sendComment.comment
    );
  }

  getAllAplicationMessage(
    getComment: GetComments
  ):
    AxiosPromise<ApiResponse<GetAllCommentResponse>> {
    return apiClient.get(`/v1/jobs/${getComment.jobId}/applications/${getComment.applicationId}/comments`
    );
  }

  getJDAutoFilling(
    data: ProfanityCheck
  ):
    AxiosPromise<ApiResponse<Job>> {
    return apiClient.post(`/v1/jd_autofilling`,
      data.data, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }
    );
  }

}

const jobService = new JobService();
export default jobService;

