import React, { useEffect, useRef, useState } from "react";

import {
  BasicUser,
  GetUserDetailsRequest,
  GetUserDetailsResponse,
} from "../../../../models/user";
import { useAppDispatch, useAppSelector } from "../../../../config/hooks";
import { getUserDetailApi } from "../../../../slices/userSlice";
import EditDots from "../../../../assets/icons/EditDots";
import MessageIcon from "../../../../assets/icons/MessageIcon";

import blankImage from "../../../../assets/images/Blank_Placeholders.png";
import { updateApplication } from "../../../../slices/jobSlice";

import { LastAppliedDay } from "../../../../utils/dateUtils";
import DownloadIcon from "../../../../assets/icons/DownloadIcon";
import Location from "../../../../assets/icons/Location";
import { useNavigate, useParams } from "react-router-dom";
import {  addDetails, sideChatActive } from "../../../../slices/chatSlice";
import { baseUrl, contentUrl } from "../../../../utils/contant";
import AlternateProfile from "../../../../components/AlternateProfile";

interface Attachment {
  name: string;
  url: string;
  contentType: string;
  size: number;
}
interface UserJobProfileProps {
  id: string;
  jobId: string;
  applicationId: string;
  appliedJobTime: Date;
  attachment: Attachment;
  percentageMatch:string
}

const UserJobProfile: React.FC<UserJobProfileProps> = ({
  id,
  jobId,
  applicationId,
  appliedJobTime,
  attachment,
  percentageMatch
}) => {
  const dispatch = useAppDispatch();
  const { session } = useAppSelector((state) => state.login);
  const [user, setUser] = useState<BasicUser | undefined>();
  const [visible, setVisible] = useState<boolean>(false);
  const editDotsPopupRef = useRef<HTMLDivElement>(null);
  const {title}=useParams()
  const navigate=useNavigate()
  console.log(title,"title for applied")

  console.log("JOBID",jobId,"APPLICATIONId",applicationId)
  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const { payload }: any = await dispatch(
            getUserDetailApi({ userId: id })
          );

          setUser(payload.data.data.userDetails);
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();
    }
  }, [id]);

  const handleJobStatus = async (hello) => {
    console.log(hello);
    try {
      const response = await dispatch(
        updateApplication({
          status: hello,
          jobId: jobId,
          applicationId: applicationId,
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        editDotsPopupRef.current &&
        !editDotsPopupRef.current.contains(event.target as Node)
      ) {
        setVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOpenChat=()=>{
    dispatch(sideChatActive())
    dispatch(addDetails({jobId,applicationId,title:`${user.firstName} ${user.lastName}`}))
    // dispatch(addJobId(jobId))
    // dispatch(addApplicationId(applicationId))
}

const handleClick=()=>{
     navigate(`/tab/profile/${id}`)
  }

  return (
    user && (
      <div className="flex p-2 border-b border-lxgray-400 items-center">
        <div onClick={handleClick}>
         { user.profilePicture ?
         <img
            src={
              user.profilePicture.length > 0
                ? `${baseUrl}/${contentUrl}/${user.profilePicture}?t=${session.token}`
                : blankImage
            }
            alt="image"
            className="  w-[95px] h-[95px] rounded-full border border-white object-cover cursor-pointer"
          />
          :
          <AlternateProfile firstName={user.firstName} lastName={user.lastName} width="[95px]" height="[95px]" textSize="2xl"/>}
        </div>
        <div className="px-4 flex-1 py-2">
          <div className="flex justify-between">
            <div onClick={handleClick} className="flex flex-col gap-1  cursor-pointer">
              <p className="text-xl text-white font-semibold">
                {user.firstName} {user.lastName}
              </p>
              <p className="text-sm text-lxgray-100 ">
                {user.organization}
              </p>
              <div className="flex gap-2 items-center">
              <Location/>
              <p className="text-sm text-lxgray-100 ">
                {user.location}
              </p>
              </div>
              
            </div>
            <div className="flex justify-end gap-4 w-1/6 relative  h-fit">
              {percentageMatch && <h1 className="text-white font-semibold ">{percentageMatch}</h1>}
              <p className="text-xs text-lxgray-400 text-nowrap   flex items-end ">
                {`${LastAppliedDay(appliedJobTime)}`}{" "}
              </p>

             {title!=="Closed" && <div
                onClick={() => setVisible(!visible)}
                className="cursor-pointer px-2"
              >
                <EditDots />
              </div>}
              {visible && (
                <div
                  ref={editDotsPopupRef}
                  className="absolute flex  text-lxgray-100 flex-col bg-lxgray-400 rounded p-1 right-6 "
                >
                  <span
                    onClick={() => handleJobStatus("SHORTLISTED")}
                    className="hover:text-white border-b pr-16 pl-2 py-1 border-lxgray-200 cursor-pointer"
                  >
                    Shortlisted
                  </span>
                  <span
                    onClick={() => handleJobStatus("HOLD")}
                    className=" hover:text-white py-1 border-b pr-16 pl-2 border-lxgray-200 cursor-pointer"
                  >
                    Hold
                  </span>
                  <span
                    onClick={() => handleJobStatus("REJECTED")}
                    className="  hover:text-white py-1 pr-16 pl-2 cursor-pointer"
                  >
                    Rejected
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className="flex justify-between items-baseline">
            <p className="text-xs text-lxgray-200 font-semibold ">
              Total Experience : {user.totalExperience} yrs
            </p>
            <p className="text-xs text-lxgray-200 font-semibold ">
              Industry : {user.industry ? user.industry[0] : ""} industry
            </p>
            <div className="flex gap-4">
              <div onClick={handleOpenChat}>
                <MessageIcon />
              </div>

              <a href={`${baseUrl}/${contentUrl}/${attachment?.url}?t=${session.token}`}>
                <div>
                  {" "}
                  <DownloadIcon />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default UserJobProfile;
